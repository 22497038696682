<template>
  <div>
    <v-btn
      class="btnVR rounded-lg"
      rounded
      solo
      depressed
      height=40
      color="black"
      v-on:click='clickIcon'
      block
      style="padding:0"
      :disabled="disabled"
    >
      <!-- <v-icon left size="20">{{svgPath_GamepadSquare}}</v-icon>VRで見る -->
      <span style="margin:5px 0 0 5px; ">
        <img src="@/assets/icon_VR_W.svg" width="30" height="30">
      </span>
      <span class="VRbtn_text" style="margin-left: 10px; margin-right:auto;">VRでみる</span>
      <v-icon class="VRbtn_icon" right style="margin:0 10px 0 0; " :size="24" color="#ffffff">{{svgPath_Outbound}}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mdiGamepadSquare, mdiArrowRightCircle } from '@mdi/js'
export default {
  data () {
    return {
      svgPath_GamepadSquare: mdiGamepadSquare,
      svgPath_Outbound: mdiArrowRightCircle
    }
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    clickIcon: function (event) {
      this.$router.push({ name: 'vrPage', params: { id: this.id } })
      // const routeData = this.$router.resolve({ name: 'vrPage', params: { id: this.id } })
      // window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>
.btnVR {
  color: #ffffff;
}
.theme--dark.v-btn {
  background-color:rgba(0,0,0,0.75);
  border: 1px solid #dadce0;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
  background-color:#d7d7d7 !important;
}
.v-btn--disabled >>> .VRbtn_text,
.v-btn--disabled >>> .VRbtn_icon {
  color: #ffffff;
}
.theme--light.v-btn.v-btn--disabled .v-icon, .theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: #ffffff !important;
}
</style>
