// import Vue from "vue"

const loginDefault = {
  token: false,
  username: 'ゲスト',
  email: '',
  user_id: -1,
  expire: 0,
  headers: { Authorization: '' }
}

const auth = {
  state: {
    login: { ...loginDefault }
  },
  mutations: {
    SET_LOGIN_INFO: (state, login) => {
      state.login.token = login.token// ログイントークン
      state.login.username = login.username// ユーザー名
      state.login.email = login.email// ユーザー名
      state.login.user_id = login.user_id// ユーザー名
      state.login.expire = Math.floor(1000 * login.expire) // APIからUNIXタイム(秒)で有効期限が返ってくるものとし、ミリ秒に変換しておく
      state.login.headers = login.headers
    },
    SET_LOGOUT: (state) => {
      state.login = { ...loginDefault }
    }
  },
  actions: {
    setLoginInfo ({ commit }, login) {
      commit('SET_LOGIN_INFO', login)
    },
    setLogOut ({ commit }) {
      commit('SET_LOGOUT')
    }
  }
}

export default auth
