<template>
  <div class="PassResetApplication">
    <v-row justify="center">
      <v-col cols=12 sm=6 lg=4 xl=3 >

        <h4>パスワードリセット</h4>

        <v-row justify="center">
          <v-card class="elevation-0 auth-card" style="background-color: #F5F5F5;">

            <v-system-bar class="flex font-weight-bolld">
              <v-row justify="center">登録メールアドレスを入力</v-row>
            </v-system-bar>

            <div class="layout column align-center">
              <v-form ref="loginForm">
                <v-text-field
                  name="login"
                  label="メールアドレス"
                  type="text"
                  dense
                  v-model="model.email"
                  :rules="emailRules"
                  :outlined=true
                  background-color="white"
                  required
                ></v-text-field>
              </v-form>
              <v-btn
                class="btn-auth btn-auth-black"
                style="margin-top: 10px;"
                rounded
                solo
                prepend-icon="mdi-magnify"
                depressed
                color="black"
                @click="passReset"
                :loading="loadingLogin"
                >
                パスワードリセット
              </v-btn>
            </div>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
  data: () => ({
    loadingLogin: false,
    valid: true,
    emailRules: [
      v => !!v || 'メールアドレスは必須項目です。',
      v => (v && v.length <= 128) || 'メールアドレスは128文字以内で入力してください。',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式が正しくありません。'
    ],
    model: {
    }
  }),
  created () {
    if (this.checkLoggedin()) {
      this.$router.push({ name: 'account' })
    }
  },
  methods: {
    passReset () {
      if (this.$refs.loginForm.validate()) {
        this.loadingLogin = true
        console.log(this.model)
        this.$axios.post(this.$httpPassReset, this.model)
          .then(res => {
            console.log('res.data↓')
            console.log(res.data)
            // Swal.fire({
            //   type: 'info',
            //   title: '申請完了',
            //   text: 'メールが送信されました',
            //   showConfirmButton: false,
            //   showCloseButton: false,
            //   timer: 3000
            // })
            // this.$router.push({ name: 'login' })
          }).catch(error => {
            this.loadingLogin = false
            console.log('error : ' + error)
            Swal.fire({
              type: 'warning',
              title: 'Error',
              text: error.response.data.detail,
              showConfirmButton: false,
              showCloseButton: false,
              timer: 3000
            })
          })
        Swal.fire({
          title: '申請完了',
          text: 'メールが送信されました',
          showConfirmButton: false,
          showCloseButton: false,
          timer: 3000
        })
        this.$router.push({ name: 'login' })
      }
    }
  }
}
</script>

<style scoped>
h4 {
  font-size: 21px;
  margin-top: 60px;
  margin-bottom: 60px;
  font-weight: 900;
  text-align: center;
}
h5 {
  font-size: 15px;
  margin-top: 60px;
  margin-bottom: 60px;
  font-weight: 900;
  text-align: center;
}
.v-system-bar {
  background-color: #E5E2E2;
  min-height: 30px;
  font-size: 15px;
  font-weight: 900;
  margin-bottom: 30px;
  text-align: center;
}
.btn-auth-black {
  color: #ffffff;
  border: 0.5px solid;
  margin-top: 20px;
}
.btn-auth-white {
  background-color: #ffffff;
  border: 0.5px solid #dadce0;
}

@media (max-width: 500px) {
  .btn-auth-black {
    border: 1px solid;
  }
  .btn-auth-white {
    border: 1px solid #dadce0 !important;
  }
}
.input-title {
  color: black;
  font-weight: 500;
  margin-bottom: 2px;
}
</style>

<style>
.PassResetApplication .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot{
  min-height: 30px;
  width: 280px;
  margin-bottom: 0px;
}
.PassResetApplication .v-input input {
  max-height: 30px;
}
.PassResetApplication .v-label {
  font-size: 14px;
}
.PassResetApplication .v-text-field input {
    padding-top: 0px;
    padding-bottom: 0px;
}
</style>
