<template>
  <v-card id="DiscriptionCard" flat tile :color="color" v-bind:class="[{dark: dark, landingTitle: landingTitle, serif: serif, value: value },addClass]" >
    <v-card-subtitle v-if="subtitle != ''">
      <!-- <v-icon color="#DFFF00" size="20">{{svgPath_Box}}</v-icon> -->
      <v-icon style="margin-right: 2px;" :color="pointColor" size="16">{{svgPath_Box}}</v-icon>
      {{subtitle}}
    </v-card-subtitle>
    <v-card-title v-html="title"/>
    <v-card-text class='text' v-if="text != ''" v-html="text" color="black"/>
    <v-card-text class='dialog rounded-0' v-if="dialogFunc != null" @click="dialogFunc">
      {{dialogText}}
      <v-icon size="30">{{svgPath_ChevronRight}}</v-icon>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiChevronRight, mdiCheckboxBlank } from '@mdi/js'
export default {
  data () {
    return {
      svgPath_ChevronRight: mdiChevronRight, // Material Design IconmdiCheckboxBlank
      svgPath_Box: mdiCheckboxBlank
    }
  },
  props: {
    title: {
      type: String,
      required: true,
      default: 'タイトル'
    },
    pointColor: {
      type: String,
      required: false,
      default: '#191919'
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false
    },
    dialogText: {
      type: String,
      required: false,
      default: '詳しく知る'
    },
    dialogFunc: {
      type: Function,
      required: false,
      default: null
    },
    color: {
      type: String,
      required: false,
      default: 'transparent'
    },
    addClass: {
      type: String,
      required: false
    },
    dark: {
      type: Boolean,
      required: false,
      default: false
    },
    landingTitle: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    serif: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
/* #DiscriptionCard
{
  background-color: transparent;
} */
.v-card__subtitle{
  font-weight: 900;
  font-size: 17px;
  color:#191919 !important;
  padding:0;
  margin-bottom:30px;
  /* border-bottom: solid 2px #191919 !important; */
  display: inline-block;
}
.v-card__title{
  font-weight: 900;
  font-size: 60px;
  line-height: 80px;
  color:#191919 !important;
  padding: 0 0 16px;
}
.v-card__text{
  font-weight: 900;
  font-size: 18px;
  line-height: 30px;
  color:#191919 !important;
  padding: 0px;
}
/* .v-card__text{
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  color:#000000 !important;
  padding: 0px;
  letter-spacing: .05em;
} */
.dialog{
  border-bottom: solid 2px #191919 !important;
  display: inline-block !important;
  width: auto;
  color:#a1a1a1 !important;
  padding: 10px 0 0;
  cursor: pointer;
}
.v-icon
{
  color:#a1a1a1;
}
.text >>> br{
    display:block;
  }
@media (max-width: 1650px) {
  .v-card__subtitle{
    font-weight: 900;
    font-size: 16px;
    padding:0;
    margin-bottom:20px;
    /* border-bottom: solid 2px #191919 !important; */
    display: inline-block;
  }
  .v-card__title{
    font-weight: 900;
    font-size: 45px;
    line-height: 60px;
    padding: 0 0 10px;
  }
  .v-card__text{
    font-weight: 900 !important;
    font-size: 14px;
    line-height: 26px;
    padding: 0px;
  }
  .dialog{
    color:#a1a1a1 !important;
    padding: 10px 0 0;
  }
}
@media (max-width: 1264px) {
  .v-card__subtitle{
    font-weight: 900;
    font-size: 16px;
    padding:0;
    margin-bottom:0px;
    padding: 40px 0 0px;
    /* border-bottom: solid 2px #191919 !important; */
    display: inline-block;
  }
  .v-card__title{
    font-weight: 900;
    font-size: 30px;
    line-height: 40px;
    padding: 15px 0 10px;
  }
  .v-card__text{
    font-weight:600 !important;
    font-size: 14px;
    line-height: 1.8;
    padding: 0px;
    letter-spacing: .05em;
  }
  .dialog{
    color:#a1a1a1 !important;
    padding: 5px 0 0;
  }
}
@media (max-width: 1030px) {
  .v-card__subtitle{
    font-weight: 900;
    font-size: 16px;
    margin-bottom:0px;
    padding: 40px 0 0px;
  }
  .v-card__title{
    padding: 15px 0 10px;
  }
}
@media (max-width: 670px) {
  .text >>> br{
    display: none;
  }
  .v-card__subtitle{
    font-weight: 900;
    font-size: 16px;
    margin-bottom:0px;
    padding: 40px 10px 0px;
  }
  .v-card__title{
    font-size: 26px;
    padding: 15px 10px 20px;
    line-height: 34px;
  }
  .v-card__text{
    font-weight:600 !important;
    font-size: 14px;
    line-height: 26px;
    padding: 0px 10px;
    letter-spacing: .05em;
  }
}
.dark >>> .v-card__subtitle,
.dark >>> .v-card__title,
.dark >>> .v-card__text {
  color:#ffffff !important;
}
.dark >>> .dialog{
  color:#a1a1a1 !important;
}
.landingTitle >>> .v-card__title{
  font-size: 45px;
  line-height: 60px;
  padding: 0 0 20px;
}
@media (max-width: 1650px) {
  .landingTitle >>> .v-card__title{
    font-size: 45px;
    line-height: 60px;
    padding: 0 0 20px;
  }
  .landingTitle >>> .v-card__text{
    font-weight: 900;
    font-size: 18px;
    line-height: 30px;
    padding: 0px;
  }
}
@media (max-width: 1264px) {
  .landingTitle >>> .v-card__title{
    font-weight: 900;
    font-size: 34px;
    line-height: 50px;
    padding: 0 0 10px;
  }
  .landingTitle >>> .v-card__text{
    font-weight:600 !important;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: .05em;
  }
}
@media (max-width: 670px) {
  .landingTitle >>> .v-card__title{
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
    padding: 0 0 25px;
  }
  .landingTitle >>> .v-card__text{
    font-weight:600 !important;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: .05em;
  }
  .landingTitle >>> br{
    display:inherit;
  }
}
.serif >>> .v-card__subtitle,
.serif >>> .v-card__title,
.serif >>> .v-card__text,
.serif >>> .dialog {
  font-family:'Noto Serif JP', serif;
}
.value >>> .v-card__title{
  font-size: 50px;
  line-height: 65px;
  padding: 0 0 20px;
}
@media (max-width: 1560px) {
  .value >>>.v-card__title{
    font-weight: 900;
    font-size: 30px;
    line-height: 40px;
    padding: 15px 0 10px;
  }
}
@media (max-width: 1030px) {
  .value >>>.v-card__title{
    padding: 15px 0 10px;
  }
}
@media (max-width: 670px) {
  .value >>>.v-card__title{
    font-size: 21px;
    padding: 15px 10px 20px;
    line-height: 34px;
  }
}
</style>
