import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import HouseList from '@/views/HouseList.vue'
import Make from '@/views/Make.vue'
import Detail from '@/views/Detail.vue'
import VRPage from '@/views/VR.vue'
import SitePolicy from '@/views/SitePolicy.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import Inquiry from '@/views/Inquiry.vue'
import Register from '@/components//UserAuth//Register.vue'
import Login from '@/components//UserAuth//Login.vue'
import Account from '@/components//UserAuth//Account.vue'
import PassResetApplication from '@/components//UserAuth//PassResetApplication.vue'
import PassReset from '@/components//UserAuth//PassReset.vue'
// import stpre from '@/store/index.js'
import gv from '../mixins/globalValiables'
import store from '../store/index'

Vue.use(VueRouter)
Vue.mixin(gv)// mixin（グローバルメソッド）の追加

const routes = [
  {
    path: '/',
    name: 'homePage',
    component: Home
  },
  // 検索
  {
    path: '/searchHouse',
    name: 'listPage',
    component: HouseList
  },
  // メイク
  {
    path: '/makeHouse',
    name: 'makePage',
    component: Make,
    meta: { requiresAuth: true }
    // props: route => ({ userID: Number(this.$store.state.Login.userID) })
    // props: route => ({ userID: Number(1) })
  },
  {
    // 個別ページ：Detail
    path: '/:id/detail',
    name: 'detailPage',
    component: Detail,
    props: route => ({ id: Number(route.params.id) })
  },
  {
    // 個別ページ：VR
    path: '/:id/vr',
    name: 'vrPage',
    component: VRPage
  },
  {
    // ユーザー：新規登録
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    // ユーザー：ログイン
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    // ユーザー：アカウント情報
    path: '/account',
    name: 'account',
    component: Account
  },
  {
    // サイトポリシー
    path: '/sitepolicy',
    name: 'sitepolicy',
    component: SitePolicy
  },
  {
    // プライバシーポリシー
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: PrivacyPolicy
  },
  {
    // お問い合わせ
    path: '/inquiry',
    name: 'inquiry',
    component: Inquiry
  },
  {
    // ユーザー：パスワードリセット申請（アドレス入力）
    path: '/account/passward/reset-application',
    name: 'passResetApplication',
    component: PassResetApplication
  },
  {
    // ユーザー：パスワードリセット申請（アドレス入力）
    path: '/account/passward/reset/:uid/:token',
    name: 'passReset',
    component: PassReset,
    props: route => ({ uid: String(route.params.uid), token: String(route.params.token) })
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// router.beforeEach
// ：グローバルのフック関数すべてのページの繊維に対して設定
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.auth.login.token === false) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // next() を常に呼び出すようにしてください!
  }
})

export default router
