<template>
  <div>
  <v-container fluid>
    <!---movie-->
    <v-card
      style="overflow: hidden;"
      absolute
      v-resize="windowSize"
      :width="width"
      :height="height"
      dark
      flat
      color="#E5E5E5"
      tile
    >
      <transition name="fade">
      <v-img
        v-if="show"
        :width="width"
        :height="height"
        class="align-center"
        :src="require('../assets/loading_house.png')"
      >
        <v-row justify="center">
          <!-- <v-col class="text-center" cols="11" sm="9" md="5" max-width="200"> -->
          <v-col class="text-center" cols="5" max-width="200">
            <!-- <transition name="fade"> -->
            <v-img v-if="showlogo" src="../assets/archiroidcom_wide.png"></v-img>
            <!-- </transition> -->
          </v-col>
        </v-row>
      </v-img>
      </transition>
    </v-card>
  </v-container>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      height: 0,
      width: 0,
      show: true,
      showlogo: true
    }
  },
  mounted () {
    this.windowSize()
    setTimeout(() => {
      this.show = false
    }, 2500)
    setTimeout(() => {
      this.showlogo = false
    }, 2500)
  },
  beforeDestroy () {
    this.show = false
  },
  methods: {
    windowSize () {
      // this.height = window.innerHeight - document.getElementById('Header').clientHeight
      this.height = window.innerHeight
      this.width = window.innerWidth
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
