<template>
  <v-row class="SearchBar" no-gutters>
    <v-col :cols="k===1?2 :undefined" :sm="k===1?3 :k===2?3 :k===3?3 :k===4?3 :undefined" v-for="k in 4" :key="k">
      <div v-if="k===1">
        <v-card class="buttonedge-left" outlined tile>
          <v-card-title
            style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
          >
            <v-avatar size=20>
              <img src="@/assets/icon_Space_G.svg">
            </v-avatar>
            {{ filter_architecture_label }}
          </v-card-title>
        </v-card>
      </div>
      <div v-else-if="k===2">
        <v-card outlined tile>
          <v-select
            v-model="architecture_plan_value"
            :items="filter_architecture_plan_items"
            :label="filter_architecture_plan_label"
            multiple
            height=30
            color="black"
            item-color="gray"
            single-line
            :menu-props="{ maxHeight: 1800 }"
            @input="filter"
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @click="togglePlan"
              >
                <v-list-item-action>
                  <v-icon :color="architecture_plan_value.length > 0 ? 'brack' : ''">{{ planicon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>全て選択する</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item disabled>
                <v-list-item-avatar color="grey lighten-3">
                  <!-- <v-icon>mdi-home</v-icon> -->
                  <v-icon style="margin: auto;" size=24>{{svgPath_HomeVariant}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content v-if="selectAllPlan">
                  <v-list-item-title>全ての部屋が選択されました!</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-else-if="selectSomePlan">
                  <v-list-item-title>Selected Room Count</v-list-item-title>
                  <v-list-item-subtitle>{{ architecture_plan_value.length }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title>
                    必要な部屋を選択してください。含まれる家を検索します。
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    検索条件：AND
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, index }">
                <!--
                <span style="background-color: #ffffff;" v-if="index === 0">{{ item }}</span>
                <span
                  v-if="index === 1"
                  class="gray--text caption"
                > + {{ architecture_plan_value.length - 1 }}</span>-->
                <span
                  v-if="index === 0"
                  :key="item"
                  class="black--text caption"
                  style="font-size: 14px !important; font-weight: 700; white-space: nowrap; text-overflow: ellipsis;"
                > +{{ architecture_plan_value.length }} <span style="font-size: 11px !important; font-weight:500; color:gray; ">{{ filter_architecture_plan_label }}</span></span>
                <!-- overflow: hidden;-->
            </template>
          </v-select>
        </v-card>
      </div>
      <div v-else-if="k===3">
        <v-card outlined tile>
          <v-select
            v-model="architecture_options_value"
            :items="filter_architecture_options_items"
            :label="filter_architecture_options_label"
            multiple
            height=30
            color="black"
            item-color="gray"
            single-line
            :menu-props="{ maxHeight: 1800 }"
            @input="filter"
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @click="toggleOptions"
              >
                <v-list-item-action>
                  <v-icon :color="architecture_options_value.length > 0 ? 'brack' : ''">{{ optionsicon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>全て選択する</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item disabled>
                <v-list-item-avatar color="grey lighten-3">
                  <!-- <v-icon>mdi-home</v-icon> -->
                  <v-icon style="margin: auto;" size=24>{{svgPath_HomeVariant}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content v-if="selectAllOptions">
                  <v-list-item-title>全てのオプションが選択されました!</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-else-if="selectSomeOptions">
                  <v-list-item-title>Selected Option Count</v-list-item-title>
                  <v-list-item-subtitle>{{ architecture_options_value.length }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title>
                    必要なオプションを選択してください。含まれる家を検索します。
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    検索条件：AND
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, index }">
                <!--
                <span style="background-color: #ffffff;" v-if="index === 0">{{ item }}</span>
                <span
                  v-if="index === 1"
                  class="gray--text caption"
                > + {{ architecture_plan_value.length - 1 }}</span>-->
                <span
                  v-if="index === 0"
                  :key="item"
                  class="black--text caption"
                  style="font-size: 14px !important; font-weight: 700; white-space: nowrap; text-overflow: ellipsis;"
                > +{{ architecture_options_value.length }} <span style="font-size: 11px !important; font-weight:500; color:gray; ">{{ filter_architecture_options_label }}</span></span>
                <!-- overflow: hidden;-->
            </template>
          </v-select>
        </v-card>
      </div>
      <div v-else>
        <v-card class="buttonedge-right" outlined tile>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
              >
          <v-select
            v-model="architecture_floor_value"
            :items="filter_architecture_floor_items"
            :label="filter_architecture_floor_label"
            multiple
            height=30
            color="black"
            item-color="gray"
            single-line
            :menu-props="{ maxHeight: 1800 }"
            @input="filter"
            disabled
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @click="toggleFloor"
              >
                <v-list-item-action>
                  <v-icon :color="architecture_floor_value.length > 0 ? 'brack' : ''">{{ flooricon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>全て選択する</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item disabled>
                <v-list-item-avatar color="grey lighten-3">
                  <!-- <v-icon>mdi-home</v-icon> -->
                  <v-icon>{{svgPath_HomeVariant}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content v-if="selectAllFloor">
                  <v-list-item-title>全ての階数が選択されました!</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-else-if="selectSomeFloor">
                  <v-list-item-title>Selected Floor Count</v-list-item-title>
                  <v-list-item-subtitle>{{ architecture_floor_value.length }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title>
                    必要な階数を選択してください。含まれる家を検索します。
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    検索条件：AND
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, index }">
                <!--
                <span style="background-color: #ffffff;" v-if="index === 0">{{ item }}</span>
                <span
                  v-if="index === 1"
                  class="gray--text caption"
                > + {{ architecture_plan_value.length - 1 }}</span>-->
                <span
                  v-if="index === 0"
                  class=" caption"
                  style="font-size: 14px !important; font-weight: 700; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                >
                  <!-- +{{ architecture_floor_value.length }} {{ filter_architecture_floor_label }} -->
                  <!-- {{item}} {{ filter_architecture_floor_label }} -->
                  {{item}}
                </span>
            </template>
          </v-select>
          </div>
            </template>
            <div class="tips text-center">
              <span>【階数】</span><br><br>
            </div>
            <div class="tips">
              <span>現在は1階建ての平屋のみですが、</span><br>
              <span>今後、2階建て以上も発表していきます。</span>
            </div>
          </v-tooltip>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mdiHomeVariant, mdiCloseBox, mdiMinusBox, mdiCheckboxBlankOutline } from '@mdi/js'
// import Vue from 'vue'
export default {
  props: {
    filter_architecture_label: {
      type: String,
      require: false,
      default: 'Search'
    },
    filter_architecture_plan_label: {
      type: String,
      require: false,
      default: 'Rooms'
    },
    filter_architecture_plan_items: {
      type: Array,
      require: true
    },
    filter_architecture_plan_value: {
      type: Array,
      require: true
    },
    filter_architecture_options_label: {
      type: String,
      require: false,
      default: 'Options'
    },
    filter_architecture_options_items: {
      type: Array,
      require: true
    },
    filter_architecture_options_value: {
      type: Array,
      require: true
    },
    filter_architecture_floor_label: {
      type: String,
      require: false,
      default: 'Floors'
    },
    filter_architecture_floor_items: {
      type: Array,
      require: true
    },
    filter_architecture_floor_value: {
      type: Array,
      require: false
    }
  },
  data () {
    return {
      svgPath_HomeVariant: mdiHomeVariant,
      svgPath_CloseBox: mdiCloseBox,
      svgPath_MinusBox: mdiMinusBox,
      svgPath_CheckboxBlankOutline: mdiCheckboxBlankOutline,
      architecture_plan_value: this.filter_architecture_plan_value,
      architecture_options_value: this.filter_architecture_options_value,
      architecture_floor_value: this.filter_architecture_floor_value
    }
  },
  beforeCreate () {
    document.body.className = 'SearchBarBody'
  },
  methods: {
    filter () {
      this.$parent.filter_architecture_plan_value = this.architecture_plan_value
      this.$parent.filter_architecture_options_value = this.architecture_options_value
      this.$parent.filter_architecture_floor_value = this.architecture_floor_value
      this.$parent.filter()
    },
    togglePlan () {
      this.$nextTick(() => {
        if (this.selectAllPlan) {
          this.architecture_plan_value = []
        } else {
          this.architecture_plan_value = this.filter_architecture_plan_items.slice()
        }
        this.filter()
      })
    },
    toggleOptions () {
      this.$nextTick(() => {
        if (this.selectAllOptions) {
          this.architecture_options_value = []
        } else {
          this.architecture_options_value = this.filter_architecture_options_items.slice()
        }
        this.filter()
      })
    },
    toggleFloor () {
      this.$nextTick(() => {
        if (this.selectAllFloor) {
          this.architecture_floor_value = []
        } else {
          this.architecture_floor_value = this.filter_architecture_floor_items.slice()
        }
        this.filter()
      })
    }
  },
  computed: {
    selectAllPlan () {
      return this.architecture_plan_value.length === this.filter_architecture_plan_items.length
    },
    selectSomePlan () {
      return this.architecture_plan_value.length > 0 && !this.selectAllPlan
    },
    selectAllOptions () {
      return this.architecture_options_value.length === this.filter_architecture_options_items.length
    },
    selectSomeOptions () {
      return this.architecture_options_value.length > 0 && !this.selectAllOptions
    },
    selectAllFloor () {
      return this.architecture_floor_value.length === this.filter_architecture_floor_items.length
    },
    selectSomeFloor () {
      return this.architecture_floor_value.length > 0 && !this.selectAllFloor
    },
    planicon () {
      // if (this.selectAllPlan) return 'mdi-close-box'
      // if (this.selectSomePlan) return 'mdi-minus-box'
      // return 'mdi-checkbox-blank-outline'
      if (this.selectAllPlan) return this.svgPath_CloseBox
      if (this.selectSomePlan) return this.svgPath_MinusBox
      return this.svgPath_CheckboxBlankOutline
    },
    optionsicon () {
      // if (this.selectAllOptions) return 'mdi-close-box'
      // if (this.selectSomeOptions) return 'mdi-minus-box'
      // return 'mdi-checkbox-blank-outline'
      if (this.selectAllOptions) return this.svgPath_CloseBox
      if (this.selectSomeOptions) return this.svgPath_MinusBox
      return this.svgPath_CheckboxBlankOutline
    },
    flooricon () {
      // if (this.selectAllFloor) return 'mdi-close-box'
      // if (this.selectSomeFloor) return 'mdi-minus-box'
      // return 'mdi-checkbox-blank-outline'
      if (this.selectAllFloor) return this.svgPath_CloseBox
      if (this.selectSomeFloor) return this.svgPath_MinusBox
      return this.svgPath_CheckboxBlankOutline
    }
  }
}
</script>

<style scoped>
.theme--light.v-card.v-card--outlined {
  /*カードの枠線*/
  border: 0.5px solid #dadce0;
}
.v-card {
  /*カード設定*/
  height: 30px;
  margin: 0px;
  border-right: none !important;
}

.v-card__title {
  padding: 0px 0px 0px 4px;
  font-size: 14px;
  line-height: 28px;
  color: #bfbfbf;
}
.v-text-field {
  margin: 0px;
  padding: 0px 2px 0px 10px;
  height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.v-input {
  font-size: 14px;

}
.v-label {
  font-size: 14px;
}
.v-input input {
  max-height: 700px;
}
.v-select__slot {
  padding-left: 10px;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.v-list-item__title {
  font-weight:700;
}
.tips {
  font-size: 16px;
  font-weight: 600;
}
</style>
<style>
.SearchBarBody {
  padding-right: 0px !important;
}
/* .SearchBarBody .v-menu__content {
  margin-top: 40px !important;
} */
.SearchBar .v-label {
  font-size: 14px;
  color: black;
  font-weight:700
}

.SearchBar .buttonedge-left {
  /* カードの左のマル */
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}
.SearchBar .buttonedge-right {
  /* カードの左のマル */
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  border-right: 0.5px solid #dadce0 !important;
}

.SearchBar .v-text-field > .v-input__control > .v-input__slot:before,
.SearchBar .v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.SearchBar .v-text-field .v-input__prepend-inner,
.SearchBar .v-text-field .v-input__append-inner {
  margin: 0px;
  padding: 2.5px 0px 0px 0px !important;
  height: 30px;
}
.SearchBar .v-select__selections {
  flex-wrap: inherit;
}
@media (max-width: 500px) {
  .SearchBar .v-card{
    /*カードの枠線*/
    border-top: 1px solid #dadce0 !important;
    border-bottom: 1px solid #dadce0 !important;
    border-left: 1px solid #dadce0 !important;
    border-right: 0px solid #dadce0 !important;
  }
  .SearchBar .buttonedge-right {
    /* カードの左のマル */
    border-right: 1px solid #dadce0 !important;
  }
}
</style>
