<template>
  <v-hover v-slot="{ hover }">
    <v-card class="HouseCard" :elevation="hover && !isError ? 16 : 0" :class="{ 'on-hover': hover }" flat :hover="true" max-width="700" min-width="300" @click  ="viewdetail">
      <!-- <v-list-item class="cardArchiroid" href="http://unnizen.com/" target=”_blank”>
        <v-list-item-avatar color="#dadce0">
          <img src="@/assets/200710_UnniZen_icon_40px_white02.png">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <a href="http://unnizen.com/" target=”_blank” style="color: black; text-decoration: none;">{{ houseinfo.userName }}<v-icon color="black" size="14" style="left:2.5px; bottom:3px;">{{svgPath_Launch}}</v-icon></a>
          </v-list-item-title>
          <v-list-item-subtitle>Status:半人前</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item> -->
      <v-img v-if="planFlg" :aspect-ratio="1160/696" :src="houseinfo.plan_urls[0]" v-on:error="error" style="cursor: pointer;">
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0 align-center justify-center"
            style="background-color:#d7d7d7;"
          >
            <v-col cols="auto">
              <v-img class="mx-auto" src="@/assets/icon_VR_W.svg" width="120" height="120"></v-img>
              <div style="text-align: center; font-weight: 900; color:#ffffff;">アーキロイドが設計中です</div>
              <div style="text-align: center; font-weight: 900; color:#ffffff;">今しばらくお待ちください</div>
            </v-col>
          </v-row>
        </template>
      </v-img>
      <v-img v-else :aspect-ratio="400/240" :src="houseinfo.thum_url" v-on:error="error" style="cursor: pointer;">
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0 align-center justify-center"
            style="background-color:#d7d7d7;"
          >
            <v-col cols="auto">
              <v-img class="mx-auto" src="@/assets/icon_VR_W.svg" width="120" height="120"></v-img>
              <div style="text-align: center; font-weight: 900; color:#ffffff;">アーキロイドが設計中です</div>
              <div style="text-align: center; font-weight: 900; color:#ffffff;">今しばらくお待ちください</div>
            </v-col>
            <!-- <v-progress-circular indeterminate color="grey lighten-1"></v-progress-circular> -->
          </v-row>
        </template>
      </v-img>
      <v-list-item class="cardHouse" @click="(e) => {e.stopPropagation()}">
        <!-- <FavoriteButton :id=" houseinfo.project_id " :absolute="true" :top="true" :right="true" :countBack="true" @click.prevent="fav_count = $event"/> -->
        <FavoriteButton  :id=" houseinfo.project_id " :absolute="true" :top="true" :right="true" :countBack="true" @input="fav_count = $event"  />
        <v-list-item-content @click="viewdetail">
          <v-list-item-title>{{ houseinfo.projectName }}</v-list-item-title>
          <v-row style="margin:0px 0px 0px 0px">
            <v-col cols="auto" class="d-flex status itemText">{{ houseinfo.createdAt|date }}</v-col>
            <v-col cols="auto" class="d-flex status itemText">ID: {{ houseinfo.project_id }}</v-col>
            <!-- <v-col cols="auto" class="d-flex status itemText"><v-icon class="icon" size="16">visibility</v-icon><span style="margin-left:3px ;">200,000</span></v-col> -->
            <!-- <v-col cols="auto" class="d-flex status itemText"><v-icon class="icon" size="16">bookmark</v-icon><span>{{ fav_count | addComma }}</span></v-col> -->
            <v-col cols="auto" class="d-flex status itemText"><v-icon class="icon" style="bottom: 1.5px;" size="16">{{svgPath_Bookmark}}</v-icon><span>{{ fav_count | addComma }}</span></v-col>
            <!-- <v-col cols="auto" class="d-flex status iconWarn"><span style="text-align: middle;">建築不可</span></v-col> -->
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-card-text class="text--primary">
          <div style="height:20px;">￥{{ houseinfo.price | addComma }}</div>
          <div style="height:20px;">{{ houseinfo.floor_area | addComma }}㎡(延床面積)</div>
      </v-card-text> -->
      <v-divider v-if="dataFlg"></v-divider>
      <v-list-item v-if="dataFlg" class="cardArchiroid" >
        <v-list-item-avatar size="30" >
          <img src="@/assets/icon_Site_B.svg">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >
            <a style="color: black; text-decoration: none;">北海道室蘭市</a>
          </v-list-item-title>
          <v-list-item-subtitle @click="(e) => {e.stopPropagation()}">
            <a href="http://www.city.muroran.lg.jp/main/org2260/odlib.php"  target=”_blank” style="color: black; text-decoration: none;">
              <span style="font-weight:900;">土地</span> / むろらんオープンデータライブラリ
              <v-icon  size="14">{{svgPath_Launch}}</v-icon>
            </a>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="dataFlg" inset></v-divider>
      <v-list-item v-if="dataFlg" class="cardArchiroid" >
        <v-list-item-avatar size="30" >
          <img src="@/assets/icon_Architecture_B.svg">
        </v-list-item-avatar>
        <v-list-item-content >
          <v-list-item-title  >
            <a style="color: black; text-decoration: none;">￥{{ houseinfo.price | addComma }}（参考価格）</a>
          </v-list-item-title>
          <v-list-item-subtitle @click="(e) => {e.stopPropagation()}">
            <a href="http://unnizen.com/" target=”_blank” style="color: black; text-decoration: none;">
              <span style="font-weight:900;">建築</span> / 庵庭ゼン
              <v-icon  size="14">{{svgPath_Launch}}</v-icon>
            </a>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="dataFlg" inset></v-divider>
      <v-list-item v-if="dataFlg" class="cardArchiroid">
        <v-list-item-avatar size="30">
          <img src="@/assets/icon_Space_B.svg">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title @click="(e) => {e.stopPropagation()}">
            <a href="http://unnizen.com/" target=”_blank” style="color: black; text-decoration: none;">{{ houseinfo.floor_area | addComma }}㎡（延床面積）</a>
          </v-list-item-title>
          <v-list-item-subtitle><span style="font-weight:900;">空間</span> / {{ houseinfo.userName }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-row style="margin:0px 15px 10px 15px" @click="(e) => {e.stopPropagation()}" >
        <!-- <v-col style="padding:0px 5px 0px 0px">
          <DetailButton :id=" houseinfo.project_id " ref="dBt"/>
        </v-col> -->
        <!-- <v-col  style="padding:0px 0px 0px 5px">
          <VRButton :id=" houseinfo.project_id "/>
        </v-col> -->
        <v-col  style="padding:10px 0px 0px 0px" >
          <VRButton :id=" houseinfo.project_id " :disabled="isError" />
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
import { mdiLaunch, mdiBookmark } from '@mdi/js'
import FavoriteButton from '../components/Button/FavoriteButton'
import VRButton from '../components/Button/VRButton'
import moment from 'moment'
export default {
  components: {
    FavoriteButton,
    VRButton
  },
  props: {
    houseinfo: {
      type: Object,
      required: true
    },
    planFlg: {
      type: Boolean,
      required: false,
      default: false
    },
    dataFlg: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      svgPath_Launch: mdiLaunch,
      svgPath_Bookmark: mdiBookmark,
      fav_count: 0,
      isError: false
    }
  },
  filters: {
    addComma: function (value) {
      return value.toLocaleString(undefined, { maximumFractionDigits: 1 })
    },
    date: function (value) {
      return moment(value).format('YYYY/MM/DD')
    }
  },
  methods: {
    viewdetail () {
      if (this.isError) return
      // this.$refs.dBt.clickIcon()
      console.log(this.$route.fullPath)
      this.$router.push({ name: 'detailPage', params: { id: this.houseinfo.project_id } })
      // const routeData = this.$router.resolve({ name: 'detailPage', params: { id: this.houseinfo.project_id } })
      // window.open(routeData.href, '_blank')
    },
    error: function (event) {
      this.isError = true
    }
  }
}
</script>

<style scoped>
.v-list-item {
  padding: 0px 15px;
}
/* .cardArchiroid .v-list-item__content {
  padding: 15px 0px 10px 0px;
}
.cardArchiroid .v-list-item__avatar,
.cardArchiroid .v-list-item__avatar:first-child {
  margin: 10px 10px 10px 0px;
} */
.cardArchiroid .v-list-item__content {
  padding: 5px 0px 0px 0px;
}
.cardArchiroid .v-list-item__avatar,
.cardArchiroid .v-list-item__avatar:first-child {
  margin: 5px 10px 5px 5px;
}
.cardArchiroid .v-list-item__title {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 3px;
}
.cardArchiroid .v-list-item__subtitle,
.cardArchiroid .v-list-item__subtitle >>> a {
  font-size: 12px;
  font-weight: 500;
  color: #afafaf !important;
}
.cardHouse .v-list-item__content {
  padding: 10px 0px 10px 0px;
}
/* .cardHouse .v-list-item__content {
  padding: 10px 0px 0px 0px;
} */
.cardHouse .v-list-item__title {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 0px;
}
.cardHouse .status {
  margin: 0 10px 0 0;
  padding: 2px 0;
  height: 16px;
  vertical-align: middle;
}
.cardHouse .itemText {
  font-size: 12px;
  color:#afafaf;
}
.cardHouse .icon {
  color: #bfbfbf;
}
.cardHouse .iconWarn {
  font-size: 10px !important;
  font-weight: 900;
  background-color: #bfbfbf;
  color:#ffffff;
  /*ちゃんと中央来てる？padding: 3px 5px;*/
  padding: 3px 5px;
  vertical-align: middle;
  height: 16px;
  text-align: center;
}

.cardHouse .v-list-item__subtitle {
  font-size: 12px;
}

.theme--light.v-application .text--primary {
  padding: 15px 15px 25px 15px;
  font-size: 13px;
  font-weight: 500;
}

.btnText {
  font-weight: 900;
}
.v-divider--inset{
  margin:0 0 0 60px !important;
  max-width: calc(100% - 60px) !important;
}
</style>

<style>
.HouseCard .v-card,
.HouseCard {
  /*カードの枠線*/
  border: 1px solid #dadce0 !important;
}
@media (max-width: 500px) {
  .HouseCard .v-card {
    /*カードの枠線*/
    border: 1px solid #dadce0 !important;
  }
}
</style>
