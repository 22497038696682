<template>
  <v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
  <div
    v-bind="attrs"
    v-on="on"
  >
  <v-row class="SearchEnvironmentBar" no-gutters>
    <v-col :cols="k===1?2 :undefined" :sm="k===1?3 :k===2?3 :k===3?3 :k===4?3 :undefined" v-for="k in 4" :key="k">
      <div v-if="k===1">
        <v-card class="buttonedge-left" outlined tile>
          <v-card-title
            style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
          >
            <v-avatar size=20>
              <img src="@/assets/icon_Site_G.svg">
            </v-avatar>
            {{ filter_environment_label }}
          </v-card-title>
        </v-card>
      </div>
      <div v-else-if="k===2">
        <v-card outlined tile>
          <v-select
            :label="filter_environment_area_label"
            multiple
            height=30
            color="black"
            item-color="gray"
            single-line
            :menu-props="{ maxHeight: 1800 }"
            disabled
          >
          </v-select>
        </v-card>
      </div>
      <div v-else-if="k===3">
        <v-card outlined tile>
          <v-select
            :label="filter_environment_shape_label"
            multiple
            height=30
            color="black"
            item-color="gray"
            single-line
            :menu-props="{ maxHeight: 1800 }"
            disabled
          >
          </v-select>
        </v-card>
      </div>
      <div v-else>
        <v-card class="buttonedge-right" outlined tile>
          <v-select
            :label="filter_environment_road_label"
            multiple
            height=30
            color="black"
            item-color="gray"
            single-line
            :menu-props="{ maxHeight: 1800 }"
            disabled
          >
          </v-select>
        </v-card>
      </div>
    </v-col>
  </v-row>
  </div>
  </template>
  <div class="tips text-center">
    <span>【敷地】</span><br><br>
  </div>
  <div class="tips">
    <span>今後、敷地データ上に</span><br>
    <span>建設できる建築を検索可能にします。</span>
  </div>
</v-tooltip>
</template>

<script>
// import Vue from 'vue'
export default {
  props: {
    filter_environment_label: {
      type: String,
      require: false,
      default: 'Environment'
    },
    filter_environment_area_label: {
      type: String,
      require: false,
      default: 'Area'
    },
    filter_environment_shape_label: {
      type: String,
      require: false,
      default: 'Shape'
    },
    filter_environment_road_label: {
      type: String,
      require: false,
      default: 'RoadSide'
    }
  }
}
</script>

<style scoped>
.theme--light.v-card.v-card--outlined {
  /*カードの枠線*/
  border: 0.5px solid #dadce0;
}
.v-card {
  /*カード設定*/
  height: 30px;
  margin: 0px;
  border-right: none !important;
}
.v-card__title {
  padding: 0px 0px 0px 4px;
  font-size: 14px;
  line-height: 28px;
  color: #bfbfbf;
}
.v-text-field {
  margin: 0px;
  padding: 0px 2px 0px 10px;
  height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.v-input {
  font-size: 14px;

}
.v-label {
  font-size: 14px;
}
.v-input input {
  max-height: 700px;
}
.v-select__slot {
  padding-left: 10px;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.v-list-item__title {
  font-weight:700
}
.tips {
  font-size: 16px;
  font-weight: 700;
}
</style>
<style>
.SearchEnvironmentBar .v-label {
  font-size: 14px;
  /*color: black;*/
  font-weight:600
}

.SearchEnvironmentBar .buttonedge-left {
  /* カードの左のマル */
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}
.SearchEnvironmentBar .buttonedge-right {
  /* カードの左のマル */
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  border-right: 0.5px solid #dadce0 !important;
}

.SearchEnvironmentBar .v-text-field > .v-input__control > .v-input__slot:before,
.SearchEnvironmentBar .v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.SearchEnvironmentBar .v-text-field .v-input__prepend-inner,
.SearchEnvironmentBar .v-text-field .v-input__append-inner {
  margin: 0px;
  padding: 2.5px 0px 0px 0px !important;
  height: 30px;
}
.SearchEnvironmentBar .v-select__selections {
  flex-wrap: inherit;
}

@media (max-width: 500px) {
  .SearchEnvironmentBar .v-card{
    /*カードの枠線*/
    border-top: 1px solid #dadce0 !important;
    border-bottom: 1px solid #dadce0 !important;
    border-left: 1px solid #dadce0 !important;
    border-right: 0px solid #dadce0 !important;
  }
  .SearchEnvironmentBar .buttonedge-right {
    /* カードの左のマル */
    border-right: 1px solid #dadce0 !important;
  }
}
</style>
