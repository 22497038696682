<template>
  <div>
    <v-btn
      class="btnBookmark"
      :absolute="absolute"
      fab
      :top="top"
      :right="right"
      depressed
      small
      outlined
      v-show="fav===false"
      @click.native="favorite"
      :dark="dark"
      :loading="loading"
    >
      <img v-if="dark" src="@/assets/bookmark-white.png">
      <img v-else src="@/assets/bookmark-black.png">
      <!-- <v-icon>bookmark</v-icon> -->
      <!-- <v-icon>bookmark_border</v-icon> -->
    </v-btn>
    <v-btn
      class="btnBookmark_marked"
      :absolute="absolute"
      fab
      :top="top"
      :right="right"
      depressed
      small
      outlined
      v-show="fav===true"
      @click.native="unfavorite"
      color="#FFFFFF"
      :dark="dark"
      :loading="unloading"
    >
      <img src="@/assets/bookmark-white.png">
      <!-- <v-icon>bookmark</v-icon> -->
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    top: {
      type: Boolean,
      required: false,
      default: false
    },
    right: {
      type: Boolean,
      required: false,
      default: false
    },
    absolute: {
      type: Boolean,
      required: false,
      default: false
    },
    countBack: {
      type: Boolean,
      required: false,
      default: false
    },
    dark: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      fav: false,
      fav_count: 0,
      loading: false,
      unloading: false
    }
  },
  created () {
    // console.log('fav created')
    this.setFav()
  },
  // beforeRouteUpdate (to, from, next) {
  //   console.log('beforeRouteUpdate')
  //   next()
  //   this.setFav()
  // },
  methods: {
    favorite () {
      if (this.checkLoggedin()) {
        this.loading = true
        const sendData = {
          fav: 'true',
          project_id: this.id,
          user_id: this.$store.state.auth.login.user_id
        }
        this.sendFav(sendData)
      } else {
        this.showSwal('ログインしてください', 1200)
        // this.$router.push({ name: 'login' })
      }
    },
    unfavorite () {
      if (this.checkLoggedin()) {
        this.unloading = true
        const sendData = {
          fav: 'false',
          project_id: this.id,
          user_id: this.$store.state.auth.login.user_id
        }
        this.sendFav(sendData)
      }
    },
    sendFav (sendData) {
      this.$axios
        .post(this.$httpFavorite, sendData, { headers: this.$store.state.auth.login.headers })
        .then(response => {
          // console.log('お気に入り投稿・削除response↓')
          // console.log(response)
          this.setFav()
        })
        // .catch(error => {
        //   // console.log('お気に入り投稿・削除error.response↓')
        //   // console.log(error.response)
        // })
    },
    setFav () {
      // fav数を取得
      // console.log({ house_id: this.id })
      this.$axios
        .post(this.$httpHouseFavCnt, { project_id: this.id })
        .then(response => {
          this.fav_count = response.data.favCnt
          this.countBackToParent()
        })
      // 現在のユーザーがfavしているかを取得
      if (this.checkLoggedin()) {
        const data = {
          project_id: this.id,
          user_id: this.$store.state.auth.login.user_id
        }
        this.$axios
          .post(this.$httpFavoriteCheck, data, { headers: this.$store.state.auth.login.headers })
          .then(response => {
            this.fav = response.data.fav.toLowerCase() === 'true' // booleanに変更
            this.loading = false
            this.unloading = false
          })
          .catch(error => {
            console.log(error.response)
          })
      } else {
        // 未ログイン
        this.fav = false
      }
    },
    countBackToParent () {
      if (this.countBack) {
        this.$emit('input', this.fav_count)
      }
    }
  }
}
</script>

<style scoped>
.theme--dark.v-btn {
  background-color:rgba(0,0,0,0.75);
  border: 1px solid #dadce0;
}
.btnBookmark.theme--dark.v-btn {
  background-color:rgba(255,255,255,0.25);
  border: 1px solid #dadce0;
}
.btnBookmark{
  border:0.5px solid #dadce0 ;
  background-color: #FFFFFF;
}
@media (max-width: 500px) {
  .btnBookmark{
    border:1px solid #dadce0 ;
  }
}
.btnBookmark_marked{
  /* border:0.5px solid #dadce0 ; */
  border:none ;
  background-color: #000000;
}
</style>
