<template>
  <div class="Register">
    <v-row v-if="!this.completeFlg" justify="center">
      <v-col cols=12 sm=6 lg=4 xl=3>

        <h4>新規アカウント登録</h4>
        <v-row justify="center">
          <v-card class="elevation-0" width=360px style="padding-bottom: 30px;">
            <p class="mb-0">下記の項目を入力してください。<br>
            今一度、
            <router-link :to="{ name: 'sitepolicy'}"
            target="_blank"
            style="text-decoration: none; color: black; font-weight: bold;">
              サイトポリシー
            </router-link>
            および
            <router-link :to="{ name: 'privacypolicy'}"
            target="_blank"
            style="text-decoration: none; color: black; font-weight: bold;">
              プライバシーポリシー
            </router-link>
            をご確認ください。</p>
          </v-card>
        </v-row>

        <v-form ref="registForm">

          <v-row justify="center">
            <v-card class="elevation-0 auth-card" style="background-color: #F5F5F5;">
              <v-system-bar class="flex font-weight-bolld">
                <v-row justify="center">ログイン情報入力</v-row>
              </v-system-bar>

              <div class="layout column align-center">
                <v-row justify="center">
                  <p class="input-title">姓</p>
                  <p class="input-title-required">（必須）</p>
                </v-row>
                <v-row>
                  <v-text-field
                    name="last_name"
                    label="姓"
                    type="text"
                    dense
                    v-model="model.last_name"
                    :rules="blankRules"
                    :outlined=true
                    background-color="white"
                    required
                  ></v-text-field>
                </v-row>

                <v-row justify="center">
                  <p class="input-title">名</p>
                  <p class="input-title-required">（必須）</p>
                </v-row>
                <v-row>
                  <v-text-field
                    name="first_name"
                    label="名"
                    type="text"
                    dense
                    v-model="model.first_name"
                    :rules="blankRules"
                    :outlined=true
                    background-color="white"
                    required
                  ></v-text-field>
                </v-row>

                <v-row justify="center">
                  <p class="input-title">ユーザー名</p>
                  <p class="input-title-required">（必須）</p>
                </v-row>
                <v-row>
                  <v-text-field
                    name="name"
                    label="ユーザー名"
                    type="text"
                    dense
                    v-model="model.username"
                    :rules="blankRules"
                    :outlined=true
                    background-color="white"
                    required
                  ></v-text-field>
                </v-row>

                <v-row justify="center">
                  <p class="input-title">メールアドレス</p>
                  <p class="input-title-required">（必須）</p>
                </v-row>
                <v-row>
                  <v-text-field
                    name="login"
                    label="メールアドレス"
                    type="text"
                    dense
                    v-model="model.email"
                    :rules="emailRules"
                    :outlined=true
                    background-color="white"
                    required
                  ></v-text-field>
                </v-row>

                <v-row justify="center">
                  <p class="input-title">パスワード</p>
                  <p class="input-title-required">（必須）</p>
                </v-row>
                <v-row>
                  <v-text-field
                    name="password"
                    label="パスワード（半角英数字8~20字）"
                    id="password"
                    type="password"
                    dense
                    v-model="model.password1"
                    :rules="passwordRules"
                    :outlined=true
                    background-color="white"
                    required
                  ></v-text-field>
                </v-row>

                <v-row justify="center">
                  <p class="input-title">パスワード（確認）</p>
                  <p class="input-title-required">（必須）</p>
                </v-row>
                <v-row>
                  <v-text-field
                    name="passwordCheck"
                    label="パスワードの確認"
                    id="passwordCheck"
                    type="password"
                    dense
                    v-model="model.password2"
                    :rules="passwordRules"
                    :outlined=true
                    background-color="white"
                    required
                  ></v-text-field>
                </v-row>
              </div>
            </v-card>
          </v-row>

          <v-row justify="center">
            <v-card class="elevation-0 auth-card" style="background-color: #F5F5F5;">
              <v-system-bar class="flex font-weight-bolld">
                <v-row justify="center">アンケート</v-row>
              </v-system-bar>
              <div class="layout column align-center">
                <v-row justify="center">
                  <p class="input-title">年齢</p>
                  <p class="input-title-required">（必須）</p>
                </v-row>
                <v-row>
                  <v-select
                    :items="ageItems"
                    name="age"
                    type="int"
                    dense
                    chips
                    v-model="model.age"
                    :rules="blankRules"
                    :outlined=true
                    background-color="white"
                    required
                  >
                  </v-select>
                </v-row>

                <v-row justify="center">
                  <p class="input-title">業種</p>
                  <p class="input-title-required">（必須）</p>
                </v-row>
                <v-row>
                  <v-select
                    :items="industryItems"
                    name="industry"
                    type="text"
                    dense
                    chips
                    multiple
                    v-model="model.industry"
                    :rules="blankRules"
                    :outlined=true
                    background-color="white"
                    required
                  >
                  </v-select>
                </v-row>

                <v-row justify="center">
                  <p class="input-title">よろしければ、詳しく教えて下さい</p>
                </v-row>
                <v-row>
                  <v-textarea
                    name="aboutJob"
                    label="お勤め先名や具体的な業務内容など"
                    filled
                    v-model="model.aboutJob"
                    background-color="white"
                    auto-grow
                    :outlined=true
                  >
                  </v-textarea>
                </v-row>
              </div>
            </v-card>
          </v-row>

          <v-row justify="center">
            <v-card class="elevation-0 auth-card" style="background-color: #F5F5F5;">
              <v-system-bar class="flex font-weight-bolld">
                <v-row justify="center">archiroid.comについて</v-row>
              </v-system-bar>
              <div class="layout column align-center">
                  <v-row justify="center">
                    <p class="input-title">本サービスに期待すること</p>
                  </v-row>
                  <v-row>
                    <v-textarea
                      name="forWhat"
                      filled
                      v-model="model.forWhat"
                      background-color="white"
                      auto-grow
                      :outlined=true
                    >
                    </v-textarea>
                  </v-row>
              </div>
            </v-card>
          </v-row>

          <v-row justify="center">
            <div class="layout column align-center">
              <v-btn
                class="btn-auth btn-auth-black"
                style="margin-bottom: 10px;"
                rounded
                solo
                depressed
                color="black"
                @click="regist"
                :loading="loading"
                >
                登録
              </v-btn>
            </div>
          </v-row>

        </v-form>
      </v-col>
    </v-row>

  <v-row v-else justify="center">
      <v-col cols=12 sm=6 lg=4 xl=3>
        <h4>登録申請完了</h4>
        <p style="text-align:center; margin-bottom:0px;">ご登録ありがとうございます。</p>
        <p style="text-align:center; margin-bottom:60px;">メールをご確認の上、
          <br>ログインして引き続きアーキロイドをお楽しみください。</p>
        <v-row justify="center">
        <v-btn
          class="btn-auth btn-auth-black"
          rounded
          solo
          depressed
          color="black"
          @click="login"
          :loading="loading"
          >
          ログイン
        </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
  data: () => ({
    model: {
      last_name: '',
      first_name: '',
      username: '',
      password1: '',
      password2: '',
      email: '',
      age: '',
      industry: '',
      aboutJob: '',
      forWhat: ''
    },
    postModel: {
    },
    loading: false,
    blankRules: [
      v => !!v || '必須項目です。'
    ],
    industryRules: [
      v => v.length > 0 || 'Item is required in select 2'
    ],
    emailRules: [
      v => !!v || 'メールアドレスは必須項目です。',
      v => (v && v.length <= 128) || 'メールアドレスは128文字以内で入力してください。',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式が正しくありません。'
    ],
    passwordRules: [
      v => !!v || 'パスワードは必須項目です。',
      v => (v && v.length >= 8 && v.length <= 20) || '8~20字以内で入力してください。'
    ],
    passwordCheck: false,
    completeFlg: false,
    industryItems: ['ハウスメーカー・工務店', '意匠設計（建築）', '構造設計（建築）', '設備設計（建築）', '不動産業', '住宅関連産業', 'デザイン業', '製造業', '鉱業', '建設業（上記以外）', '運輸交通業', '貨物取扱業', '農林業', '畜産業', '水産業', '商業', '金融業', '広告業', '映画・演劇業', '通信業', '教育・研究業', '保健衛生業', '接客娯楽業', '官公庁', '派遣・警備業', 'その他の事業', '学生（建築系）', '学生'],
    ageItems: ['10歳以下', '10代', '20代', '30代', '40代', '50代', '60代', '70代', '80代', '90代', '100歳以上']
    // industryItems: industryCSV
  }),
  created () {
    if (this.checkLoggedin()) {
      this.$router.push({ name: 'account' })
    }
  },
  methods: {
    regist () {
      // APIでログイン認証を行う
      if (this.$refs.registForm.validate()) {
        this.loading = true
        if (this.model.password1 !== this.model.password2) {
          Swal.fire({
            type: 'warning',
            title: 'Error',
            text: 'パスワードが一致していません。',
            showConfirmButton: false,
            showCloseButton: false,
            timer: 3000
          })
          this.loading = false
        } else {
          // this.postModel = this.model.map(list => ({ ...list }))
          this.postModel = JSON.parse(JSON.stringify(this.model))
          this.postModel.industry = this.postModel.industry.join(',')

          if (this.postModel.aboutJob === '') {
            delete this.postModel.aboutJob
          }
          if (this.postModel.forWhat === '') {
            delete this.postModel.forWhat
          }

          this.$axios.post(this.$httpUserReist, this.postModel).then(res => {
            this.loading = false
            this.completeFlg = true

            // google analytics
            this.$gtm.trackEvent({
              event: 'sign_up',
              category: 'form',
              action: 'click'
            })
          }).catch(e => {
            this.loading = false
            const errrorText = this.getAxiosErrorString(e.response.data)
            Swal.fire({
              type: 'warning',
              title: 'Error',
              text: errrorText,
              showConfirmButton: false,
              showCloseButton: false
              // timer: 3000
            })
          })
        }
      }
    },
    login () {
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style scoped>
h4 {
  font-size: 21px;
  margin-top: 60px;
  margin-bottom: 60px;
  font-weight: 900;
  text-align: center;
}
h5 {
  font-size: 15px;
  margin-top: 60px;
  margin-bottom: 60px;
  font-weight: 900;
  text-align: center;
}
.regist-card {
  background-color: #F5F5F5;
  width: 360px;
  padding-bottom: 40px;
  margin-bottom: 60px;
}
.v-system-bar {
  background-color: #E5E2E2;
  min-height: 30px;
  font-size: 15px;
  font-weight: 900;
  margin-bottom: 30px;
  text-align: center;
}
.btn-auth-black {
  color: #ffffff;
  border: 0.5px solid;
}
.btn-auth-white {
  background-color: #ffffff;
  border: 0.5px solid #dadce0;
}
@media (max-width: 500px) {
  .btn-auth-black {
    border: 1px solid;
  }
  .btn-auth-white {
    border: 1px solid #dadce0 !important;
  }
}
.input-title {
  color: black;
  font-weight: 500;
  margin-bottom: 2px;
}
.input-title-required {
  color: #888888;
  font-weight: 500;
  margin-bottom: 2px;
}
</style>

<style>
.Register .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot{
  min-height: 30px;
  width: 280px;
  margin-bottom: 0px;
}
.Register .v-input input {
  max-height: 30px;
}
.Register .v-label {
  font-size: 14px;
}
.Register .v-text-field input {
  padding-top: 0px;
  padding-bottom: 0px;
}
.Register .v-textarea {
  width: 280px;
}
</style>
