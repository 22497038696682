
// import Swal from 'sweetalert2'

export default {
  methods: {
    // -------------------------------------------
    // ログイン関連
    // -------------------------------------------
    // ログイン状態を返す。frontでログイン状態だがbackで未ログイン状態の場合はfrontのログイン状態を抹消（App.vueのcreated時に1回呼び出している）
    checkLoggedin () {
      // console.log('checkLoggedin')
      if (this.$store.state.auth.login.token === false) {
        return false
      } else {
        this.$axios.get(this.$httpGetUserInfo, { headers: this.$store.state.auth.login.headers })
          .then(result => {
          }).catch(e => {
            this.$store.dispatch('setLogOut')
            this.accountinfo.name = this.$store.state.auth.login.username
            this.$session.destory()
            return false
          })
        return true
      }
    },
    // -------------------------------------------
    // 外部ライブラリ関連（Axios, Swal）
    // -------------------------------------------
    getAxiosErrorString (data) {
      // ------------------------------------------------
      // 後々これに変更する可能性あるのでコメントアウト残す
      // for (var i = 0; i < Object.keys(data).length; i++) {
      //   console.log(Object.keys(data)[i])
      //   console.log(data[i])
      //   console.log(data[Object.keys(data)[i]])
      // }
      // ------------------------------------------------
      const jsondata = JSON.stringify(data)
      let returnText = ''
      JSON.parse(jsondata, (key, value) => {
        if (!this.isEmpty(value) && !this.isArray(value)) {
          if (!this.isEmpty(returnText)) { returnText = returnText + ' | ' }
          returnText = returnText + value
        }
      })
      return returnText
    },
    showSwal (text, timer = 3000) {
      this.$swal.fire({
        // type: 'warning',
        // title: 'Error',
        text: text,
        showConfirmButton: false,
        showCloseButton: false,
        timer: timer
      })
    },
    showSwalError (text, timer = 3000) {
      this.$swall.fire({
        type: 'warning',
        title: 'Error',
        text: text,
        showConfirmButton: false,
        showCloseButton: false,
        timer: timer
      })
    },
    // -------------------------------------------
    // テキスト関連
    // -------------------------------------------
    isEmpty (obj) {
      if (obj === undefined || obj === null) {
        return true
      } else if (Object.prototype.toString.call(obj).slice(8, -1) === 'String') {
        if (obj === '') {
          return true
        }
      } else if (this.isArray(obj)) {
        if (obj.length === 0) {
          return true
        }
      } else if (Object.prototype.toString.call(obj).slice(8, -1) === 'Object') {
        if (!Object.keys(obj).length) {
          return true
        }
      }
      return false
    },
    isArray (obj) {
      if (Object.prototype.toString.call(obj).slice(8, -1) === 'Array') {
        return true
      }
      return false
    },
    // -------------------------------------------
    // デバイス関係
    // -------------------------------------------
    getDeviceType () {
      const ua = navigator.userAgent.toLowerCase()
      if ((ua.indexOf('iphone') > 0) || (ua.indexOf('ipad') > 0) || (ua.indexOf('ipod') > 0)) {
        return 'iOS'
      } else if (ua.indexOf('android') > 0) {
        return 'Android'
      }
      return 'PC'
    }
    // -------------------------------------------
    // 遷移関係
    // -------------------------------------------
    // // 住宅詳細ページに遷移する（新規タブ）
    // moveHouseDetail (url) {
    //   window.open(url, '_blank')
    // }
  }
  // filters: {
  //   addComma: function (value) {
  //     return value.toLocaleString()
  //   }
  // }
}
