<template>
  <div>
    <ScrollToTopButton />
    <v-container fluid class="DetailContainer">
      <v-row no-gutters>
        <v-col
          class="DetailCol"
          v-bind:class="{ 'pr-5': lgWindow }"
          cols="12"
          lg="8"
        >
          <v-img
            class="rounded-lg pa-0 ma-0"
            :src="houseinfo ? houseinfo.elevation_url : ''"
            :height="ElevationHeight"
            :aspect-ratio="500 / 300"
            v-on:error="error"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0 align-center justify-center"
                style="background-color: #d7d7d7"
              >
                <v-col cols="auto">
                  <v-img
                    class="mx-auto"
                    src="@/assets/icon_VR_W.svg"
                    width="120"
                    height="120"
                  ></v-img>
                  <div
                    style="text-align: center; font-weight: 900; color: #ffffff"
                  >
                    アーキロイドが設計中です
                  </div>
                  <div
                    style="text-align: center; font-weight: 900; color: #ffffff"
                  >
                    今しばらくお待ちください
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-img>
          <!-- <v-carousel
          class="rounded-lg pa-0 ma-0"
          hide-delimiter-background
          show-arrows
          delimiter-icon="・"
          :height="PersHeight"
        >
          <v-carousel-item
            v-for="(p_url) in houseinfo.pers_urls"
            :key="p_url"
          >
            <v-img
              :src="p_url"
              height=100%
              :aspect-ratio="500/300"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-1"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-carousel-item>
        </v-carousel> -->
        </v-col>
        <v-col
          class="DetailCol DetailCard"
          v-bind:class="{ 'pl-5': lgWindow }"
          cols="12"
          lg="4"
        >
          <v-card flat tile height="100%" v-bind:class="{ baseCard: lgWindow }">
            <v-row no-gutters class="DetailTitleRow">
              <v-card flat tile class="pa-0 ma-0">
                <v-card-title v-html="houseinfo.projectName" />
              </v-card>
              <v-spacer></v-spacer>
              <ShareNetwork
                network="twitter"
                :url="DetailUrl"
                :title="houseinfo.projectName"
                hashtags="archiroid"
                twitter-user="archiroid"
              >
                <div class="border mt-1 mr-9"></div>
                <font-awesome-icon
                  :icon="['fab', 'twitter']"
                  size="2x"
                  color="silver"
                />
                <!-- <v-icon class="mr-2" size="40">{{svgPath_Twitter}}</v-icon> -->
              </ShareNetwork>
              <ShareNetwork
                network="facebook"
                :url="DetailUrl"
                :title="houseinfo.projectName"
                description="archiroid.comで住宅製作中"
                hashtags="archiroid,アーキロイド"
              >
                <div class="border mt-1 mr-9"></div>
                <font-awesome-icon
                  :icon="['fab', 'facebook-square']"
                  size="2x"
                  color="silver"
                />
                <!-- <v-icon class="mr-2" size="40">{{svgPath_FaceBook}}</v-icon> -->
              </ShareNetwork>
              <ShareNetwork
                network="pinterest"
                :url="DetailUrl"
                :title="houseinfo.projectName"
                :media="houseinfo.elevation_url"
              >
                <div class="border mt-1 mr-9"></div>
                <font-awesome-icon
                  :icon="['fab', 'pinterest']"
                  size="2x"
                  color="silver"
                />
              </ShareNetwork>
              <ShareNetwork
                network="line"
                :url="DetailUrl"
                :title="houseinfo.projectName"
                description="archiroid.comで住宅製作中"
              >
                <div class="border mt-1 mr-9"></div>
                <font-awesome-icon
                  :icon="['fab', 'line']"
                  size="2x"
                  color="silver"
                />
              </ShareNetwork>
              <FavoriteButton
                class="pr-2"
                :id="$route.params.id"
                :countBack="true"
                @input="fav_count = $event"
                ref="favBtop"
              />
            </v-row>
            <v-card-subtitle class="DetailSubTitle">{{
              houseinfo.createdAt | date
            }}</v-card-subtitle>
            <v-row no-gutters class="dataRow">
              <!-- <v-col cols="6" lg="3" class="">{{houseinfo.createdAt|date}}</v-col> -->
              <v-col cols="6" lg="6" class=""
                >ID:{{ houseinfo.project_id }}</v-col
              >
              <!-- <v-col cols="6" lg="4" class=""><v-icon class="icon" size="16">visibility</v-icon><span style="margin-left:3px ;">200,000</span></v-col> -->
              <v-col cols="6" lg="6" class=""
                ><v-icon class="icon" style="bottom: 1.5px" size="16">{{
                  svgPath_Bookmark
                }}</v-icon
                ><span>{{ fav_count | addComma }}</span></v-col
              >
            </v-row>
            <v-data-table
              v-bind:class="{ baseCard4: lgWindow }"
              class="baseTable"
              :headers="baseheaders"
              :items="basedatas"
              :items-per-page="-1"
              hide-default-footer
              disable-sort
              mobile-breakpoint="0"
            >
              <template v-slot:[`item.icon`]="{ item }" class="pa-0">
                <v-img
                  class="typeIcon"
                  v-if="item.icon == 'site'"
                  height="30"
                  width="30"
                  src="@/assets/icon_Site_B.svg"
                />
                <v-img
                  class="typeIcon"
                  v-else-if="item.icon == 'architecture'"
                  height="30"
                  width="30"
                  src="@/assets/icon_Architecture_B.svg"
                />
                <v-img
                  class="typeIcon"
                  v-else-if="item.icon == 'space'"
                  height="30"
                  width="30"
                  src="@/assets/icon_Space_B.svg"
                />
              </template>
            </v-data-table>
            <Button
              v-bind:class="{ baseCard3: lgWindow }"
              style="margin-top: 20px"
              title="データをDL"
              :func="dlModalButton"
              :size="45"
              :mdi="svgPath_CloudDownload"
              color="#000000"
              :disabled="true"
            />
            <Button
              v-bind:class="{ baseCard2: lgWindow }"
              style="margin-top: 4px"
              title="グッズをつくる"
              :func="vrButton"
              :size="45"
              :mdi="svgPath_Shopping"
              :disabled="true"
            />
            <Button
              v-bind:class="{ baseCard1: lgWindow }"
              style="margin-top: 4px"
              title="VRでみる"
              :func="vrButton"
              :size="45"
              icon="VR"
              color="#000000"
              :disabled="isError"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="lgWindow" no-gutters style="padding: 0 50px">
        <v-card class="pictureCard" flat tile>
          <v-card-title>{{ houseinfo.projectName }} 正面立面</v-card-title>
        </v-card>
      </v-row>
      <!-- ///////////////////////////////////////////////////////////////2段目////////////////////////////////////////////////////////////////////// -->
      <v-row no-gutters>
        <v-col
          class="DetailCol"
          v-bind:class="{ 'pr-5': lgWindow }"
          cols="12"
          lg="8"
        >
          <!-- ///////////////////////////////////////////////////////////////外観////////////////////////////////////////////////////////////////////// -->
          <v-carousel
            class="rounded-lg"
            hide-delimiter-background
            show-arrows
            delimiter-icon="・"
            :height="PersHeight"
          >
            <v-carousel-item
              v-for="p_url in houseinfo.exterior_urls"
              :key="p_url"
            >
              <v-img :src="p_url" height="100%">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0 align-center justify-center"
                    style="background-color: #d7d7d7"
                  >
                    <v-col cols="auto">
                      <v-img
                        class="mx-auto"
                        src="@/assets/icon_VR_W.svg"
                        width="120"
                        height="120"
                      ></v-img>
                      <div
                        style="
                          text-align: center;
                          font-weight: 900;
                          color: #ffffff;
                        "
                      >
                        アーキロイドが設計中です
                      </div>
                      <div
                        style="
                          text-align: center;
                          font-weight: 900;
                          color: #ffffff;
                        "
                      >
                        今しばらくお待ちください
                      </div>
                    </v-col>
                  </v-row>
                </template>
              </v-img>
            </v-carousel-item>
          </v-carousel>
          <v-card v-if="lgWindow" class="pictureCard" flat tile>
            <v-card-title>{{ houseinfo.projectName }} 外観</v-card-title>
          </v-card>
          <!-- ///////////////////////////////////////////////////////////////内観////////////////////////////////////////////////////////////////////// -->
          <!-- <Panorama :source="houseinfo.interior_urls[0]" :caption="houseinfo.interior_urls[0]"/> -->
          <!-- <VuePannellum :src="houseinfo.interior_urls[0]" style="height: 500px;" crossOrigin="anonymous"/>
        <Pano title="The Title" width="720" height="480" :bundle="houseinfo.interior_urls" format="jpg"></Pano> -->
          <v-carousel
            :v-if="!isError"
            class="pictureFrame rounded-lg"
            style=""
            hide-delimiter-background
            show-arrows
            delimiter-icon="・"
            :height="PersHeight"
            touchless
          >
            <v-carousel-item
              v-for="p_url in houseinfo.interior_urls"
              :key="p_url"
            >
              <VuePannellum
                :src="p_url"
                :autoLoad="true"
                :hfov="500"
                :autoRotate="true"
                :showFullscreen="true"
                crossOrigin="anonymous"
              />
              <!-- <Pano title="The Title" width="720" height="480" :bundle="p_url" format="jpg"></Pano> -->
              <!-- <Panorama :source="p_url" :caption="p_url"/> -->
              <!-- <v-img
              :src="p_url"
              height=100%
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0 align-center justify-center"
                  style="background-color:#d7d7d7;"
                >
                  <v-col cols="auto">
                    <v-img class="mx-auto" src="@/assets/icon_VR_W.svg" width="120" height="120"></v-img>
                    <div style="text-align: center; font-weight: 900; color:#ffffff;">アーキロイドが設計中です</div>
                    <div style="text-align: center; font-weight: 900; color:#ffffff;">今しばらくお待ちください</div>
                  </v-col>
                </v-row>
              </template>
            </v-img> -->
            </v-carousel-item>
          </v-carousel>
          <v-card v-if="lgWindow" class="pictureCard" flat tile>
            <v-card-title>{{ houseinfo.projectName }} 内観</v-card-title>
          </v-card>
          <!-- ///////////////////////////////////////////////////////////////平面////////////////////////////////////////////////////////////////////// -->
          <v-img
            v-bind:class="{ smallPlan: !lgWindow }"
            class="pictureFrame rounded-lg"
            :src="houseinfo.plan_urls[0]"
            contain
            :aspect-ratio="1160 / 696"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0 align-center justify-center"
                style="background-color: #d7d7d7"
              >
                <v-col cols="auto">
                  <v-img
                    class="mx-auto"
                    src="@/assets/icon_VR_W.svg"
                    width="120"
                    height="120"
                  ></v-img>
                  <div
                    style="text-align: center; font-weight: 900; color: #ffffff"
                  >
                    アーキロイドが設計中です
                  </div>
                  <div
                    style="text-align: center; font-weight: 900; color: #ffffff"
                  >
                    今しばらくお待ちください
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-img>
          <v-card
            v-if="lgWindow"
            class="pictureCard"
            flat
            tile
            style="margin-bottom: 30px !important"
          >
            <v-card-title>{{ houseinfo.projectName }} 一階平面図</v-card-title>
          </v-card>
        </v-col>
        <!-- ///////////////////////////////////////////////////////////////DATA////////////////////////////////////////////////////////////////////// -->
        <v-col
          class="DetailCol DetailCard"
          v-bind:class="{ 'pl-5': lgWindow }"
          cols="12"
          lg="4"
        >
          <v-card flat tile>
            <v-data-table
              class="dataTable"
              :headers="headers"
              :items="sitedatas"
              :items-per-page="-1"
              hide-default-footer
              disable-sort
              mobile-breakpoint="0"
            >
              <template v-slot:[`item.icon`]="{ item }" class="pa-0">
                <v-img
                  class="typeIcon"
                  v-if="item.icon == 'site'"
                  height="30"
                  width="30"
                  src="@/assets/icon_Site_B.svg"
                />
                <v-img
                  class="typeIcon"
                  v-else-if="item.icon == 'architecture'"
                  height="30"
                  width="30"
                  src="@/assets/icon_Architecture_B.svg"
                />
                <v-img
                  class="typeIcon"
                  v-else-if="item.icon == 'space'"
                  height="30"
                  width="30"
                  src="@/assets/icon_Space_B.svg"
                />
              </template>
            </v-data-table>
            <v-data-table
              class="dataTable"
              :headers="headers"
              :items="architecturedatas"
              :items-per-page="-1"
              hide-default-footer
              disable-sort
              mobile-breakpoint="0"
            >
              <template v-slot:[`item.icon`]="{ item }" class="pa-0">
                <v-img
                  class="typeIcon"
                  v-if="item.icon == 'site'"
                  height="30"
                  width="30"
                  src="@/assets/icon_Site_B.svg"
                />
                <v-img
                  class="typeIcon"
                  v-else-if="item.icon == 'architecture'"
                  height="30"
                  width="30"
                  src="@/assets/icon_Architecture_B.svg"
                />
                <v-img
                  class="typeIcon"
                  v-else-if="item.icon == 'space'"
                  height="30"
                  width="30"
                  src="@/assets/icon_Space_B.svg"
                />
              </template>
            </v-data-table>
            <v-data-table
              class="dataTable"
              :headers="headers"
              :items="spacedatas"
              :items-per-page="-1"
              hide-default-footer
              disable-sort
              mobile-breakpoint="0"
            >
              <template v-slot:[`item.icon`]="{ item }" class="pa-0">
                <v-img
                  class="typeIcon"
                  v-if="item.icon == 'site'"
                  height="30"
                  width="30"
                  src="@/assets/icon_Site_B.svg"
                />
                <v-img
                  class="typeIcon"
                  v-else-if="item.icon == 'architecture'"
                  height="30"
                  width="30"
                  src="@/assets/icon_Architecture_B.svg"
                />
                <v-img
                  class="typeIcon"
                  v-else-if="item.icon == 'space'"
                  height="30"
                  width="30"
                  src="@/assets/icon_Space_B.svg"
                />
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- /////////////////////////////////////////////////////////////// Download Modal ////////////////////////////////////////////////////////////////////// -->
    <v-dialog v-model="dlModal" max-width="600px">
      <DownloadCard @modalToggle="dlModalButton" ref="downloadModal" />
    </v-dialog>
  </div>
</template>
<script>
import { mdiLaunch, mdiBookmark, mdiDownload, mdiShopping, mdiTwitter, mdiFacebook } from '@mdi/js'
// import HouseCard from '../components/HouseCard'
// import AidingCard from '../components/AidingCard'
import FavoriteButton from '../components/Button/FavoriteButton'
// import VRButton from '../components/Button/VRButton'
// import SearchButton from '../components/Button/SearchButton'
import ScrollToTopButton from '../components/Button/ScrollToTopButton'
// import DownloadButton from '../components/Button/DownloadButton'
// import InquiryButton from '../components/Button/InquiryButton'
import Button from '../components/Button/Button'
import DownloadCard from '../components/DownloadCard'
import moment from 'moment'
import VuePannellum from 'vue-pannellum'
// import { loadScript } from '@paypal/paypal-js'
// eslint-disable-next-line no-undef
export default {
  components: {
    // HouseCard,
    // AidingCard,
    FavoriteButton,
    // VRButton,
    // SearchButton,
    ScrollToTopButton,
    // DownloadButton,
    // InquiryButton
    Button,
    // Panorama,
    VuePannellum,
    DownloadCard
  },
  data () {
    return {
      svgPath_Launch: mdiLaunch,
      svgPath_Bookmark: mdiBookmark,
      svgPath_CloudDownload: mdiDownload,
      svgPath_Shopping: mdiShopping,
      houseinfo: {
        project_id: '',
        price: '',
        archi_area: 0,
        floor_area: 0,
        thum_url: '',
        pers_urls: '',
        plan_urls: '',
        section_urls: [],
        house_id: '',
        projectName: '',
        userName: '',
        createdAt: '',
        elevation_url: '',
        exterior_urls: [],
        interior_urls: []
      },
      svgPath_Twitter: mdiTwitter,
      svgPath_FaceBook: mdiFacebook,
      fav_count: 0,
      houseList: [],
      wordProgress: 'in-progress',
      architectName: '庵庭 ゼン',
      houseStructure: '木造在来工法',
      fdStructure: '布基礎',
      ZoneCategory: '指定なし',
      planWidth: 500,
      planHeight: 0,
      bigWindow: true,
      smallWindow: true,
      xlWindow: true,
      lgWindow: true,
      mdWindow: true,
      smWindow: true,
      xsWindow: true,
      isError: false,
      baseheaders: [
        { text: 'TYPE', align: 'start', value: 'icon', width: '50', class: 'px-0' },
        { text: '', align: 'start', value: 'type', width: '50', class: 'px-0' },
        { text: 'SUBJECT', value: 'subject', width: '48%', class: 'px-0' },
        { text: 'HOLDER/DESIGNER', value: 'holder', width: '33.333%', class: 'px-0' }
      ],
      basedatas: [],
      // basedatas: [
      //   {
      //     icon: 'site',
      //     type: '土地',
      //     subject: '北海道室蘭市',
      //     holder: '架空の土地'
      //   },
      //   {
      //     icon: 'architecture',
      //     type: '建築',
      //     subject: '27,980,000円（参考）',
      //     holder: '庵庭 ゼン'
      //   },
      //   {
      //     icon: 'space',
      //     type: '空間',
      //     subject: '85.5㎡（延床）',
      //     holder: '亀岡 千花'
      //   }
      // ]
      headers: [
        { text: 'TYPE', align: 'start', value: 'icon', width: '50', class: 'px-0' },
        { text: '', align: 'start', value: 'type', width: '120', class: 'px-0' },
        { text: 'CONTENT', value: 'content', width: 'auto', class: 'px-0' }
      ],
      sitedatas: [
        {
          icon: 'site',
          type: '土地'
        },
        { type: '所有者', content: '架空の土地' },
        { type: '物件種目', content: '-' },
        { type: '最寄駅', content: '輪西駅' },
        { type: '土地面積', content: '225.5㎡' },
        { type: '価格', content: '-' },
        { type: '所在地', content: '北海道室蘭市' },
        { type: '交通', content: 'JR室蘭本線「輪西駅」徒歩10分' },
        { type: '土地権利', content: '所有権' },
        { type: '都市計画', content: '市街化区域' },
        { type: '建蔽率', content: '50％' },
        { type: '容積率', content: '100%' },
        { type: '地目', content: '宅地 地勢：高台' },
        { type: '用途地域', content: '第一種住居地域' },
        { type: '現況', content: '更地' },
        { type: '接道条件', content: '一方 南側幅5.4ｍの公道に11.8ｍ接道 位置指定：無' },
        { type: '引き渡し', content: '-' },
        { type: '設備', content: '都市ガス・公営水道・浄化槽' },
        { type: '取引形態', content: '※本土地は参考です。媒介は実施しておりません。' },
        { type: '備考', content: '建築条件 なし' }
      ],
      architecturedatas: [],
      spacedatas: [],
      dlModal: false
      // meta: { type: 'article', title: this.houseinfo.projectName, description: 'archiroid.comのお家だよ', url: this.DetailUrl, image: this.houseinfo.elevation_url, card: 'summary_large_image' }
    }
  },
  // beforeMount () {
  //   console.log('beforeMount')
  //   console.log(this.houseinfo)
  //   // this.houseinfo = { projectName: 'abcdef' }
  //   // console.log(this.houseinfo)
  //   this.houseinfo.projectName = 'abcdef'
  // },
  created () {
    // console.log('###created')
    this.setPage(this.$route.params.id)
  },
  mounted () {
    this.windowSize()
    window.addEventListener('resize', this.windowSize)
    // console.log('###mounted')
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.windowSize)
  },
  updated () {
    // console.log('###update')
    this.$refs.favBtop.setFav()
    // this.$refs.favBmid.setFav()
  },
  beforeRouteUpdate (to, from, next) {
    // console.log('###beforeRouteUpdate')
    this.setPage(to.params.id)
    next()
  },
  filters: {
    addComma: function (value) {
      return value.toLocaleString(undefined, { maximumFractionDigits: 1 })
    },
    date: function (value) {
      return moment(value).format('YYYY年MM月DD日_HH時mm分ss秒')
    }
  },
  methods: {
    error: function (event) {
      this.isError = true
    },
    setPage: async function (houseIndex) {
      // console.log('setPage')
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      // const res = await this.$axios.get(this.$httpGetHouse, {
      const { data: houseinfo } = await this.$axios.get(this.$httpGetHouse, {
        params: {
          id: houseIndex // id: this.$route.params.id
        }
      })
      // this.houseinfo = res.data
      // console.log(houseinfo)
      this.houseinfo = houseinfo
      this.makeBaseData()
      this.makeArchitectureData()
      this.makeSpaceData()

      // this.$emit('updateHead')
      // var res2 = await this.$axios.get(this.$httpGetAllHouseNum)
      // var randoms = []/** 重複チェック用配列 */
      // this.houseList = []
      // /** 最小値と最大値 */
      // var min = 1
      // var max = res2.data
      // /** 重複チェックしながら乱数作成 */
      // const makeList = async () => {
      //   for (var i = min; i <= max; i++) {
      //     while (true) {
      //       var tmp = Math.floor(Math.random() * (max - min + 1)) + min
      //       if (!randoms.includes(tmp)) {
      //         randoms.push(tmp)
      //         this.$axios.get(this.$httpGetHouse, {
      //           params: {
      //             id: tmp
      //           }
      //         })
      //           .then(response3 => {
      //             this.houseList.push(response3.data)
      //           })
      //         break
      //       }
      //     }
      //     if (randoms.length === 6) { return this.houseList }
      //   }
      // }
      // makeList()
      // var path = this.houseinfo.plan_urls[0]
      // var element = new Image()
      // element.src = path
      // this.planWidth = element.naturalWidth
      // this.planHeight = element.naturalHeight
    },
    windowSize () {
      // this.height = window.innerHeight - document.getElementById('Header').clientHeight
      this.height = window.innerHeight
      this.width = window.innerWidth
      if (this.width >= 850) {
        this.bigWindow = true
      } else {
        this.bigWindow = false
      }
      if (this.width >= 400) {
        this.smallWindow = true
      } else {
        this.smallWindow = false
      }
      console.log(this.width)
      if (this.width >= 1560) { this.xlWindow = true } else { this.xlWindow = false }
      if (this.width >= 1264) { this.lgWindow = true } else { this.lgWindow = false }
      if (this.width >= 1030) { this.mdWindow = true } else { this.mdWindow = false }
      if (this.width >= 670) { this.smWindow = true } else { this.smWindow = false }
      if (this.width >= 0) { this.xsWindow = true } else { this.xsWindow = false }
      console.log(this.xlWindow)
    },
    makeBaseData () {
      this.basedatas = [
        {
          icon: 'site',
          type: '土地',
          subject: '北海道室蘭市',
          holder: '架空の土地'
        },
        {
          icon: 'architecture',
          type: '建築',
          subject: this.houseinfo.price.toLocaleString(undefined, { maximumFractionDigits: 1 }) + '円',
          holder: '庵庭 ゼン'
        },
        {
          icon: 'space',
          type: '空間',
          subject: this.houseinfo.floor_area.toLocaleString(undefined, { maximumFractionDigits: 1 }) + '㎡(延床)',
          holder: this.houseinfo.userName
        }
      ]
    },
    makeArchitectureData () {
      this.architecturedatas = [
        {
          icon: 'architecture',
          type: '建築'
        },
        { type: '設計者', content: '庵庭 ゼン' },
        { type: '参考価格', content: this.houseinfo.price.toLocaleString(undefined, { maximumFractionDigits: 1 }) + '円' },
        { type: '物件種別', content: '新築' },
        { type: '規模', content: '地上一階' },
        { type: '構造', content: '木造在来金物工法' },
        { type: '屋根構法', content: '真澄寄棟屋根' }
        // { type: '最高高さ', content: '？？' },
        // { type: '最高軒高', content: '？？' }
      ]
    },
    makeSpaceData () {
      this.spacedatas = [
        {
          icon: 'space',
          type: '空間'
        },
        { type: '設計者', content: this.houseinfo.userName },
        { type: '建築面積', content: this.houseinfo.floor_area.toLocaleString(undefined, { maximumFractionDigits: 1 }) + '㎡' },
        { type: '建蔽率', content: ((this.houseinfo.floor_area * 100) / 225.574713).toLocaleString(undefined, { maximumFractionDigits: 1 }) + '% / 50%' },
        { type: '容積率', content: ((this.houseinfo.floor_area * 100) / 225.574713).toLocaleString(undefined, { maximumFractionDigits: 1 }) + '% / 100%' },
        { type: '一階床面積', content: this.houseinfo.floor_area.toLocaleString(undefined, { maximumFractionDigits: 1 }) + '㎡' },
        { type: '延床面積', content: this.houseinfo.floor_area.toLocaleString(undefined, { maximumFractionDigits: 1 }) + '㎡' }
        // { type: '各室面積', content: '？？' }
      ]
    },
    dlModalButton: function (event) {
      this.dlModal = !this.dlModal
      if (this.dlModal && this.$refs.downloadModal !== undefined) {
        this.$refs.downloadModal.initStep()
      }
    },
    vrButton: function (event) {
      this.$router.push({ name: 'vrPage', params: { id: this.$route.params.id } })
      // const routeData = this.$router.resolve({ name: 'vrPage', params: { id: this.$route.params.id } })
      // window.open(routeData.href, '_blank')
    }
  },
  computed: {
    SectionName: function () {
      return function (pathName) {
        let splits = pathName.split('/')
        splits = splits[splits.length - 1]
        splits = splits.split('_')
        splits = splits[splits.length - 1]
        splits = splits.split('.')
        splits = splits[0]
        splits = splits.split('z')
        let tag = splits[0]
        if (tag === 'x') {
          tag = 'Y'
        } else {
          tag = 'X'
        }
        const nam = parseInt(splits[1], 10) + 1
        const fileName = tag + nam
        return fileName
      }
    },
    ElevationHeight: function () {
      if (this.mdWindow === true) {
        return 650
      } else if (this.smWindow === true) {
        return 430
      } else {
        return 200
      }
    },
    PersHeight: function () {
      if (this.smWindow === true) {
        return 550
      } else {
        return 250
      }
    },
    PlanHeight: function () {
      if (this.smWindow === true) {
        return this.height - 191
      } else {
        return 400
      }
    },
    // PlanHeight: function () {
    //   if (this.smallWindow === true) {
    //     return 750
    //   } else {
    //     return 400
    //   }
    // },
    SectionHeight: function () {
      if (this.smallWindow === true) {
        return 500
      } else {
        return 230
      }
    },
    DetailUrl: function () {
      return location.origin + this.$route.fullPath
    }
    // PersImageUrl: function () {
    //   return 'https://img.archiroid.com/' + ('00000000' + this.$route.params.id).slice(-8) + '/perspective_0000.jpg'
    // }
  }
}
</script>

<style scoped>
.DetailContainer >>> .row {
  padding: 30px 50px 0;
}
.DetailContainer >>> .dataRow {
  padding: 10px 0 0;
}
.DetailContainer >>> .dataRow .col,
.DetailContainer >>> .dataRow .col-lg-3,
.DetailContainer >>> .dataRow .col-6,
.DetailContainer >>> .dataRow .col-12 {
  font-size: 14px;
  font-weight: 900;
  color: #b3b3b3;
  border-right: 1px solid #dadce0;
  line-height: 1;
  padding: 0 3px;
}
/* .DetailContainer >>> .DetailCol {
  margin:0;
} */
/* .DetailContainer >>> .v-btn--active:before,
.DetailContainer >>> .v-btn:before {
  opacity: 0 !important;
} */
.DetailContainer >>> .v-item--active >>> .v-carousel__controls__item,
.DetailContainer >>> .v-btn--active >>> .v-icon {
  opacity: 1 !important;
}
.DetailContainer >>> .DetailTitleRow {
  padding: 30px 0 0;
}
.DetailCard >>> .v-card__title {
  padding: 0;
  font-size: 40px;
  font-weight: 900;
  color: #191919;
  line-height: 1;
  width: 100%;
  /* border-top: 1px solid #dadce0; */
  /* border-bottom: 1px solid #dadce0; */
}
.DetailSubTitle {
  padding: 10px 0 0 !important;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  color: #191919 !important;
}
.pictureFrame {
  margin-top: 30px;
}
.pictureCard >>> .v-card__title {
  font-size: 13px;
  font-weight: 900;
  color: #191919;
  padding: 10px 0 0;
  line-height: 1;
}
.baseTable {
  padding: 20px 0 0;
}
.dataTable {
  padding: 0 0 30px;
}
.smallPlan {
  margin-bottom: 30px;
}
.v-data-table >>> th {
  border-bottom: 1px solid #dadce0 !important;
  font-weight: 900 !important;
}
.v-data-table >>> td {
  padding: 0 !important;
  /* font-size:14px !important; */
  font-weight: 900;
  border-bottom: 1px solid #dadce0 !important;
}
.typeIcon {
  margin: 0 10px 0 10px !important;
}
.baseCard {
  position: relative;
}

.baseCard4 {
  position: absolute;
  bottom: 183px;
  width: 100%;
}
.baseCard3 {
  position: absolute;
  bottom: 98px;
  width: 100%;
}
.baseCard2 {
  position: absolute;
  bottom: 49px;
  width: 100%;
}
.baseCard1 {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
@media (max-width: 1030px) {
  .DetailContainer >>> .row {
    padding: 20px 20px;
  }
  .DetailContainer >>> .dataRow {
    padding: 10px 0 0;
  }
  .DetailCard >>> .DetailTitleRow {
    padding: 20px 0 0;
  }
}
@media (max-width: 670px) {
  .DetailCard >>> .v-card__title {
    font-size: 32px;
  }
  .DetailSubTitle {
    font-size: 14px;
    padding: 0 !important;
  }
  .v-data-table >>> th {
    font-size: 8px !important;
  }
  .v-data-table >>> td {
    font-size: 13px !important;
    height: 25px !important;
  }
  .dataTable {
    padding: 0 0 10px;
  }
  .pictureFrame {
    margin-top: 10px;
  }
}
/* .list-item-detail .v-list-item__content {
  padding: 10px 0px 0px 0px;
}
.list-item-detail .v-list-item__title {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 0px;
}
.list-item-detail .status {
  margin: 0 10px 0 0;
  padding: 2px 0;
  height: 16px;
  vertical-align: middle;
}
.list-item-detail .itemText {
  font-size: 12px;
  color:#afafaf;
}
.list-item-detail .icon {
  color: #bfbfbf;
}
.list-item-detail .iconWarn {
  font-size: 10px;
  font-weight: 900;
  background-color: #bfbfbf;
  color:#ffffff;
  padding: 3px 5px;
  vertical-align: middle;
  height: 16px;
  text-align: center;
}
.list-item-detail .v-list-item__subtitle {
  font-size: 12px;
}
.drawing-card {
  margin-top: 40px;
  padding: 5px;
}
.border-b {
  border-color: black;
  border-width: 1.5px;
  background-color: black;
}
.index-text {
  margin: 0px;
  line-height: 36px;
  font-size: 12px;
  font-weight: bold;
}
.border-t {
  border-color: #B3B3B3;
  border-width: 0.5px;
}
.list-item-indent {
  margin: 0px 0px 0px 30px;
  line-height: 36px;
  font-size: 12px;
}
.list-item-value {
  margin: 0px;
  line-height: 36px;
  font-size: 12px;
}
.sortBar {
  margin-top: 30px;
  font-size: 14px;
  height: 20px;
}
.sortBar .result {
  font-weight: 700;
}
.bookmarkText {
  font-size: 14px;
  font-weight: 400;
}
.filterBookmark {
  border:0.5px solid #dadce0;
} */
</style>
<style>
/* .Detail .v-list-item {
  padding-left: 0px;
  padding-right: 0px;
}
.Detail .v-img {
  margin: 0px;
  padding: 0px;
}
.DetailPlan .v-btn .v-btn__content .v-icon {
  color: black;
}
.DetailPlan .theme--dark.v-btn.v-btn--icon {
  color: rgba(0,0,0);
}
.DetailPlan .v-card--flat.v-sheet.v-sheet--tile {
  height: 90%;
} ここまで*/
/* .DetailPlan v-card--flat v-sheet v-sheet--tile theme--light */
/* .Detail a {
  text-decoration: none;
} */
/* .Detail .v-responsive__content {
  margin: 0px;
  height: 1000px;
  max-width:100%;
} */
/* .Detail .v-carousel__controls {
  height: 30px;
  background: rgba(0, 0, 0, 0.0);
} */
/* .DetailPlan .v-image__image, .v-image__placeholder {
  width: 1000px;
  height: 93%;
} */
/* .DetailPlan .v-responsive v-image v-carousel__item {
  height: 1000px;
} */
/* .DetailPlan @media (max-width: 1000px) {
  img {
    width: 100px;
    height: auto;
  }
} */
</style>
