<template>
  <div class="Login">
    <v-row justify="center">
      <v-col cols=12 sm=6 lg=4 xl=3>

        <h4>ログイン</h4>

        <v-row justify="center">
          <v-card class="elevation-0 auth-card" style="background-color: #F5F5F5;">

            <v-system-bar class="flex font-weight-bolld">
              <v-row justify="center">ユーザー登録済みの方</v-row>
            </v-system-bar>

            <div class="layout column align-center">
              <v-form ref="loginForm">
                <v-text-field
                  name="login"
                  label="メールアドレス"
                  type="text"
                  dense
                  height=30
                  v-model="model.email"
                  background-color="white"
                  :rules="emailRules"
                  :outlined=true
                  required
                ></v-text-field>
                <v-text-field
                  name="password"
                  label="パスワード"
                  id="password"
                  type="password"
                  dense
                  height=30
                  v-model="model.password"
                  background-color="white"
                  :rules="passwordRules"
                  :outlined=true
                  required
                ></v-text-field>
              </v-form>
              <v-btn
                class="btn-auth btn-auth-black"
                style="margin-top: 10px;"
                rounded
                solo
                depressed
                color="black"
                @click="login"
                :loading="loadingLogin"
                >
                ログイン
              </v-btn>
            </div>
          </v-card>
        </v-row>

        <v-row justify="center">
          <v-card class="elevation-0 auth-card" style="background-color: #F5F5F5;">
            <v-system-bar class="flex font-weight-bolld">
              <v-row justify="center">パスワードを忘れた方</v-row>
            </v-system-bar>
            <div class="layout column align-center">
              <v-btn
                class="btn-auth btn-auth-white"
                rounded
                solo
                depressed
                outlined
                @click="reset"
                :loading="loadingRegister"
                >
                リセット
              </v-btn>
            </div>
          </v-card>
        </v-row>

        <v-row justify="center">
          <v-card class="elevation-0 auth-card" style="background-color: #F5F5F5;">
            <v-system-bar class="flex font-weight-bolld">
              <v-row justify="center">新規ユーザー登録の方</v-row>
            </v-system-bar>
            <div class="layout column align-center">
              <v-btn
                class="btn-auth btn-auth-white"
                rounded
                solo
                depressed
                outlined
                @click="regist"
                :loading="loadingRegister"
                >
                新規登録
              </v-btn>
            </div>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data: () => ({
    loadingLogin: false,
    loadingRegister: false,
    valid: true,
    emailRules: [
      v => !!v || 'メールアドレスは必須項目です。',
      v => (v && v.length <= 128) || 'メールアドレスは128文字以内で入力してください。',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式が正しくありません。'
    ],
    passwordRules: [
      v => !!v || 'パスワードは必須項目です。',
      v => (v && v.length <= 32) || 'パスワードは32文字以内で入力してください。'
    ],
    model: {
      email: '',
      password: ''
    }
  }),
  created () {
    if (this.checkLoggedin()) {
      this.$router.push({ name: 'account' })
    }
  },
  methods: {
    login: async function () {
      let token
      // -----------------------------------------
      // APIでログイン認証を行う
      // 参考：https://qiita.com/trustbank_kei/items/c72c57b54332431c67ec
      if (this.$refs.loginForm.validate()) {
        this.loadingLogin = true
        axios.post(this.$httpUserLogin, this.model).then(res => {
          token = res.data.token
          // console.log('login token : ' + token)
          const base64Url = token.split('.')[1]
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
          const dd = JSON.parse(decodeURIComponent(escape(window.atob(base64))))
          const cacheHeaders = {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + token
          }
          axios.get(this.$httpGetUserInfo, { headers: cacheHeaders })
            .then(result => {
              // console.log(result)
              // console.log(result.data.email)
              const login = {
                token: token,
                username: result.data.username,
                email: result.data.email,
                user_id: result.data.pk,
                expire: dd.exp,
                headers: cacheHeaders
              }

              // google analytics
              this.$gtm.trackEvent({
                event: 'login',
                category: 'form',
                action: 'click'
              })
              this.$store.dispatch('setLoginInfo', login)// src/store/module/auth.js
              this.$router.push({ name: 'account' })
            })
        }).catch(e => {
          this.loadingLogin = false
          this.showSwal('ユーザー名もしくはパスワード、または両方が間違っています')
          this.model.password = ''
        })
        this.loadingLogin = false
      }
    },
    regist () {
      this.$router.push({ name: 'register' })
    },
    reset () {
      this.$router.push({ name: 'passResetApplication' })
    }
  }
}
</script>

<style scoped>
h4 {
  font-size: 21px;
  margin-top: 60px;
  margin-bottom: 60px;
  font-weight: 900;
  text-align: center;
}
h5 {
  font-size: 15px;
  margin-top: 60px;
  margin-bottom: 60px;
  font-weight: 900;
  text-align: center;
}
.v-system-bar {
  background-color: #E5E2E2;
  min-height: 30px;
  font-size: 15px;
  font-weight: 900;
  margin-bottom: 30px;
  text-align: center;
}
.btn-auth-black {
  color: #ffffff;
  border: 0.5px solid;
}
.btn-auth-white {
  background-color: #ffffff;
  border: 0.5px solid #dadce0;
}
@media (max-width: 500px) {
  .btn-auth-black {
    border: 1px solid;
  }
  .btn-auth-white {
    border: 1px solid #dadce0 !important;
  }
}
.input-title {
  color: black;
  font-weight: 500;
  margin-bottom: 2px;
}
</style>

<style>
.Login .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot{
  min-height: 30px;
  width: 280px;
  margin-bottom: 0px;
}
.Login .v-input input {
  max-height: 30px;
}
.Login .v-label {
  font-size: 14px;
}
.Login .v-text-field input {
    padding-top: 0px;
    padding-bottom: 0px;
}
</style>
