<template>
  <div id="Unity">
    <iframe
      id="UnityFrame"
      :src="unitySrc"
      :width="width"
      :height="height"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      ref="makeInstance"
    ></iframe>
    <div id="UnityData" :loginUser="loginUser" modeName="make" :jwt="this.$store.state.auth.login.headers.Authorization"></div>
  </div>
</template>

<script>
export default {
  components: {
    // Unity
  },
  data () {
    return {
      height: document.documentElement.clientHeight - 60,
      width: document.documentElement.clientWidth,
      unitySrc: '/Unity/index.html'
    }
  },
  beforeMount () {
    this.windowSize()
  },
  mounted () {
    this.windowSize()
    window.addEventListener('resize', this.windowSize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.windowSize)
  },
  computed: {
    loginUser: function () {
      if (this.$store.state.auth.login.token) {
        return this.$store.state.auth.login.user_id
      } else {
        return null
      }
    }
  },
  methods: {
    windowSize: function () {
      this.height = document.documentElement.clientHeight - 60
      this.width = document.documentElement.clientWidth
    }
  }
}
</script>

<style>
#make-page-container {
  padding: 0 !important;
  margin: 0 !important;
  max-width: 100%;
  max-height: 100%;
}
#UnityFrame {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  /* padding:0 !important;
    margin: 0 !important;
    max-width: 100%;
    max-height: 100%;
    width:  100%;
    height:  100%; */
}
</style>
<style scoped>
.container {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
