<template>
  <div style="overflow-x: hidden">
    <!-- <DescriptionIconButton v-if="miniWindow == true" /> -->
    <v-container v-scroll="onScroll" fluid id="HomeContainer">
      <!---movie-->
      <v-card
        id="Home_LP"
        style="overflow: hidden"
        absolute
        v-resize="windowSize"
        :width="width"
        :height="height"
        dark
        flat
        color="#0f0f0f"
        tile
      >
        <!-- //////////////////////////////////Landing//////////////////////////////////////// -->
        <v-row
          class="landing"
          style="position: absolute; z-index: 3; width: 100%; height: 100%"
          :align="landingAlign"
        >
          <v-col xl="8" lg="10" cols="12" style="padding: 0">
            <DiscriptionCard
              :title="landingTitle"
              :text="landing_text"
              :dark="true"
              :landingTitle="true"
              :serif="true"
            />
            <v-row style="padding-top: 20px">
              <v-col cols="12" sm="6" md="4" style="padding: 5px 12px">
                <Button
                  class="landingButton"
                  title="家をさがす"
                  :func="searchButton"
                  :size="50"
                  :mdi="svgPath_Magnify"
                  color="#000000"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" style="padding: 5px 12px">
                <Button
                  class="landingButton"
                  title="家をつくる"
                  :func="makeButton"
                  :size="50"
                  :mdi="svgPath_Wrench"
                  color="#000000"
                  caution="NEW!! ※要ログイン・PC"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- //////////////////////////////////Video//////////////////////////////////////// -->
        <video
          style="
            position: absolute;
            z-index: 1;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
          "
          :height="videoHight"
          playsinline
          autoplay
          muted
          loop
          preload="none"
        >
          <source :src="currMPFourURL" type="video/mp4" />
        </video>
        <!-- //////////////////////////////////Image//////////////////////////////////////// -->
        <v-img
          style="position: absolute; z-index: 0"
          :width="width"
          :height="height"
          src="../assets/200706_homeLoading.png"
        >
        </v-img>
        <!-- //////////////////////////////////Scroll Arrow//////////////////////////////////////// -->
        <v-row align="end" justify="center" class="scDown">
          <a href="#">
            <span></span>
          </a>
          <b href="#">
            <span></span>
          </b>
          <v-card-subtitle
            style="z-index: 100"
            class="
              white--text
              font-weight-black
              text-center
              padding-top:-500px;
            "
            >SCROLL</v-card-subtitle
          >
        </v-row>
      </v-card>
      <!-- /////////////////////////////////////Vision///////////////////////////////////////// -->
      <!-- <v-lazy
        v-model="isVision"
        :height="homeRowHeight"
        :options="{
        threshold: .6
      }"
        transition="slide-x-transition"
      >
        <v-card
          style="overflow: hidden;"
          v-resize="windowSize"
          :width="homeRowWidth"
          :height="homeRowHeight"
          flat
          tile
        >
          <v-img style="position: absolute; z-index:0;" :width="width" :height="height" src="../assets/NEMESTUDIO-Museum-of-Lost-Volumes-4.jpg"/>
          <v-row class="homeRow" style="background-color:transeparent" :align="homeRowAlign" no-gutters>
            <v-col v-if="xlWindow" cols="1" />
            <v-col xl="5" md="6" cols="12">
              <DiscriptionCard
                :title="vision_title"
                :subtitle="vision_subtitle"
                :text="vision_text"
                color="#ffffffdd"
                addClass="px-4 py-12 rounded-lg"
                :landingTitle="true"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-lazy> -->

      <!-- /////////////////////////////////////SearchPage///////////////////////////////////////// -->
      <v-lazy
        id="Home_SearchPage"
        v-model="isSearch"
        :height="homeRowHeight"
        :options="{
          threshold: 0.8,
        }"
        transition="scroll-x-transition"
      >
        <v-card
          style="overflow: hidden"
          v-resize="windowSize"
          :width="homeRowWidth"
          :height="homeRowHeight"
          flat
          tile
        >
          <v-row class="homeRow" :align="homeRowAlign" no-gutters>
            <v-col v-if="!bigWindow" cols="12">
              <!-- <v-card class="HowToUse_Chips rounded-lg" style="margin:0 auto;" flat tile color="#F2F2F2" max-width="750"> -->
              <v-card
                class="HowToUse_Chips rounded-lg"
                flat
                tile
                color="#F2F2F2"
              >
                <video
                  style="width: 100%"
                  playsinline
                  autoplay
                  muted
                  loop
                  preload="auto"
                >
                  <source
                    src="../assets/video/LP_検索ページ.mp4"
                    type="video/mp4"
                  />
                </video>
              </v-card>
            </v-col>
            <v-col v-if="xlWindow || !bigWindow" cols="1" />
            <v-col xl="5" lg="6" sm="11" cols="12">
              <DiscriptionCard
                subtitle="service1"
                pointColor="#00EEFF"
                :title="searchpage_title"
                :text="searchpage_text"
              />
              <Button
                class="experienceButton"
                title="家をさがす"
                :func="searchButton"
                :size="experienceButtonSize"
                :mdi="svgPath_Magnify"
              />
            </v-col>
            <v-col v-if="bigWindow" cols="6">
              <v-card
                class="HowToUse_Chips rounded-lg"
                flat
                tile
                color="#F2F2F2"
              >
                <video
                  style="width: 100%"
                  playsinline
                  autoplay
                  muted
                  loop
                  preload="auto"
                >
                  <source
                    src="../assets/video/LP_検索ページ.mp4"
                    type="video/mp4"
                  />
                </video>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-lazy>
      <!-- /////////////////////////////////////MakePage///////////////////////////////////////// -->
      <v-lazy
        id="Home_MakePage"
        v-model="isMake"
        :height="homeRowHeight"
        :options="{
          threshold: 0.8,
        }"
        transition="slide-x-reverse-transition"
      >
        <v-card
          style="overflow: hidden"
          v-resize="windowSize"
          :width="homeRowWidth"
          :height="homeRowHeight"
          flat
          tile
        >
          <v-row class="homeRow" :align="homeRowAlign" no-gutters>
            <v-col lg="6" cols="12">
              <!-- <v-card class="HowToUse_Chips rounded-lg" style="margin:0 auto;" flat tile color="#F2F2F2" max-width="750"> -->
              <v-card
                class="HowToUse_Chips rounded-lg"
                flat
                tile
                color="#F2F2F2"
              >
                <video
                  style="width: 100%"
                  playsinline
                  autoplay
                  muted
                  loop
                  preload="auto"
                >
                  <source
                    src="../assets/video/LP_メイクモード.mp4"
                    type="video/mp4"
                  />
                </video>
              </v-card>
            </v-col>
            <!-- <v-col v-if="xlWindow || !bigWindow" cols="1" /> -->
            <!-- <v-col xl="5" lg="6" sm = "11" cols="12"> -->
            <v-col v-if="miniWindow" cols="1" />
            <v-col xl="5" lg="5" md="11" sm="" cols="12">
              <DiscriptionCard
                subtitle="service2"
                pointColor="#00EEFF"
                :title="makepage_title"
                :text="makepage_text"
              />
              <Button
                class="experienceButton"
                title="家をつくる"
                :func="makeButton"
                :size="experienceButtonSize"
                :mdi="svgPath_Wrench"
                caution="NEW!! ※要ログイン"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-lazy>
      <!-- /////////////////////////////////////VRPage///////////////////////////////////////// -->
      <v-lazy
        id="Home_VRPage"
        v-model="isVR"
        :height="homeRowHeight"
        :options="{
          threshold: 0.8,
        }"
        transition="scroll-x-transition"
      >
        <v-card
          style="overflow: hidden"
          v-resize="windowSize"
          :width="homeRowWidth"
          :height="homeRowHeight"
          flat
          tile
        >
          <v-row class="homeRow" :align="homeRowAlign" no-gutters>
            <v-col v-if="!bigWindow" cols="12">
              <!-- <v-card class="HowToUse_Chips rounded-lg" style="margin:0 auto;" flat tile color="#F2F2F2" max-width="750"> -->
              <v-card
                class="HowToUse_Chips rounded-lg"
                flat
                tile
                color="#F2F2F2"
              >
                <video
                  style="width: 100%"
                  playsinline
                  autoplay
                  muted
                  loop
                  preload="auto"
                >
                  <source
                    src="../assets/video/LP_内覧モード.mp4"
                    type="video/mp4"
                  />
                </video>
              </v-card>
            </v-col>
            <v-col v-if="xlWindow || !bigWindow" cols="1" />
            <v-col xl="5" lg="6" sm="11" cols="12">
              <DiscriptionCard
                subtitle="service3"
                pointColor="#00EEFF"
                :title="VRpage_title"
                :text="VRpage_text"
              />
              <Button
                class="experienceButton"
                title="家をさがす"
                :func="searchButton"
                :size="experienceButtonSize"
                :mdi="svgPath_Magnify"
                caution="※検索からご利用下さい"
              />
            </v-col>
            <v-col v-if="bigWindow" cols="6">
              <v-card
                class="HowToUse_Chips rounded-lg"
                flat
                tile
                color="#F2F2F2"
              >
                <video
                  style="width: 100%"
                  playsinline
                  autoplay
                  muted
                  loop
                  preload="auto"
                >
                  <source
                    src="../assets/video/LP_内覧モード.mp4"
                    type="video/mp4"
                  />
                </video>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-lazy>
      <!-- /////////////////////////////////////DetailPage///////////////////////////////////////// -->
      <v-lazy
        id="Home_DetailPage"
        v-model="isDetail"
        :height="homeRowHeight"
        :options="{
          threshold: 0.8,
        }"
        transition="slide-x-reverse-transition"
      >
        <v-card
          style="overflow: hidden"
          v-resize="windowSize"
          :width="homeRowWidth"
          :height="homeRowHeight"
          flat
          tile
        >
          <v-row class="homeRow" :align="homeRowAlign" no-gutters>
            <v-col lg="6" cols="12">
              <!-- <v-card class="HowToUse_Chips rounded-lg" style="margin:0 auto;" flat tile color="#F2F2F2" max-width="750"> -->
              <v-card
                class="HowToUse_Chips rounded-lg"
                flat
                tile
                color="#F2F2F2"
              >
                <video
                  style="width: 100%"
                  playsinline
                  autoplay
                  muted
                  loop
                  preload="auto"
                >
                  <source
                    src="../assets/video/LP_詳細ページ.mp4"
                    type="video/mp4"
                  />
                </video>
              </v-card>
            </v-col>
            <!-- <v-col v-if="xlWindow || !bigWindow" cols="1" /> -->
            <!-- <v-col xl="5" lg="6" sm = "11" cols="12"> -->
            <v-col v-if="miniWindow" cols="1" />
            <v-col xl="5" lg="5" md="11" sm="" cols="12">
              <DiscriptionCard
                subtitle="service4"
                pointColor="#00EEFF"
                :title="detailpage_title"
                :text="detailpage_text"
              />
              <Button
                class="experienceButton"
                title="家をさがす"
                :func="searchButton"
                :size="experienceButtonSize"
                :mdi="svgPath_Magnify"
                caution="※検索からご利用下さい"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-lazy>
      <!-- /////////////////////////////////////Value///////////////////////////////////////// -->
      <v-lazy
        id="Home_ValuePage"
        v-model="isValue"
        :height="homeRowHeight"
        :options="{
          threshold: 0.8,
        }"
        transition="scroll-x-transition"
      >
        <v-card
          style="overflow: hidden"
          v-resize="windowSize"
          :width="homeRowWidth"
          :height="homeRowHeight"
          flat
          tile
        >
          <v-row class="homeRow valueRow" :align="homeRowAlign" no-gutters>
            <!-- <v-col v-if="!bigWindow" cols="12" >
              <v-card class="HowToUse_Chips rounded-lg" flat tile color="#F2F2F2">
                <v-img :aspect-ratio="600/140" src="../assets/Value1.png"></v-img>
                <v-img :aspect-ratio="600/400" src="../assets/Value2.png"></v-img>
              </v-card>
            </v-col> -->
            <v-col v-if="xlWindow || !bigWindow" cols="1" />
            <v-col xl="5" lg="6" sm="11" cols="12">
              <DiscriptionCard
                :subtitle="valuepage_subtitle"
                pointColor="#FF00EE"
                :title="valuepage_title"
                :text="valuepage_text"
                :value="true"
              />
              <!-- <Button
                class="experienceButton"
                title = "家をさがす"
                :func = searchButton
                :size = experienceButtonSize
                :mdi = svgPath_Magnify
              /> -->
            </v-col>
            <v-col v-if="miniWindow" style="margin: 40px 0" lg="6" cols="12">
              <v-card class="valueDiagram rounded-lg" flat tile color="#f2f2f2">
                <v-img
                  :aspect-ratio="600 / 140"
                  src="../assets/Value1.png"
                ></v-img>
              </v-card>
              <v-icon
                style="margin: 0 0; width: 100%"
                :size="50"
                color="#191919"
                >{{ svgPath_ArrowDownBold }}</v-icon
              >
              <v-card class="valueDiagram rounded-lg" flat tile color="#f2f2f2">
                <v-img
                  :aspect-ratio="600 / 400"
                  src="../assets/Value2.png"
                ></v-img>
              </v-card>
            </v-col>
            <v-col v-if="!miniWindow" style="margin: 40px 0" lg="6" cols="12">
              <v-card class="valueDiagram rounded-lg" flat tile color="#f2f2f2">
                <v-img
                  :aspect-ratio="300 / 140"
                  src="../assets/Value1_s.png"
                ></v-img>
              </v-card>
              <v-icon
                style="margin: 0 0; width: 100%"
                :size="50"
                color="#191919"
                >{{ svgPath_ArrowDownBold }}</v-icon
              >
              <v-card class="valueDiagram rounded-lg" flat tile color="#f2f2f2">
                <v-img
                  :aspect-ratio="300 / 370"
                  src="../assets/Value2_s.png"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-lazy>
      <!-- /////////////////////////////////////Build///////////////////////////////////////// -->
      <v-lazy
        id="Home_BuildPage"
        v-model="isBuild"
        :height="homeRowHeight - 50"
        :options="{
          threshold: 0.8,
        }"
        transition="scroll-x-reverse-transition"
      >
        <v-card
          style="overflow: hidden"
          v-resize="windowSize"
          :width="homeRowWidth"
          :height="homeRowHeight - 50"
          flat
          tile
        >
          <v-row class="homeRow" :align="homeRowAlign" no-gutters>
            <v-col v-if="xlWindow || !bigWindow" cols="1" />
            <v-col xl="5" lg="6" sm="11" cols="12">
              <DiscriptionCard
                :subtitle="buildpage_subtitle"
                pointColor="#37ff37"
                :title="buildpage_title"
                :text="buildpage_text"
              />
              <Button
                class="experienceButton"
                title="お問い合わせ"
                :func="inquilyButton"
                :size="experienceButtonSize"
                :mdi="svgPath_Email"
              />
            </v-col>
            <v-col v-if="!bigWindow" cols="1" />
            <v-col style="margin: 40px 0" lg="6" sm="10" cols="12">
              <v-data-table
                class="baseTable"
                :headers="baseheaders"
                :items="basedatas"
                :items-per-page="-1"
                hide-default-footer
                disable-sort
                mobile-breakpoint="0"
                color="black"
              >
                <template v-slot:[`item.icon`]="{ item }" class="pa-0">
                  <v-img
                    :aspect-ratio="425 / 330"
                    class="typeIcon"
                    v-if="item.icon == 'site'"
                    :width="associateIconSize"
                    src="@/assets/avhsLOGO.png"
                  />
                </template>
                <template v-slot:[`item.subject`]="{ item }">
                  <a target="_blank" :href="item.website">
                    {{ item.subject }}
                  </a>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-lazy>
      <!-- /////////////////////////////////////SearchOrMake///////////////////////////////////////// -->
      <!-- <v-lazy
        v-model="isMake"
        :options="{
        threshold: .4
      }"
        transition="slide-x-transition"
      >
        <v-card
          style="overflow: hidden;"
          v-resize="windowSize"
          :width="homeRowWidth"
          :height="homeRowHeight"
          flat
          tile
        >
          <v-row class="homeRow" style="background-color: #ffffff !important;" :align="homeRowAlign" no-gutters>
            <v-col cols="1">
            </v-col>
            <v-col xl="5" cols="11">
              <DiscriptionCard
                title="まず、家をさがす"
              />
              <Button
                class="experienceButton"
                title = "家をさがす"
                :func = searchButton
                :size = experienceButtonSize
                :mdi = svgPath_Magnify
                caution = "|"
              />
            </v-col>
            <v-col cols="1">
            </v-col>
            <v-col xl="5" cols="11">
              <DiscriptionCard
                title="まず、家をつくる"
              />
              <Button
                class="experienceButton"
                title = "家をつくる"
                :func = makeButton
                :size = experienceButtonSize
                :mdi = svgPath_Wrench
                caution = "※要ログイン"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-lazy> -->
    </v-container>
  </div>
</template>

<script>
import { mdiMagnify, mdiWrench, mdiArrowDownBold, mdiEmail } from '@mdi/js'
import Button from '../components/Button/Button'
import DiscriptionCard from '../components/Card/DiscriptionCard'
export default {
  components: {
    Button,
    DiscriptionCard
  },
  data () {
    return {
      svgPath_Magnify: mdiMagnify, // Material Design Icon
      svgPath_Wrench: mdiWrench, // Material Design Icon
      svgPath_ArrowDownBold: mdiArrowDownBold, // Material Design Icon
      svgPath_Email: mdiEmail, // Material Design Icon
      pastPos: 0,
      isScroll: false,
      isGoUp: false,
      scrollTarget: 0,
      isVision: false,
      isSearch: false,
      isMake: false,
      isVR: false,
      isDetail: false,
      isValue: false,
      isBuild: false,
      height: 0,
      width: 0,
      xxlWindow: true,
      xlWindow: true,
      bigWindow: true,
      smallWindow: true,
      miniWindow: true,
      currBackground: 0,
      currMPFour: 0,
      test: 'https://media.giphy.com/media/Fd0rrjTJ3yLDi/giphy.gif',
      backgrounds: [
        {
          name: 'houseIcon',
          url: require('../assets/200706_homeLoading_grey4.png')
        },
        {
          name: 'movie',
          url: 'https://media.giphy.com/media/Fd0rrjTJ3yLDi/giphy.gif'
        }
      ],
      mpFours: [
        {
          name: 'normal',
          // url: require('../assets/From The Air - 9798.mp4')
          url: require('../assets/p2108_archiroid1-0_LP.mp4')
        },
        {
          name: 'light',
          // url: require('../assets/From The Air - 9798.mp4')
          url: require('../assets/p2108_archiroid1-0_LP.mp4')
        }
      ],
      vision_title: 'archiroid.comへようこそ',
      vision_subtitle: 'archiroid.com',
      vision_text: '新築なのに、物件情報サイトのように検索・比較できます。<br/>気になる家はブックマーク。だれかに伝えるイメージや、アイデアの参考に。<br/>人も時間も気にしない。あなたのペースで住まいを知り、考えられます。<br/>nLDKと表現される定番の家から、珍しい特別な部屋がある多様な家まで。<br/>これまで発見されてなかった、新しい家の可能性に出会えるかもしれません。<br/><br/>新築なのに、物件情報サイトのように検索・比較できます。<br/>気になる家はブックマーク。だれかに伝えるイメージや、アイデアの参考に。<br/>人も時間も気にしない。あなたのペースで住まいを知り、考えられます。<br/>nLDKと表現される定番の家から、珍しい特別な部屋がある多様な家まで。<br/>これまで発見されてなかった、新しい家の可能性に出会えるかもしれません。<br/><br/>新築なのに、物件情報サイトのように検索・比較できます。<br/>気になる家はブックマーク。だれかに伝えるイメージや、アイデアの参考に。<br/>人も時間も気にしない。あなたのペースで住まいを知り、考えられます。<br/>nLDKと表現される定番の家から、珍しい特別な部屋がある多様な家まで。<br/>これまで発見されてなかった、新しい家の可能性に出会えるかもしれません。',
      vision_dialog: false,
      searchpage_title: '家を検索・比較する',
      searchpage_subtitle: 'searchPage',
      searchpage_text: '未住宅なのに、物件情報サイトのように検索・比較できます。<br/>気になる家はブックマーク。誰かに伝えるイメージやアイデアの参考に。<br/>人も時間も気にしない。あなたのペースで住まいを考えられます。<br/>nLDKと表現される定番の家から、＋αの特別な空間がある家まで。<br/>これまで発見されてなかった、新しい家の可能性に出会えるかもしれません。',
      makepage_title: '家を設計・変更する',
      makepage_subtitle: 'searchPage',
      makepage_text: '新しい家をデザインしたり、誰かの家をリノベーションすることもできます。<br/>土地と建築を選んで、空間や開口部に家具レイアウトも試せます。<br/>経験がなくても大丈夫。構造、法規、価格も、アーキロイドが即座に検査。<br/>投稿すると検索ページに発表、VRや図面を閲覧できるようになります。<br/>あなたの作品が、誰かの家になるかもしれません。',
      makepage_dialog: false,
      VRpage_title: '家をVRで内覧する',
      VRpage_subtitle: 'searchPage',
      VRpage_text: 'すべての家を、VRでウォークスルーできます。<br/>壁の中を覗いたり、手軽な家具シミュレーションは、デジタル空間ならでは。<br/>いくつ試しても、内覧に足を運ぶ負担や、モデルハウス建設の環境負荷もありません。<br/>不思議なもので、家は部屋の数が同じでも、”かたち”が違うと使い方もそれぞれ。<br/>本当に建つそのままの”かたち”、あなただけの使い方を発見できるかもしれません。',
      VRpage_dialog: false,
      detailpage_title: '家のデータを活用する',
      detailpage_subtitle: 'searchPage',
      detailpage_text: '3Dモデル、図面、CGパース、プレカット加工データ...<br/>数日かかるものが数分で。ここにある全ての家が豊富なデータを持っています。<br/>3Dプリンタで模型を作ったり、自作のゲームの中に埋め込んだり。使い方は様々。<br/>今後データのダウンロード販売やグッズ展開を予定しています。<br/>ご興味のある方、いち早く欲しい方、ご連絡ください。<br/><br/>もちろん建てることも。（「アーキロイドと家をつくる」の項を御覧ください）',
      detailpage_dialog: false,
      valuepage_title: 'いくつもの平行世界をめぐり、<br/>より良いデザインと出会う',
      valuepage_subtitle: 'value',
      valuepage_text: '家の設計は考えることがたくさん。<br/>プロにだって大変だから、何度も試すのは難しかった。<br/>でもこれからはたくさんのことをアーキロイドが設計します。<br/>理想の暮らしを実現する家を、どこまでも、納得が行くまで。<br/><br/>これまで出会うことがなかった平行世界のデザインたち。<br/>時間のゆるす限り、家をめぐる冒険に没頭してください。',
      valuepage_dialog: false,
      buildpage_title: 'アーキロイドと<br/>家をつくる',
      buildpage_subtitle: 'contact',
      buildpage_text: '「archiroid.comで家を建てたい」　そう思った方、ご連絡ください。<br/>好きなだけお試しいただけるように、あなたの土地をこの中に再現します。<br/>わたし達は、最初のお客様をお待ちしております。<br/>提携設計事務所協力の下、建築まで実現します。',
      buildpage_dialog: false,
      baseheaders: [
        { text: '提携事務所', align: 'start', value: 'icon', width: '110px', class: 'px-0' },
        { text: 'ホームページ', value: 'subject', width: '', class: 'px-0' }
      ],
      basedatas: []
    }
  },
  mounted () {
    this.windowSize()
    this.$vuetify.goTo(0)
    this.setNowPos()
    this.makeBaseData()
    window.setTimeout(this.nextImage(), 2000)
    window.addEventListener('scroll', this.scrollEventHandler, { passive: false })
  },
  beforeRouteUpdate (to, from, next) {
    window.removeEventListener('scroll', this.scrollEventHandler, { passive: false })
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollEventHandler, { passive: false })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollEventHandler, { passive: false })
  },
  methods: {
    windowSize () {
      this.height = window.innerHeight
      this.width = window.innerWidth
      if (this.width >= 1650) {
        this.xxlWindow = true
      } else {
        this.xxlWindow = false
      }
      if (this.width >= 1560) {
        this.xlWindow = true
      } else {
        this.xlWindow = false
      }
      if (this.width >= 1264) {
        this.bigWindow = true
      } else {
        this.bigWindow = false
      }
      if (this.width >= 1030) {
        this.smallWindow = true
      } else {
        this.smallWindow = false
      }
      if (this.width >= 670) {
        this.miniWindow = true
      } else {
        this.miniWindow = false
      }
      if (this.width >= 500) {
        this.currMPFour = 0
      } else {
        this.currMPFour = 1
      }
    },
    changeTech_Base (n) {
      this.tech_base = n
    },
    nextImage () {
      this.currBackground += 1
      if (this.currBackground > 1) {
        this.currBackground = 0
      }
    },
    searchButton: function (event) {
      this.$router.push({ name: 'listPage' }, () => { }, () => { })
    },
    inquilyButton: function (event) {
      this.$router.push({ name: 'inquiry' }, () => { }, () => { })
    },
    makeButton: function (event) {
      if (this.checkLoggedin()) {
        this.sBtnNum = 2
        this.$router.push({ name: 'makePage' }, () => { }, () => { })
      } else {
        this.showSwal('ログインしてません', 1200)
      }
    },
    makeBaseData () {
      this.basedatas = [
        {
          icon: 'site',
          subject: 'アーキヴィジョン広谷スタジオ',
          website: 'http://archivision-hs.co.jp/'
        }
      ]
    },
    setNowPos () {
      if (typeof window === 'undefined') return
      this.pastPos = window.pageYOffset || 0
    },
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      if (!this.isScroll && this.pastPos < top) {
        this.isGoUp = false
        // console.log('DOWN')
      } else if (!this.isScroll && this.pastPos > top) {
        this.isGoUp = true
        // console.log('UP')
      }
      // const options = { offset: -document.getElementById('Header').offsetHeight }
      const options = { offset: 0 }
      // this.$vuetify.goTo(969, options)
      this.pastPos = top
      if (!this.isGoUp && top >= this.scrollTarget) {
        this.isScroll = false
        // this.returnScroll()
      } else if (this.isGoUp && top <= this.scrollTarget) {
        this.isScroll = false
        // this.returnScroll()
      }
      if (this.isScroll) return
      if (!this.bigWindow) return
      // console.log(top + this.height)
      // console.log(document.getElementById('SearchPage').getBoundingClientRect().height)
      // console.log(document.getElementById('Home_Make').offsetTop)
      // console.log(this.height)
      // console.log(this.scrollTarget)
      // スクロール中はスクロールさせない
      if (!this.isGoUp) {
        // console.log('Down')
        // console.log(this.isScroll)
        // console.log(document.getElementById('Home_BuildPage').offsetTop - (top + (window.innerHeight / 2)))
        // console.log(window.innerHeight / 2)
        if (top < document.getElementById('Home_BuildPage').offsetTop && document.getElementById('Home_BuildPage').offsetTop - (top + (window.innerHeight / 2)) < window.innerHeight / 2) {
          this.isGoUp = false
          this.isScroll = true
          // this.noScroll()
          this.scrollTarget = document.getElementById('Home_BuildPage').offsetTop
          this.$vuetify.goTo(document.getElementById('Home_BuildPage').offsetTop, options)
        } else if (top < document.getElementById('Home_ValuePage').offsetTop && document.getElementById('Home_ValuePage').offsetTop - (top + (window.innerHeight / 2)) < window.innerHeight / 2) {
          this.isGoUp = false
          this.isScroll = true
          // this.noScroll()
          this.scrollTarget = document.getElementById('Home_ValuePage').offsetTop
          this.$vuetify.goTo(document.getElementById('Home_ValuePage').offsetTop, options)
        } else if (top < document.getElementById('Home_DetailPage').offsetTop && document.getElementById('Home_DetailPage').offsetTop - (top + (window.innerHeight / 2)) < window.innerHeight / 2) {
          this.isGoUp = false
          this.isScroll = true
          // this.noScroll()
          this.scrollTarget = document.getElementById('Home_DetailPage').offsetTop
          this.$vuetify.goTo(document.getElementById('Home_DetailPage').offsetTop, options)
        } else if (top < document.getElementById('Home_VRPage').offsetTop && document.getElementById('Home_VRPage').offsetTop - (top + (window.innerHeight / 2)) < window.innerHeight / 2) {
          this.isGoUp = false
          this.isScroll = true
          // this.noScroll()
          this.scrollTarget = document.getElementById('Home_VRPage').offsetTop
          this.$vuetify.goTo(document.getElementById('Home_VRPage').offsetTop, options)
        } else if (top < document.getElementById('Home_MakePage').offsetTop && document.getElementById('Home_MakePage').offsetTop - (top + (window.innerHeight / 2)) < window.innerHeight / 2) {
          this.isGoUp = false
          this.isScroll = true
          // this.noScroll()
          this.scrollTarget = document.getElementById('Home_MakePage').offsetTop
          this.$vuetify.goTo(document.getElementById('Home_MakePage').offsetTop, options)
        } else if (top < document.getElementById('Home_SearchPage').offsetTop && document.getElementById('Home_SearchPage').offsetTop - (top + (window.innerHeight / 2)) < window.innerHeight / 2) {
          this.isGoUp = false
          this.isScroll = true
          // this.noScroll()
          this.scrollTarget = document.getElementById('Home_SearchPage').offsetTop
          this.$vuetify.goTo(document.getElementById('Home_SearchPage').offsetTop, options)
        }
      } else {
        // console.log('Up')
        if (top > document.getElementById('Home_LP').offsetTop && (top + (window.innerHeight / 2)) - (document.getElementById('Home_LP').offsetTop + document.getElementById('Home_LP').getBoundingClientRect().height) < window.innerHeight / 2) {
          this.isGoUp = true
          this.isScroll = true
          // this.noScroll()
          this.scrollTarget = document.getElementById('Home_LP').offsetTop
          this.$vuetify.goTo(document.getElementById('Home_LP').offsetTop, options)
        } else if (top > document.getElementById('Home_SearchPage').offsetTop && (top + (window.innerHeight / 2)) - (document.getElementById('Home_SearchPage').offsetTop + document.getElementById('Home_SearchPage').getBoundingClientRect().height) < window.innerHeight / 2) {
          this.isGoUp = true
          this.isScroll = true
          // this.noScroll()
          this.scrollTarget = document.getElementById('Home_SearchPage').offsetTop
          this.$vuetify.goTo(document.getElementById('Home_SearchPage').offsetTop, options)
        } else if (top > document.getElementById('Home_MakePage').offsetTop && (top + (window.innerHeight / 2)) - (document.getElementById('Home_MakePage').offsetTop + document.getElementById('Home_MakePage').getBoundingClientRect().height) < window.innerHeight / 2) {
          this.isGoUp = true
          this.isScroll = true
          // this.noScroll()
          this.scrollTarget = document.getElementById('Home_MakePage').offsetTop
          this.$vuetify.goTo(document.getElementById('Home_MakePage').offsetTop, options)
        } else if (top > document.getElementById('Home_VRPage').offsetTop && (top + (window.innerHeight / 2)) - (document.getElementById('Home_VRPage').offsetTop + document.getElementById('Home_VRPage').getBoundingClientRect().height) < window.innerHeight / 2) {
          this.isGoUp = true
          this.isScroll = true
          // this.noScroll()
          this.scrollTarget = document.getElementById('Home_VRPage').offsetTop
          this.$vuetify.goTo(document.getElementById('Home_VRPage').offsetTop, options)
        } else if (top > document.getElementById('Home_DetailPage').offsetTop && (top + (window.innerHeight / 2)) - (document.getElementById('Home_DetailPage').offsetTop + document.getElementById('Home_DetailPage').getBoundingClientRect().height) < window.innerHeight / 2) {
          this.isGoUp = true
          this.isScroll = true
          // this.noScroll()
          this.scrollTarget = document.getElementById('Home_DetailPage').offsetTop
          this.$vuetify.goTo(document.getElementById('Home_DetailPage').offsetTop, options)
        } else if (top > document.getElementById('Home_ValuePage').offsetTop && (top + (window.innerHeight / 2)) - (document.getElementById('Home_ValuePage').offsetTop + document.getElementById('Home_ValuePage').getBoundingClientRect().height) < window.innerHeight / 2) {
          this.isGoUp = true
          this.isScroll = true
          // this.noScroll()
          this.scrollTarget = document.getElementById('Home_ValuePage').offsetTop
          this.$vuetify.goTo(document.getElementById('Home_ValuePage').offsetTop, options)
        }
      }
    },
    scrollEventHandler (e) {
      if (this.isScroll) {
        e.preventDefault()
      }
    },
    scrollEventHandle (e) {
      e.preventDefault()
    },
    noScroll () {
      // sp
      document.addEventListener('touchmove', this.scrollEventHandle, { passive: false })
      // sp
      document.addEventListener('mousewheel', this.scrollEventHandle, { passive: false })
    },
    returnScroll () {
      // sp
      document.removeEventListener('touchmove', this.scrollEventHandle, { passive: false })
      // sp
      document.removeEventListener('mousewheel', this.scrollEventHandle, { passive: false })
    }
  },
  computed: {
    beforeTech: function () {
      if (this.tech_base === 0) {
        return this.technologies.length - 1
      } else {
        return this.tech_base - 1
      }
    },
    afterTech: function () {
      if (this.tech_base === this.technologies.length - 1) {
        return 0
      } else {
        return this.tech_base + 1
      }
    },
    afterNextTech: function () {
      if (this.tech_base === this.technologies.length - 2) {
        return 0
      } else if (this.tech_base === this.technologies.length - 1) {
        return 1
      } else {
        return this.tech_base + 2
      }
    },
    // src: function () {
    //   var timestamp = new Date().getTime()
    //   return require('../assets/projects.gif') + '?' + timestamp
    // },
    currBackgroundURL: function () {
      return this.backgrounds[this.currBackground].url
    },
    currMPFourURL: function () {
      return this.mpFours[this.currMPFour].url
    },
    videoHight: function () {
      const videoAspect = 1080 / 1920
      const windowAspect = this.height / this.width
      if (this.currMPFour === 0) {
        // パソコン用 横合わせ
        if (videoAspect > windowAspect) {
          return videoAspect * this.width
        } else {
          return this.height
        }
      } else {
        // スマホ用 高さ合わせ
        if (videoAspect < (1 / windowAspect)) {
          return (1 / videoAspect) * this.width
        } else {
          return this.height
        }
      }
    },
    homeRowAlign: function () {
      if (this.bigWindow) {
        return 'center'
      } else {
        return 'start'
      }
    },
    homeRowWidth: function () {
      if (this.bigWindow) {
        return this.width
      } else {
        return null
      }
    },
    homeRowHeight: function () {
      if (this.bigWindow) {
        return this.height
      } else {
        return null
      }
    },
    experienceButtonSize: function () {
      if (this.bigWindow) {
        return 60
      } else {
        return 50
      }
    },
    associateIconSize: function () {
      if (this.miniWindow) {
        return 80
      } else {
        return 60
      }
    },
    landingAlign: function () {
      if (this.miniWindow) {
        return 'center'
      } else {
        return 'center'
      }
    },
    landingTitle: function () {
      if (this.miniWindow) {
        return 'アーキロイドと創って試そう、壊して学ぼう<br>日本(未)住宅図鑑'
      } else {
        return 'アーキロイドと<br>創って試そう、壊して学ぼう<br>日本(未)住宅図鑑'
      }
    },
    landing_text: function () {
      if (this.miniWindow) {
        return 'archiroid.comへようこそ。<br/>ここは日本中の人が集い、家づくりの楽しみを体験・共有できる仮想世界です。<br/>住宅を設計して発表、作品の検索と比較、VRで内覧して改良、図面・３Dデータの活用やグッズ作成まで。<br/>誰かが作った未だ実現されてない（未）住宅を、誰もが閲覧、編集、活用して楽しむことができます。<br/>経験がなくても大丈夫。アーキロイドがあなたの設計をお手伝いします。<br/><br/>かつてないほどに暮らしが多様になった今だから、住まいにも圧倒的な選択肢を作ろう。<br/>さあ図鑑を手に、想像よりも広大な冒険へ出かけよう。'
      } else {
        return 'archiroid.comへようこそ。<br/>ここは日本中の人が集い、家づくりの楽しみを体験・共有できる仮想世界です。<br/><br/>かつてないほどに暮らしが多様になった今だから、住まいにも圧倒的な選択肢を作ろう。<br/>さあ図鑑を手に、想像よりも広大な冒険へ出かけよう。'
      }
    }
  }
}
</script>

<style scoped>
#HomeContainer >>> .scDown a {
  padding-top: 65px;
}
#HomeContainer >>> .scDown a {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(-14.5px, -50%);
  transform: translate(-14.5px, -50%);
  text-decoration: none;
}
#HomeContainer >>> .scDown a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 29px;
  height: 29px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb 1.5s infinite;
  animation: sdb 1.5s infinite;
  box-sizing: border-box;
}
#HomeContainer >>> .scDown b {
  padding-top: 70px;
}

#HomeContainer >>> .scDown b {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  /* -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%); */
  text-decoration: none;
}
/* .scDown b {
  position: absolute;
  left:50%;
  z-index: 2;
  display: inline-block;
  bottom: 20%;
  transform: translate(0,-50%);
  -webkit-transform: translate(0, -50%);
  text-decoration: none;
} */
#HomeContainer >>> .scDown b span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 0px;
  height: 40px;
  margin-left: -1px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-animation: sdb2 1.5s infinite;
  animation: sdb2 1.5s infinite;
  box-sizing: border-box;
}

/* .scDown .v-card__subtitle {
  top: 100%-1px;
} */

#HomeContainer >>> .scDown .v-card__subtitle {
  padding: 0;
  position: absolute;
  left: 50%;
  /* margin-left: -42px; */
  /* margin-left: -50%; */
  /* 下からの高さ */
  bottom: 130px;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
}
@media (max-width: 500px) {
  #HomeContainer >>> .scDown .v-card__subtitle {
    font-size: 10px;
    bottom: 80px;
  }
  #HomeContainer >>> .scDown b span {
    height: 25px;
    margin-left: -1px;
  }
  #HomeContainer >>> .scDown b {
    bottom: -22px;
  }
  #HomeContainer >>> .scDown a span {
    width: 18.5px;
    height: 18.5px;
  }
  #HomeContainer >>> .scDown a {
    bottom: -19px;
    -webkit-transform: translate(-9.5px, -50%);
    transform: translate(-9.5px, -50%);
  }
}

@-webkit-keyframes sdb {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@-webkit-keyframes sdb2 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb2 {
  0% {
    transform: rotate(0deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(0deg) translate(0px, 28.2px);
    opacity: 0;
  }
}
</style>

<style scoped>
#HomeContainer {
  background-color: #f2f2f2 !important;
}
.landing {
  padding: 0 50px;
  margin: 0 !important;
}
.homeRow {
  padding: 0 50px;
  height: 100%;
  background-color: #f2f2f2 !important;
}
.experienceButton {
  width: 360px;
  padding-top: 35px;
}
@media (max-width: 1264px) {
  .landing {
    padding: 50px 50px;
  }
  .homeRow {
    padding: 80px 50px 20px;
    height: auto;
  }
  .experienceButton {
    padding-top: 20px;
  }
}
@media (max-width: 1030px) {
  .landing {
    padding: 0px 20px 0;
  }
  .homeRow {
    padding: 120px 20px 40px;
    height: auto;
  }
  .experienceButton {
    width: 240px;
  }
}
@media (max-width: 670px) {
  .homeRow {
    padding: 70px 20px 40px;
    height: auto;
  }
  .experienceButton {
    width: 240px;
    padding-left: 10px;
  }
}
.valueRow {
  background-color: #ffffff !important;
}
.valueDiagram {
  padding: 5px 20px;
  margin: 0px 40px;
}
@media (max-width: 1560px) {
  .valueDiagram {
    margin: 0;
  }
}
@media (max-width: 1264px) {
  .valueDiagram {
    margin: 0 40px;
  }
}
@media (max-width: 670px) {
  .valueDiagram {
    margin: 0;
  }
}
.dataTable {
  padding: 0 0 30px;
}
.v-data-table {
  background-color: transparent !important;
}
.v-data-table >>> th {
  border-bottom: 1px solid #191919 !important;
  font-weight: 900 !important;
  font-size: 14px !important;
  color: #191919 !important;
}
.v-data-table >>> td {
  padding: 0 !important;
  /* font-size:14px !important; */
  font-weight: 900;
  border-bottom: 1px solid #191919 !important;
  color: #191919 !important;
}
.v-data-table >>> tr:hover {
  background-color: #ffffff !important;
}
.v-application a {
  color: #191919;
  text-decoration: none;
}
.typeIcon {
  margin: 20px 0px 20px 0px !important;
}
.baseTable {
  padding: 0 40px;
}
@media (max-width: 1560px) {
}
@media (max-width: 1264px) {
  .baseTable {
    padding: 0 0px;
  }
}
@media (max-width: 670px) {
  .v-data-table >>> th {
    font-size: 13px !important;
  }
  .v-data-table >>> td {
    font-size: 16px !important;
  }
}
</style>
