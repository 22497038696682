<template>
<div class="PassReset">
  <v-row justify="center">
    <v-col cols=12 sm=9 lg=7 xl=6 >

      <h4>新規パスワード設定</h4>

      <v-row justify="center">
        <v-card class="elevation-0 auth-card" style="background-color: #F5F5F5;">

          <v-system-bar class="flex font-weight-bolld">
            <v-row justify="center">新しいパスワードを入力</v-row>
          </v-system-bar>

          <div class="layout column align-center">
            <v-form ref="resetForm">
              <v-text-field
                name="password"
                label="新しいパスワード"
                id="password"
                type="password"
                v-model="model.new_password1"
                :rules="passwordRules"
                :outlined=true
                dense
                background-color="white"
                required
              ></v-text-field>
              <v-text-field
                name="passwordCheck"
                label="新しいパスワードの確認"
                id="passwordCheck"
                type="password"
                v-model="model.new_password2"
                :rules="passwordRules"
                :outlined=true
                dense
                background-color="white"
                required
              ></v-text-field>
            </v-form>
            <v-btn
              class="btn-auth btn-auth-black"
              style="margin-top: 10px;"
              rounded
              solo
              depressed
              color="black"
              @click="reset"
              :loading="loading"
              >
              設定
            </v-btn>
          </div>
        </v-card>
      </v-row>
    </v-col>
  </v-row>

</div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
export default {
  data: () => ({
    model: {
      new_password1: '',
      new_password2: '',
      uid: '',
      token: ''
    },
    // credentials: {},
    loading: false,
    valid: true,
    nameRules: [
      v => !!v || 'お名前は必須項目です。'
    ],
    emailRules: [
      v => !!v || 'メールアドレスは必須項目です。',
      v => (v && v.length <= 128) || 'メールアドレスは128文字以内で入力してください。',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式が正しくありません。'
    ],
    passwordRules: [
      v => !!v || 'パスワードは必須項目です。',
      v => (v && v.length >= 8 && v.length <= 20) || '8~20字以内で入力してください。'
    ],
    passwordCheck: false
  }),
  props: {
    uid: String,
    token: String
  },
  created () {
    if (this.checkLoggedin()) {
      this.$router.push({ name: 'account' })
    } else {
      this.model.uid = this.uid
      this.model.token = this.token
      console.log(this.model.uid)
      console.log(this.model.token)
    }
  },
  methods: {
    reset () {
      console.log('reset')
      // -----------------------------------------
      // APIでログイン認証を行う
      if (this.$refs.resetForm.validate()) {
        this.loading = true
        if (this.model.new_password1 !== this.model.new_password2) {
          Swal.fire({
            type: 'warning',
            title: 'Error',
            text: 'パスワードが一致していません。',
            showConfirmButton: false,
            showCloseButton: false,
            timer: 3000
          })
          this.loading = false
        } else {
          axios.post(this.$httpPassResetConfirm, this.model).then(res => {
            this.loading = false
            Swal.fire({
              type: 'warning',
              title: 'パスワードリセット完了',
              text: 'ログインしてください',
              showConfirmButton: false,
              showCloseButton: false,
              timer: 3000
            })
            this.$router.push({ name: 'login' })
          }).catch(e => {
            this.loading = false
            Swal.fire({
              type: 'warning',
              text: 'パスワードリセットに失敗しました。',
              showConfirmButton: false,
              showCloseButton: false,
              timer: 3000
            })
            console.log('Error : ' + e)
          })
        }
      }
    }
  }
}
</script>

<style scoped>
h4 {
  font-size: 21px;
  margin-top: 60px;
  margin-bottom: 60px;
  font-weight: 900;
  text-align: center;
}
h5 {
  font-size: 15px;
  margin-top: 60px;
  margin-bottom: 60px;
  font-weight: 900;
  text-align: center;
}
.v-system-bar {
  background-color: #E5E2E2;
  min-height: 30px;
  font-size: 15px;
  font-weight: 900;
  margin-bottom: 30px;
  text-align: center;
}
.btn-auth-black {
  color: #ffffff;
  border: 0.5px solid;
}
.btn-auth-white {
  background-color: #ffffff;
  border: 0.5px solid #dadce0;
}
@media (max-width: 500px) {
  .btn-auth-black {
    border: 1px solid;
  }
  .btn-auth-white {
    border: 1px solid #dadce0 !important;
  }
}
.input-title {
  color: black;
  font-weight: 500;
  margin-bottom: 2px;
}
</style>

<style>
.PassReset .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot{
  min-height: 30px;
  width: 280px;
  margin-bottom: 0px;
}
.PassReset .v-input input {
  max-height: 30px;
}
.PassReset .v-label {
  font-size: 14px;
}
.PassReset .v-text-field input {
    padding-top: 0px;
    padding-bottom: 0px;
}
</style>
