<template>
<div class="Account">

  <v-row justify="center">
    <v-col cols=12 sm=6 lg=4 xl=3>

      <h4>アカウント</h4>

        <v-card class="elevation-0 newpass-card" style="background-color: #F5F5F5; margin-bottom: 30px;">
          <v-system-bar class="flex font-weight-bolld">
            <v-row justify="center">アカウント</v-row>
          </v-system-bar>
          <v-row>
            <v-col
              class=my-5
              v-for="n in 2"
              :key="n"
              :lg="cols[n - 1]"
              :xl="cols[n - 1]"
              :sm="cols[n - 1]"
            >
              <p class="ma-0 mp-0" v-if="n===1" style="text-align:end;">お名前</p>
              <p class="ma-0 mp-0" v-if="n===2"> {{accountinfo.username}} </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="n in 2"
              :key="n"
              :lg="cols[n - 1]"
              :xl="cols[n - 1]"
              :sm="cols[n - 1]"
            >
              <p class="ma-0 mp-0" v-if="n===1" style="text-align:end;">メールアドレス</p>
              <p class="ma-0 mp-0" v-if="n===2"> {{accountinfo.email}} </p>
            </v-col>
          </v-row>
      </v-card>
      <v-row justify="center">
        <v-btn
          class="btn-auth btn-auth-black"
          style="margin-top: 10px;"
          rounded
          solo
          depressed
          color="black"
          @click="logout"
          >
          ログアウト
        </v-btn>
      </v-row>

      <v-card v-show="passwordChangeFlg" class="elevation-0 newpass-card">
        <v-system-bar class="flex font-weight-bolld">
          <v-row justify="center">新規パスワードを入力</v-row>
        </v-system-bar>
        <v-row justify="center">
          <v-form ref="changeForm">
            <v-text-field
              name="password"
              label="新しいパスワード"
              id="password1"
              type="password"
              dense
              v-model="model.new_password1"
              :counter="20"
              :rules="passwordRules"
              :outlined=true
              background-color="white"
              required
            ></v-text-field>
            <v-text-field
              name="password"
              label="新しいパスワード（確認）"
              id="password2"
              type="password"
              dense
              v-model="model.new_password2"
              :counter="20"
              :rules="passwordRules"
              :outlined=true
              background-color="white"
              required
            ></v-text-field>
          </v-form>
        </v-row>
        <v-row justify="center">
          <v-btn
            class="btn-auth btn-auth-black"
            style="margin-top: 10px;"
            rounded
            solo
            depressed
            color="black"
            @click="changePassword"
            >
            設定
          </v-btn>
        </v-row>
      </v-card>
      <v-row v-show="!passwordChangeFlg" justify="center" style="margin-top: 60px;">
        <v-btn
          class="btn-auth btn-auth-white"
          rounded
          solo
          depressed
          outlined
          @click="changePassword"
          >
          パスワード変更
        </v-btn>
      </v-row>
    </v-col>
  </v-row>

</div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
  data: () => ({
    // colList () {
    //   const { lg, sm, xl, md } = this.$vuetify.breakpoint
    //   return lg ? [1, 2] : sm ? [1, 2] : xl ? [1, 2] : md ? [1, 2] : [1, 2]
    // },
    accountinfo: {
      username: '',
      email: ''
    },
    passwordRules: [
      v => !!v || 'パスワードは必須項目です。',
      v => (v && v.length >= 8 && v.length <= 20) || '8~20字以内で入力してください。'
    ],
    passwordChangeFlg: false,
    model: {
    }
  }),
  computed: {
    cols () {
      const { lg, sm, xl } = this.$vuetify.breakpoint
      return lg ? [7 * 1 / 4, 7 * 3 / 4] : sm ? [9, 9] : xl ? [6 * 1 / 4, 6 * 3 / 4] : [6 * 1 / 4, 6 * 3 / 4]
    }
  },
  created () {
    if (this.checkLoggedin()) {
      this.accountinfo.username = this.$store.state.auth.login.username
      this.accountinfo.email = this.$store.state.auth.login.email
    } else {
      this.$router.push({ name: 'login' })
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('setLogOut')
      this.accountinfo.name = this.$store.state.auth.login.username
      this.$router.push({ name: 'listPage' })
      this.$session.destory()
    },
    changePassword () {
      const dataPassword = this.model
      if (this.passwordChangeFlg) {
        if (this.$refs.changeForm.validate()) {
          console.log(this.$store.state.auth.login.token)
          this.$axios
            .post(this.$httpUserPassChange,
              dataPassword, {
                headers: this.$store.state.auth.login.headers
              })
            .then(response => {
              this.passwordChangeFlg = !this.passwordChangeFlg
            })
            .catch(e => {
              const errrorText = this.getAxiosErrorString(e.response.data)
              Swal.fire({
                type: 'warning',
                title: 'Error',
                text: errrorText,
                showConfirmButton: false,
                showCloseButton: false,
                timer: 3000
              })
            })
          Swal.fire({
            text: 'パスワードが変更されました',
            showConfirmButton: false,
            showCloseButton: false,
            timer: 3000
          })
        }
      } else {
        this.passwordChangeFlg = !this.passwordChangeFlg
      }
    }
  }
}
</script>

<style scoped>
h4 {
  font-size: 21px;
  margin-top: 60px;
  margin-bottom: 60px;
  font-weight: 900;
  text-align: center;
}
h5 {
  font-size: 15px;
  margin-top: 60px;
  margin-bottom: 60px;
  font-weight: 900;
  text-align: center;
}
.account-card {
  background-color: #F5F5F5;
  width: 740px;
  padding-bottom: 40px;
  margin-bottom: 60px;
}
.newpass-card {
  background-color: #F5F5F5;
  width: 740px;
  padding-bottom: 40px;
  margin-top: 60px;
  margin-bottom: 60px;
}
.v-system-bar {
  background-color: #E5E2E2;
  min-height: 30px;
  font-size: 15px;
  font-weight: 900;
  margin-bottom: 30px;
  text-align: center;
}
.btn-auth-black {
  color: #ffffff;
  border: 0.5px solid;
}
.btn-auth-white {
  background-color: #ffffff;
  border: 0.5px solid #dadce0;
}

@media (max-width: 500px) {
  .btn-auth-black {
    border: 1px solid;
  }
  .btn-auth-white {
    border: 1px solid #dadce0 !important;
  }
}
.input-title {
  color: black;
  font-weight: 500;
  margin-bottom: 2px;
}
</style>

<style>
.Account .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot{
  min-height: 30px;
  width: 280px;
  margin-bottom: 0px;
}
.Account .v-input input {
  max-height: 30px;
}
.Account .v-label {
  font-size: 14px;
}
.Account .v-text-field input {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
