<template>
  <div id="app">
    <v-app id="inspire">
      <Loading v-show="loading && $route.name == 'homePage'" />
      <Header v-show="!loading" />

      <v-main v-show="!loading || $route.name != 'homePage'">
        <v-container v-if="$route.name == 'makePage'" id="make-page-container">
          <router-view v-if="isRouterShow" />
        </v-container>
        <v-container
          v-else-if="$route.name !== 'homePage' && $route.name !== 'detailPage'"
          id="scrolling-techniques-7"
        >
          <!-- 表示内容の指定 -->
          <router-view v-if="isRouterShow" />
          <!-- <router-view v-if='isRouterShow' class="reload" /> -->
        </v-container>
        <router-view v-else />
      </v-main>
      <!-- </transition> -->
      <Footer
        v-show="!loading"
        v-if="$route.name !== 'vrPage' && $route.name !== 'makePage'"
      />
      <!-- </div> -->
    </v-app>
  </div>
</template>

<!--WEBfont beta.archiroid.com-->
<!--<script
  src = "https://font.realtype.jp/api/script/v4"
  data-rt-user = "T19OlK6pXdpobZmQLyimZ4LXdw6Noaim"
></script>-->
<!--WEBfont archiroid.com-->
<script
  src="https://font.realtype.jp/api/script/v4"
  data-rt-user="lsviB5XIIwpv0O52vcqp56hYXrI8dKM0"
  data-rt-cache="true"
></script>

<!--loading-->
<script src="https://unpkg.com/vue-infinite-loading@^2/dist/vue-infinite-loading.js"></script>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
// import luxy from 'luxy.js'
// import 'animate.css'

export default {
  name: "app",
  components: { Header, Footer, Loading },
  data() {
    return {
      transition: {
        enter: "",
        leave: "",
        duration: 100
      },
      loading: true,
      isRouterShow: true
    };
  },
  methods: {
    async reload() {
      // <-これ!isRouterShow(true/false)をコントロール！
      this.isRouterShow = false;
      await this.$nextTick();
      this.isRouterShow = true;
    }
  },
  conputed: {},
  beforeCreated() {
    console.log("test");
  },
  created() {
    this.checkLoggedin();
  },
  mounted() {
    if (this.$route.name === "homePage") {
      setTimeout(() => {
        this.loading = false;
      }, 3000);
    } else {
      this.loading = false;
    }
  },
  // mounted () {
  //   luxy.init({
  //     wrapper: '#app',
  //     wrapperSpeed: 0.15
  //   })
  // }
  watch: {
    $route(to, from) {
      if (to.name === "detailPage" && from.name === "detailPage") {
        this.reload();
      } else if (to.name === "makePage" && from.name === "makePage") {
        console.log("makePage");
      }
      // // アニメーションの切り替え
      // if (to.meta.index > from.meta.index) {
      //   this.transition.enter = 'animate__rubberBand'
      //   this.transition.leave = 'animate__rubberBand'
      // } else {
      //   this.transition.enter = 'animate__rubberBand'
      //   this.transition.leave = 'animate__rubberBand'
      // }
    }
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.v-application {
  font-family: "A+EqpB-游ゴシック体 Pr6N L", "A+EqpB-游ゴシック体 Pr6N R",
    "A+EqpB-游ゴシック体 Pr6N M", "A+EqpB-游ゴシック体 Pr6N D",
    "A+EqpB-游ゴシック体 Pr6N B", "游ゴシック体", YuGothic, "游ゴシック",
    "Yu Gothic", Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN,
    "ヒラギノ角ゴ ProN W1", "Hiragino Kaku Gothic ProN W1",
    "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, sans-serif !important;
}

html {
  overflow-y: auto;
}

body {
  font-family: yu-gothic, fot-tsukuardgothic-std, sans-serif;
  line-height: 1.5;
  font-size: 14px;
}

.container {
  width: 100%;
  padding: 0 20px;
  max-width: 1500px;
  box-sizing: border-box;
}

.auth-card {
  /* background-color: #F5F5F5; */
  width: 360px;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.btn-auth {
  min-height: 40px;
  width: 280px;
}

@media (min-width: 670px) {
  .container {
    margin: 0 auto;
  }
}

@media (min-width: 1030px) {
  .container {
    max-width: 970px;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: 1234px;
  }
}

@media (min-width: 1560px) {
  .container {
    max-width: 1500px;
  }
}

.col {
  padding: 0px 10px 0px 10px;
}

.container--fluid {
  width: 100%;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.swal2-shown,
.swal2-height-auto {
  padding-right: 0px !important;
}
</style>

<!--<style lang="scss">
  @import '@/assets/scss/variables.scss';
</style>-->
