<template>
  <v-stepper v-model="e1" id="downloadStepper" alt-labels>
    <v-stepper-header class="elevation-1">
      <v-stepper-step :complete="e1 > 1" step="1">
        データの選択
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2"> お支払い </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3"> ダウンロード </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <!-- /////////////////////////////////////////////////////////////// データ選択 ////////////////////////////////////////////////////////////////////// -->
      <v-stepper-content step="1">
        <v-card class="elevation-0" color="">
          <div style="display: flex">
            <v-checkbox
              v-model="checkbox_pdf"
              label="図面データ（PDF）"
              v-bind:class="{ 'font-weight-bold': checkbox_pdf }"
            ></v-checkbox>
            <v-spacer style="padding: 32px 10px"
              ><v-divider></v-divider
            ></v-spacer>
            <div class="mt-5">
              <span
                class="float-right"
                v-bind:class="{ 'font-weight-bold': checkbox_pdf }"
                >¥ {{ this.price_pdf | addComma }}</span
              >
            </div>
          </div>
          <div style="display: flex">
            <v-checkbox
              v-model="checkbox_dxf"
              label="図面データ（dxf）"
              v-bind:class="{ 'font-weight-bold': checkbox_dxf }"
            ></v-checkbox>
            <v-spacer style="padding: 32px 10px"
              ><v-divider></v-divider
            ></v-spacer>
            <div class="mt-5">
              <span
                class="float-right"
                v-bind:class="{ 'font-weight-bold': checkbox_dxf }"
                >¥ {{ this.price_dxf | addComma }}</span
              >
            </div>
          </div>
          <div style="display: flex">
            <v-checkbox
              v-model="checkbox_3dm"
              label="3Dデータ（3dm）"
              v-bind:class="{ 'font-weight-bold': checkbox_3dm }"
            ></v-checkbox>
            <v-spacer style="padding: 32px 10px"
              ><v-divider></v-divider
            ></v-spacer>
            <div class="mt-5">
              <span
                class="float-right"
                v-bind:class="{ 'font-weight-bold': checkbox_3dm }"
                >¥ {{ this.price_3dm | addComma }}</span
              >
            </div>
          </div>
          <div style="display: flex">
            <v-spacer style="padding: 33px 10px"></v-spacer>
            <div class="mt-5">
              <span class="float-right font-weight-bold"
                >合計 ¥ {{ SelectedPrice | addComma }}</span
              >
            </div>
          </div>
          <div style="display: flex" class="mb-2">
            <span>※サンプルデータは<a href="" target="_blank">こちら</a></span>
          </div>
          <div style="display: flex" class="mb-5">
            <span>※購入済みデータは<a href="" target="_blank">こちら</a></span>
          </div>
          <v-row class="downloadAction">
            <v-col cols="2">
              <Button
                title="戻る"
                :func="
                  () => {
                    $emit('modalToggle');
                  }
                "
                :size="40"
                :mdi="mdiArrowRightCircle"
                :backFlg="true"
              />
            </v-col>
            <v-col cols="6"> </v-col>
            <v-col cols="4">
              <Button
                title="次へ"
                :func="
                  () => {
                    e1 = 2;
                    paypalButton();
                  }
                "
                :size="40"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-stepper-content>

      <!-- /////////////////////////////////////////////////////////////// 支払い ////////////////////////////////////////////////////////////////////// -->
      <v-stepper-content step="2">
        <v-card class="elevation-0" color="">
          <div style="display: flex" v-show="checkbox_pdf">
            <span class="font-weight-bold mt-5 item-label"
              >図面データ（PDF）</span
            >
            <v-spacer style="padding: 32px 10px"
              ><v-divider></v-divider
            ></v-spacer>
            <div class="mt-5">
              <span
                class="float-right"
                v-bind:class="{ 'font-weight-bold': checkbox_pdf }"
                >¥ {{ this.price_pdf | addComma }}</span
              >
            </div>
          </div>
          <div style="display: flex" v-show="checkbox_dxf">
            <span class="font-weight-bold mt-5 item-label"
              >図面データ（dxf）</span
            >
            <v-spacer style="padding: 32px 10px"
              ><v-divider></v-divider
            ></v-spacer>
            <div class="mt-5">
              <span
                class="float-right"
                v-bind:class="{ 'font-weight-bold': checkbox_dxf }"
                >¥ {{ this.price_dxf | addComma }}</span
              >
            </div>
          </div>
          <div style="display: flex" v-show="checkbox_3dm">
            <span class="font-weight-bold mt-5 item-label"
              >3Dデータ（3dm）</span
            >
            <v-spacer style="padding: 32px 10px"
              ><v-divider></v-divider
            ></v-spacer>
            <div class="mt-5">
              <span
                class="float-right"
                v-bind:class="{ 'font-weight-bold': checkbox_3dm }"
                >¥ {{ this.price_3dm | addComma }}</span
              >
            </div>
          </div>
          <div style="display: flex">
            <div class="mt-5 mx-auto mb-5">
              <span class="font-weight-bold sumPrice"
                >合計 ¥ {{ SelectedPrice | addComma }}</span
              >
            </div>
          </div>
        </v-card>

        <div id="paypal-button" />
        <v-row class="downloadAction">
          <v-col cols="2">
            <Button
              title="戻る"
              :func="
                () => {
                  e1 = 1;
                }
              "
              :size="40"
              :mdi="mdiArrowRightCircle"
              :backFlg="true"
            />
          </v-col>
          <v-col cols="6"> </v-col>
          <v-col cols="4">
            <Button
              title="次へ"
              :func="
                () => {
                  e1 = 3;
                }
              "
              :size="40"
            />
          </v-col>
        </v-row>
      </v-stepper-content>

      <!-- /////////////////////////////////////////////////////////////// ダウンロード ////////////////////////////////////////////////////////////////////// -->
      <v-stepper-content step="3">
        <v-card class="elevation-0" color="">
          <div
            style="display: flex"
            v-for="content in downloadContents"
            :key="content.name"
          >
            <span class="font-weight-bold mt-5 item-label">{{ content.name }}</span>
            <v-spacer style="padding: 32px 10px"
              ><v-divider></v-divider
            ></v-spacer>
            <div class="mt-5">
              <Button
                title="ダウンロード"
                :func="() => {}"
                :size="30"
                :subIcon="mdiDownload"
                :subIconSize="20"
              />
            </div>
          </div>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { mdiArrowRightCircle, mdiDownload } from '@mdi/js'
import { loadScript } from '@paypal/paypal-js'
import Button from '../components/Button/Button'
export default {
  components: {
    Button
  },
  data () {
    return {
      paypalSdk: null,
      mdiArrowRightCircle,
      mdiDownload,
      e1: 1,
      checkbox_pdf: false,
      checkbox_dxf: false,
      checkbox_3dm: false,
      price_pdf: 100,
      price_dxf: 500,
      price_3dm: 1000,
      downloadContents: [
        { name: '平面図面（PDF）', url: 'https://archiroid.com/' },
        { name: '平面図面（DXF）', url: 'https://archiroid.com/' },
        { name: '3Dデータ（OBJ）', url: 'https://archiroid.com/' }
      ]
    }
  },
  created () {
  },
  async mounted () {
    // await this.mountpaypalbutton()
    this.paypalSdk = await loadScript({
      'client-id': 'AcsXZHxdeWadzS58WWXxAYSCVbcBHUm4XjghImd-DjMUGh94PQYf5bbQXx84kah9qTiyt_m27SCu5fpo',
      currency: 'JPY'
    })
  },
  methods: {
    initStep: function () {
      this.e1 = 1
    },
    toDownloadStep: function (contents) {
      this.downloadContents = contents
      // for (let i = 0; i < contents.length; i++) {
      //   const content = contents[i]

      // }
      this.e1 = 3
    },
    paypalButton: function () {
      console.log('paypalButton-----------------')
      console.log(this.$el.querySelector('#paypal-button'))
      if (this.$el.querySelector('#paypal-button').childElementCount > 0) {
        this.$el.querySelector('#paypal-button').firstElementChild.remove()
      }

      console.log(this.$httpPaymentCreateOrder)
      const orderURL = this.$httpPaymentCreateOrder
      const captureURL = this.$httpPaymentCaptureOrder
      const headers = this.$store.state.auth.login.headers
      const selectedItems = [this.checkbox_pdf, this.checkbox_dxf, this.checkbox_3dm]
      const doneFunc = this.toDownloadStep
      this.paypalSdk.Buttons({
        style: this.style,
        createOrder (data, actions) {
          console.log('createOrder--------------------------------')
          console.log(orderURL)
          return fetch(orderURL, {
            method: 'post',
            headers: headers,
            body: JSON.stringify({
              selectedItems: selectedItems
            })
          }).then(function (res) {
            console.log('res')
            console.log(res)
            return res.json()
          }).then(function (orderData) {
            console.log('orderData')
            console.log(orderData)
            return orderData.id
          })
          // this.$axios.post(orderURL).then(res => {
          //   console.log('res')
          //   console.log(res)
          //   //   return res.json()
          // }).catch(e => {
          //   console.log('e')
          //   //   Swal.fire({
          //   //     type: 'warning',
          //   //     text: 'パスワードリセットに失敗しました。',
          //   //     showConfirmButton: false,
          //   //     showCloseButton: false,
          //   //     timer: 3000
          //   //   })
          //   console.log('Error : ' + e)
          // })
        },

        // Finalize the transaction
        onApprove: function (data) {
          console.log('onApprove--------------------------------')
          console.log(captureURL)
          console.log(data)
          return fetch(captureURL, {
            method: 'post',
            headers: headers,
            body: JSON.stringify({
              orderID: data.orderID
            })
          }).then(function (res) {
            console.log(res)
            return res.json()
          }).then(function (details) {
            console.log(details)
            doneFunc(details.contents)
            // alert('Transaction funds captured from ' + details.payer.name.given_name)
          })
        }

      }).render('#paypal-button')
    }
  },
  filters: {
    addComma: function (value) {
      return value.toLocaleString(undefined, { maximumFractionDigits: 1 })
    }
  },
  computed: {
    SelectedPrice: function () {
      return (this.checkbox_pdf === true ? this.price_pdf : 0) + (this.checkbox_dxf === true ? this.price_dxf : 0) + (this.checkbox_3dm === true ? this.price_3dm : 0)
    },
    style: function () {
      return {
        color: 'gold',
        shape: 'rect',
        layout: 'vertical',
        label: 'paypal',
        tagline: false
      }
    }
  }
}
</script>
<style scoped>
#downloadStepper >>> .v-stepper__wrapper {
  overflow: visible !important;
}
#downloadStepper >>> .v-label,
#downloadStepper >>> .v-input__slot {
  transition: none !important;
}
#downloadStepper >>> .downloadAction.row {
  margin: 0px !important;
}
.downloadAction >>> .col {
  padding: 0px !important;
}
.item-label {
  font-size: 16px;
}
.sumPrice {
  font-size: 20px;
}
</style>
<style>
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
  }
}
</style>
