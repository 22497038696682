<template>
  <div class="Policy">
    <ScrollToTopButton/>
    <v-row justify="center">
      <v-col cols=12 sm=9 lg=7 xl=6 >
        <h4>利用規約</h4>

        <h5>第 1 条 &nbsp; 利用規約について</h5>
        <ol>
          <li>この利用規約（以下「本規約」といいます）は、株式会社アーキロイド（以下「当社」といいます）が提供するすべての製品及びサービス（以下「本サービス」といいます）の利用に関する条件やルールを定めるものです。</li>
          <li>利用者は本規約の定めに従って本サービスを利用しなければなりません。本サービスの利用にあたっては、本規約に同意したものとみなします。</li>
          <li>当社は、当社の判断により、本規約を予告なく変更することができるものとします。</li>
          <li>当社が本規約を変更した場合、別段の定めがない限り、変更後の利用規約を公開、表示した時点で効力を生じるものとします。変更後の本規約の効力が生じた後に本サービスを利用した場合は、変更後の本規約に同意したものとみなされます。</li>
          <li>本規約に示される権利の行使または実施をしない場合でも、当社が当該権利を放棄するものではありません。</li>
          <li>本規約の全部、または一部が法令に基づいて無効と判断された場合であっても、当該規定の無効部分以外の部分およびその他の規定は有効であるものします。また、本規約の一部または全部が特定の利用者との間で無効とされた場合、または取り消された場合であっても、本規約はその他の利用者との間では有効であるものとします。</li>
        </ol>

        <h5>第 2 条 &nbsp; 定義</h5>
        <p class="indent-1">本規約において使用する用語を以下のように定めます。</p>
        <div class="kakko">
          <ol>
            <li>「利用者」：本サービスを利用するすべての個人または法人。利用者は登録者（下記）も含みます。 </li>
            <li>「登録者」：第３条に示すユーザー登録手続きを行い本サービスを利用する個人または法人。 </li>
            <li>「登録情報」：表示名、氏名、メールアドレス、パスワード等、登録者がユーザー登録手続き時に登録した情報をいいます。 </li>
            <li>「コンテンツ」：本サービス上の動画、オーディオ、画像、写真、文章、コメント、サービスマーク・サービスロゴや商号・商標などのブランド表示、3Dモデル、図面、コード、ソフトウェア、その他利用者、登録者、当社によって提供される素材の総称 </li>
            <li>「知的財産権」：著作権、特許権、実用新案権、意匠権、商標権その他一切の知的財産権（それらの権利を取得し、又はそれらの権利に付き登録等を出願する権利を含む） </li>
            <li>「提携企業」：本サービス提供のために当社が契約を締結した企業、本サービスを活用した事業のために当社が契約を締結した企業をいいます。 今後契約する企業も含みます。 </li>
          </ol>
        </div>

        <h5>第 3 条 &nbsp; ユーザー登録</h5>
        <ol>
          <li>本サービスはユーザー登録をせずにご利用いただくことが可能です。本サービスの一部のサービスをご利用頂く場合はユーザー登録が必要となります。ユーザー登録の有無によるサービス提供内容は予告なく変わる可能性があります。</li>
          <li>ユーザー登録を希望する利用者（以下「申込者」といいます）は、本利用規約に同意の上、当社所定の登録手続きを行っていただきます。</li>
          <li>当社は、前項の手続きに沿って入力されたお申込内容について、必要な審査を行い、申込者のユーザー登録を承諾する場合、入力されたメールアドレスにメールを配信することにより、当社の承諾の意思表示といたします。なお、お申込内容について審査した結果、次のいずれかに該当する場合には、当社の判断でユーザー登録を承諾しない場合があります。</li>
            <div class="kakko">
              <ol>
                <li>申込者が、過去に本利用規約に違反したことを理由に、当社から登録解除の処分を受けたものである場合。または禁止事項に該当、もしくは該当する恐れがある場合</li>
                <li>その他当社が不適切と判断した場合</li>
              </ol>
            </div>
          <li>当社は、前項とは別に、ユーザー登録の事前事後を問わず、申込者または登録者のお申込内容または登録情報、サービス利用の動向を審査し、以下の項目に該当すると当社が判断した場合には、当社の判断により、ユーザー登録を承諾せず、または解除することができるものとします。</li>
            <div class="kakko">
              <ol>
                <li>申込者または登録者が存在しない場合</li>
                <li>申込者または登録者が送受信可能なメールアドレスを持たない場合</li>
                <li>お申込み内容または登録情報に虚偽の記載があった場合</li>
                <li>当社への債務を怠ったことがある場合</li>
                <li>当社または提携企業の業務の遂行上または技術上支障をきたす場合</li>
                <li>申込者または登録者が未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、登録手続きが成年後見人によって行われておらず、または登録手続きの際に、法定代理人、保佐人もしくは補助人の同意を得ていなかった場合</li>
                <li>反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等と何らかの交流もしくは関与を行っている場合</li>
                <li>その他当社が申込者または登録者のユーザー登録を不適当と判断した場合</li>
              </ol>
            </div>
        </ol>

        <h5>第 4 条 &nbsp; メールアドレスおよびパスワードの管理</h5>
        <ol>
          <li>登録者は、メールアドレスおよびパスワードについて、自己の責任の下で管理を行うものとし、登録情報が不正確または虚偽であったために登録者が被った一切の不利益及び損害に関し、当社は一切の責任を追わないものとします。</li>
          <li>当社は、ログイン時に入力されたメールアドレスおよびパスワードが、登録されたメールアドレスおよびパスワードと一致することを所定の方法により確認した場合、当該ログインを真正な登録者のログインとみなし、登録者による利用とみなします。本サービスの利用によって料金または代金が発生した場合には、当該登録者に当該料金または代金を請求いたします。</li>
          <li>登録者は、パスワードを第三者に知られた場合、あるいはパスワード等が第三者に使用されている疑いのある場合には、直ちに当社にその旨連絡するとともに、当社の指示がある場合にはこれに従うものとします。</li>
          <li>登録者は、パスワード等が不正に利用されたことにより当社または提携企業に損害が生じた場合、当社または提携企業に対しその損害を賠償するものとします。</li>
        </ol>

        <h5>第 5 条 &nbsp; 広告の掲載等</h5>
        <p class="indent-1">利用者は、本サービスやメールなど、全てのコンテンツへの広告掲載、バナー表示が行われることを承諾します。 </p>

        <h5>第 6 条 &nbsp; 個人情報の取扱等</h5>
        <p class="indent-1">当社は、当社が取得した個人情報に関し、別途定める
          <router-link :to="{ name: 'privacypolicy'}"
            target="_blank" style="color: black;">
              プライバシーポリシー
            </router-link>
          に基づき、適切に取り扱うものとします。</p>

        <h5>第 7 条 &nbsp; 譲渡禁止等</h5>
        <ol>
          <li>登録者は、本サービスに関する権利について、第三者に譲渡、移転、または質権の設定その他の担保に供するなどの行為を禁止します。</li>
          <li>当社は、本サービスに係る事業を他社に譲渡した場合には、当該事業譲渡に伴いサービス利用契約上の地位、本利用規約に基づく権利及び義務ならびに利用者の登録事項その他の情報を当該事業譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡に付き予め同意したものとします。なお、本項にかかる事業譲渡は、会社分割その他事業が転移するあらゆる場合を含むものとします。</li>
        </ol>

        <h5>第 8 条 &nbsp; 連絡または通知</h5>
        <ol>
          <li>当社は、登録者へ連絡または通知の必要があると判断した場合には、登録されたメールアドレスへの電子メールの送付、本サービス上での表示、その他当社が適切と判断する方法にて連絡または通知を行います。</li>
          <li>利用者が当社に連絡する際は、別段の定めがある場合を除き当社所定のメールアドレスへのメール及びお問い合わせフォームから連絡するものとします。当社は電話による連絡および来訪は受け付けておりません。 </li>
        </ol>

        <h5>第 9 条 &nbsp; 禁止事項</h5>
        <p class="indent-1">利用者は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為を行ってはならないものとします。</p>
        <div class="kakko">
          <ol>
            <li>当社、またはその他の第三者が社会的信用を失うおそれのある行為</li>
            <li>当社、またはその他の第三者が経済的損失を被るおそれのある行為</li>
            <li>当社、またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利を侵害するおそれのある行為</li>
            <li>本サービスの運営を妨害するおそれのある行為</li>
            <li>本サービスに関連するネットワークまたはシステムなどに不正にアクセスする行為、または不正にアクセスを試みる行為</li>
            <li>本サービスのネットワーク、またはシステムなどに過度な負荷をかける行為</li>
            <li>本サービスを通じ、次の各号のいずれかに該当するファイルをアップロード、または当社に送信する行為</li>
              <div class="indent-2">
                <ul>
                  <li>コンピューター・ウィルス</li>
                  <li>有害なコンピュータ・プログラム</li>
                  <li>その他、前各号のいずれかに該当するおそれがあると当社が判断するファイル、または当社が不適切と判断するファイル</li>
                </ul>
              </div>
            <li>本サービスの全部または一部や本サービス上のコンテンツを複製、修正、転載、改変、変更、リバースエンジニアリング、逆アセンブル、逆コンパイル、翻訳、または解析する行為</li>
            <li>本サービスの全部または一部や本サービス上のコンテンツを商用目的、営利目的で、方法や手段を問わず使用、利用する行為</li>
            <li>本規約に限らず、本サービス上で表示またはメールなどで通知する、サービス利用に関するルールやお願いに抵触する行為</li>
            <li>法令、または公序良俗に反する行為</li>
            <li>前各号の行為を直接、または間接に惹起する行為、または容易にする行為</li>
            <li>その他、前各号のいずれかに該当するおそれがあると当社が判断する行為、または当社が不適切と判断する行為</li>
          </ol>
        </div>

        <h5>第 10 条 &nbsp; サービスの停止等</h5>
        <ol>
          <li>当社は、以下の各号のいずれかに該当する場合には、当社の判断で予告なく、本サービスの全部、または一部を停止、中断することができるものとします。</li>
            <div class="kakko">
              <ol>
                <li>本サービスの点検、または保守作業を緊急または定期的に行う場合</li>
                <li>コンピュータまたは通信回線が事故により停止した場合</li>
                <li>天災地変などの不可抗力、戦争、暴動、内乱、法令の改廃制定、公権力による命令処分、騒乱、労働争議などにより本サービスの運営が困難になった場合</li>
                <li>その他、当社が相当の事由があると判断した場合</li>
              </ol>
            </div>
          <li>当社は、本条に基づいて当社が行った措置により利用者、または第三者が被った損害について、一切の責任を負わないものとします。</li>
        </ol>

        <h5>第 11 条 &nbsp; サービスの内容変更、終了</h5>
        <ol>
          <li>当社は、当社の都合により、事前の通知なしに本サービスの内容を変更し、または提供を終了することができるものとします。</li>
          <li>当社は、当社の都合により、本サービスの運営を第三者に委託、または本サービスを第三者に譲渡することができるものとします。</li>
          <li>当社は、本条に基づいて当社が行った措置により利用者、または第三者が被った損害について、一切の責任を負わないものとします。</li>
        </ol>

        <h5>第 12 条 &nbsp; 利用環境の整備</h5>
        <ol>
          <li>利用者は、本サービスを利用するために必要な通信機器、ソフトウェアその他これらに付随して必要となる全ての機器、環境を自己の費用と責任において準備するものとします。また、本サービスの利用は、利用者は自己の費用と責任において任意に選択するものとします。</li>
          <li>利用者は、関係官庁等が提供する情報を参考にして、自己の利用環境に応じ、コンピューター・ウィルスの感染、不正アクセスおよび情報漏えいの防止などの措置をとるものとします。</li>
          <li>当社は、利用者の利用環境について一切関与せず、また一切の責任を追わないものとします。</li>
        </ol>

        <h5>第 13 条 &nbsp; 権利の帰属</h5>
        <ol>
          <li>本サービスに関する知的財産権は、原則として当社に帰属します。ただし、当社がライセンスを受けて掲載している第三者の著作物の著作権は、その限りではありません。</li>
          <li>利用者は、利用者が送信、発信、入力したコンテンツ、当社に対して、当社または当社の指定する者が当該コンテンツを日本国内外問わず対価の支払いなく非独占的にいかなる制約も受けずに自由に複製、公開、送信、頒布、譲渡、貸与、翻訳、翻案を含むあらゆる使用を許諾するものとします。利用者は当社および当社の指定するものに対して、当該コンテンツに係る著作者人格権を保有していたとしても、当該権利を行使しないものとします。</li>
          <li>利用者は、利用者が本サービス上で作成したコンテンツについて、その知的財産権が当社に帰属することを認めます。</li>
          <li>利用者が送信、発信、入力したコンテンツ、利用者によるサービスの利用・接続・規約違反、利用者による第三者への権利審判に起因または関連して生じたすべてのクレーム、請求、訴訟に関して、利用者の責任と費用においてこれを解決するものとします。</li>
          <li>前項のクレームや請求の対応に関連して当社に費用が発生した場合または当社が賠償金等の支払いを行った場合は、当該費用及び賠償金、当社が支払った弁護士費用等を当該利用者の負担とし、当社は当該利用者にこれらの支払いを請求できるものとします。</li>
          <li>当社は、利用者が送信、発信、入力したコンテンツについての保存義務を負わないものとし、利用者は自己の責任において適宜保存をするものとします。</li>
          <li>当社は、利用者が送信、発信、入力したコンテンツを、運営上必要に応じて閲覧することができ、規約に抵触すると判断した場合には、利用者への事前の通知なしに、当該コンテンツの全部または一部を非公開にすること、または削除することができるものとします。</li>
        </ol>

        <h5>第 14 条 &nbsp; リンク</h5>
        <ol>
          <li>本サービスへのリンクの設定は、以下の各号のいずれかに該当する場合にはお断りします。</li>
            <div class="kakko">
              <ol>
                <li>当社、またはその他の第三者が社会的信用を失うおそれがある場合</li>
                <li>当社、またはその他の第三者が経済的損失を被るおそれがある場合</li>
                <li>当社、またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利を侵害するおそれがある場合</li>
                <li>サービスのオリジナルデザインを変更するような改変や、フレーム内でサービスまたは本サービスが展開されるなど、当社のサービスであることが不明確となり、第三者に誤解を与えるおそれがある場合</li>
                <li>本サービスの運営を妨害するおそれがある場合</li>
                <li>法令または公序良俗に反する場合</li>
                <li>その他、前各号のいずれかに該当するおそれがあると当社が判断する場合、または当社が不適切と判断する場合</li>
              </ol>
            </div>
          <li>当社が保有するロゴやマークなどの登録商標を無断で用いてリンク設定することは認めておりません。</li>
          <li>リンクに関連した賠償、苦情などに関して、当社は一切の責任を負わないものとします。</li>
        </ol>

        <h5>第 15 条 &nbsp; 自己責任の原則</h5>
        <p class="indent-1">本サービスの利用は利用者の自由意志によるものとし、利用に関していかなる責任も利用者にあるものとします。</p>

        <h5>第 16 条 &nbsp; 利用制限</h5>
        <ol>
          <li>当社は、利用者が以下のいずれかに該当する場合には、当該利用者の承諾を得ること無く、当該利用者の本サービスの利用を制限することがあります。</li>
            <div class="kakko">
              <ol>
                <li>ワーム型ウィルスの感染、大量送信メールの経路等により、当該登録者が関与することにより第三者に被害が及ぶ恐れがあると判断した場合</li>
                <li>メールによる連絡が取れない場合</li>
                <li>法令または公序良俗に反する場合</li>
                <li>上記各号の他、当社が緊急性が高いと判断した場合</li>
              </ol>
            </div>
          <li>当社が前項に基づき利用者の本サービスの利用を制限したことにより、当該利用者が本サービスを利用できずに損害が発生したとしても、当社は一切の責任を負わないものとします。</li>
        </ol>

        <h5>第 17 条 &nbsp; 免責</h5>
        <ol>
          <li>当社は、本サービスの利用により発生した利用者の損害については、一切の賠償責任を負わないものとします。</li>
          <li>利用者が、本サービスを利用することにより、その他の第三者に対して損害を与えた場合、利用者は自己の責任と費用においてこれを賠償するものとします。</li>
          <li>当社は、本サービスの出力情報を含む本サービスにおいて提供する情報について、利用者の目的に適合すること、完全性、正確性、確実性、有効性、有用性、利用可能性、及び最新性を有することを保証いたしません。</li>
          <li>当社は、本サービスの出力情報を含む本サービスにおいて提供する情報について、利用者に適用ある法令に適合することを保証いたしません。</li>
          <li>当社は、本サービスの機能が中断されることはないこと、エラーがないこと、または欠点が修正されること、このサイトまたはサーバーにコンピュータ・ウィルスおよびその他の有害物が無いことを保証いたしません。</li>
        </ol>

        <h5>第 18 条 &nbsp; 準拠法および管轄裁判所</h5>
        <ol>
          <li>本サービスの利用、本規約の解釈および適用は、他に別段の定めの無い限り準拠法は日本法とします。</li>
          <li>本サービスの利用、本規約に起因する、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
        </ol>

        <p style="margin-top:40px;">2020年7月3日 制定</p>
        <p style="margin-top:0px;">2020年8月8日 改定</p>
        <p style="margin-top:0px;">2021年8月25日 改定</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ScrollToTopButton from '../components/Button/ScrollToTopButton'
export default {
  components: {
    ScrollToTopButton
  },
  data () {
    return {
    }
  }
}
</script>
