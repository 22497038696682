<template>
  <div class="Policy">
    <ScrollToTopButton/>
    <v-row justify="center">
      <v-col cols=12 sm=9 lg=7 xl=6 >
        <h4>プライバイシーポリシー</h4>

        <p>株式会社アーキロイド（以下「当社」といいます）は、
          当社が運営管理するサービス（以下「本サービス」といいます）
          におけるユーザーについての個人情報を含むユーザーの情報（以下「ユーザー情報」といいます）
          の取り扱いについて、以下の通りプライバシーポリシー（以下「本ポリシー」といいます）を定めます。</p>

        <h5>第 1 条 &nbsp; 当社が取得するユーザー情報</h5>
        <p class="indent-1">本ポリシーにおいて個人情報とは、
          個人情報の保護に関する法律（以下「個人情報保護法」といいます。）に定める「個人情報」をいいます。
          当社は、本サービスにおいて以下のユーザー情報を取得する場合があり、これらの情報には個人情報が含まれることがあります。</p>
        <ol>
          <li>ユーザーから取得する情報</li>
            <ul>
              <li>お名前</li>
              <li>ユーザー情報</li>
              <li>その他当社がアプリまたはウェブサイトにおいて取得すると定めた情報</li>
            </ul>

          <li>本サービスの利用に関する情報</li>
            <p>当社はユーザーの本サービスの利用にあたって、本ユーザーのアクションに応じた、
              コメント、写真、シェア、検索、閲覧などの情報およびその他のユーザーのアクションに関する情報を、
              その日時とともに取得します。</p>

          <li>技術情報</li>
            <p style="margin-bottom:5px;">当社はユーザーの本サービスの利用にあたって、以下の情報を取得します。</p>
            <ul>
              <li>端末情報（端末機種名、端末メーカー、端末OSバージョン、端末の個体識別情報、コンピュータ名、IPアドレス、ブラウザ種類、ブラウザ言語等）</li>
              <li>スマートフォン等にかかる位置情報（GPS、ビーコン等）</li>
              <li>その他広告識別子（IDFAまたはGoogle Advertising ID等）</li>
              <li>Cookie</li>
            </ul>
        </ol>
        <p class="indent-1">※ 本サービスでは、ユーザーの利用状況を分析するため、
          あるいは個々のユーザーに対してカスタマイズされたサービス・広告を提供する目的のため、
          Cookie及び類似技術を使用して一定の情報を収集する場合があります。Cookie の使用を希望されない場合は、
          ユーザーがブラウザの設定を変更することにより、Cookie の使用を拒否することができますが、
          その場合には、本サービスの一部または全部をご利用できなくなる場合がありますので、あらかじめご了承ください。</p>

        <h5>第 2 条 &nbsp; ユーザー情報の利用目的</h5>
        <p class="indent-1">当社は取得したユーザー情報を以下の目的で利用いたします。
          なお、第3条に定める内容に該当する場合に限り、以下の目的のために、
          ユーザー情報を当社のサービスにおける商品の出品者その他の第三者に提供する場合があります。</p>
        <div class="kakko">
          <ol>
            <li>料金請求、課金計算をするため</li>
            <li>当社または第三者の商品・権利・サービス等の販売をするため</li>
            <li>当社または第三者が提供するサービスの利用に関する審査のため</li>
            <li>当社または第三者が提供するサービスの障害情報、メンテナンス情報等技術的なサポートに関する情報のご提供のため</li>
            <li>本サービスの利用に関する統計データの作成、分析をするため</li>
            <li>当社または第三者の商品・サービス等の品質向上、システムの維持、不具合対応をするため</li>
            <li>当社または第三者が提供するサービスに関するサポートや、ユーザーからお問い合わせに関する内容確認、調査、またはご返信のため</li>
            <li>当社または第三者が実施するアンケート、ユーザーヒアリング等への実施のため</li>
            <li>当社または第三者が提供する各サービス及びそれに関連するサービスのご提供及びユーザーの管理のため</li>
            <li>当社または第三者の商品・サービス等の広告または宣伝のため</li>
            <li>ユーザーごとに最適化された当社または第三者のコンテンツの提供、広告の配信のため</li>
            <li>当社または第三者のセミナー、イベントの管理、告知、キャンペーン等の実施及び関連する連絡等を行うため</li>
            <li>当社または第三者のサービスに関する新企画、新商品または新サービスの立案、開発、提供をするため</li>
            <li>利用規約違反その他本サービスの不正・不当利用に対応するため</li>
            <li>提携企業等から個人情報の取扱業務を委託された場合、委託された業務を遂行するため</li>
            <li>その他本サービスに関するお知らせをしたり、運営上必要な連絡を行ったりするため
            <li>契約や法令等に基づく権利を行使したり義務を履行したりするため</li>
            <li>その他当社の提供する各サービス等において個別に定める目的のため</li>
          </ol>
        </div>

        <h5>第 3 条 &nbsp; ユーザー情報の第三者への開示・提供</h5>
        <ol class="indent-1">
          <li>当社は、取得したユーザー情報を、ユーザーご本人の同意を得ずに第三者に提供することは、原則いたしません。
            ただし以下の場合は、関係法令に反しない範囲で、ユーザー情報を提供することがあります。</li>
            <div class="kakko">
            <ol style="margin-top:5px;">
              <li>法令に基づく場合</li>
              <li>人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難である場合</li>
              <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難である場合</li>
              <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、
                ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合</li>
              <li>業務委託契約に基づいて、ユーザー情報の取扱いの一部または全部を外部の会社に委託する場合
                （サーバーへの蔵置および本サービスの品質を向上する目的で、業務委託先に情報の管理および分析を委託する場合も含みます。）</li>
              <li>合併、会社分割、事業譲渡その他の事由によって事業の継承が行われる場合</li>
            </ol>
            </div>

          <li>当社は、氏名や住所など直接特定の個人を識別することができる情報を除外した上で、下記のような情報を提供することがあります。
            ユーザーは、下記のような情報を第三者が、当社または当該第三者が第２条に定める目的を遂行するため、
            当該第三者のプライバシーポリシー等に基づき使用することに同意するものとします。</li>
            <ul style="margin-top:5px;">
              <li>端末情報（端末機種名、端末メーカー、端末OSバージョン、端末の個体識別情報、コンピュータ名、IPアドレス、ブラウザ種類、ブラウザ言語等）</li>
              <li>広告識別子（IDFAまたはGoogle Advertising ID等）</li>
              <li>スマートフォン等にかかる位置情報（GPS、ビーコン等）</li>
              <li>ログ情報（本サービス内のユーザーの行動履歴（閲覧履歴、購買履歴等を含む））</li>
              <li>符号化したユーザーアカウント等の識別子</li>
              <li>ユーザーの属性情報（年代、性別、趣味趣向等を含む）</li>
            </ul>
        </ol>

        <h5>第 4 条 &nbsp; 個人情報の開示、訂正について</h5>
        <ol class="indent-1">
          <li style="margin-bottom:16px;">本サービスにおける個人情報について<br>
            本サービスでは、当社保有の個人情報のすべて、または一部が合理的、技術的に適切な方法で保護された環境下で閲覧、
            訂正することが可能な場合があります。サービスよりアカウントページにログインしてください。
          </li>
          <li>当社保有の個人情報について<br>
            前項の方法で閲覧、訂正等を行う事ができない個人情報について、
            ユーザーご本人が個人情報の利用目的の通知、個人情報の開示、訂正、追加、利用の停止又は第三者への提供の停止を希望される場合、
            下記問い合わせ先にご連絡ください。ユーザーご本人からのご請求であることを確認の上で、
            当社が定める手続きに従ってご請求いただければ、合理的な範囲内で速やかに対応いたします。
            但し、個人情報保護法その他の法令により、当社が訂正、利用停止等および第三者提供停止の義務を負わない場合は、この限りではありません。
          </li>
        </ol>

        <h5>第 5 条 &nbsp; 個人情報の安全管理措置について</h5>
        <p>本ポリシーにおいて個人情報とは、
          当社は、取得するユーザー情報に関して、情報への不正な侵入、紛失、破壊、改ざん、漏洩、滅失または毀損の防止のために、必要かつ適切な措置を講じます。</p>

        <h5>第 6 条 &nbsp; 継続的改善</h5>
        <p>当社は、ユーザー情報の取扱いを適切なものとするよう、継続的な改善を実施します。</p>

        <h5>第 7 条 &nbsp; ユーザー情報に関するお問合わせ</h5>
        <p>ご意見、ご質問、苦情のお申出その他ユーザー情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。<br>
        株式会社アーキロイド<br>
        Email：info[at]archiroid.com &nbsp; [at]を@に変えてお送りください。</p>

        <h5>第 8 条 &nbsp; プライバシーポリシーの変更</h5>
        <p>当社では、取得するユーザー情報の変更、またはその他のプライバシーポリシーの変更を行う際は、当ページの変更をもって公表といたします。
          変更後のプライバシーポリシーはサイト上に改定日を表示した時点より効力を生じます。
          なお、法令上ユーザーの同意が必要となる内容の改定の場合は、当社所定の方法でユーザーの同意を得るものとします。</p>

        <p style="margin-top:40px;">2020年7月3日 制定</p>
        <p style="margin-top:0px;">2020年8月8日 改定</p>
        <p style="margin-top:0px;">2021年8月25日 改定</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ScrollToTopButton from '../components/Button/ScrollToTopButton'
export default {
  components: {
    ScrollToTopButton
  },
  data () {
    return {
    }
  }
}
</script>

<style>
.Policy h4 {
  font-size: 21px;
  margin-top: 60px;
  margin-bottom: 60px;
  font-weight: 900;
  text-align: center;
}

.Policy h5 {
  font-size: 16px;
  font-weight: 700;
  margin-top:50px;
  margin-bottom: 20px;
}

/* h6 {
  font-size: 14px;
  font-weight: 700 !important;
  margin-bottom:3px;
} */

.Policy p {
  font-size: 14px;
  margin-bottom: 0px;
  /* margin-bottom: 30px !important; */
  /* margin:0px !important; */
}

.Policy ol {
  margin:0px 0px 16px 0px;
}
.Policy ul {
  margin:0px 0px 16px 0px;
  list-style-type: disc;
}
.Policy li {
  margin-top: 0px;
  /* margin-top: 0px; */
}

.kakko ol.parentheses1 {
  /* margin-left: 3.5em; */
}
.kakko {
  margin-left: 0.5em;
}
.kakko ol li {
  list-style-type: none;
  counter-increment: cnt;
}
.kakko ol li::before {
  content: "(" counter(cnt) ")";
  display:inline-block;
  margin-left:-2em;
  width: 2em;
}
.kakko ul li {
  list-style-type: disc;
  counter-increment: uncnt;
}
.kakko ul li::before {
  content: none;
}

.Policy .noMargin {
  margin-bottom: 0px !important;
}

.Policy .indent-1{
  margin-top:10px !important;
}
.Policy .indent-2{
  /* margin-top:10px !important; */
  margin-left: 24px!important;
}

/* @media (min-width: 1030px) {
  h4 {
  margin-top: 120px;
  margin-bottom: 90px;
  }
} */

</style>
