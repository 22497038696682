<template>
  <v-app-bar
    id="Header"
    class="Header"
    height="60"
    flat
    fixed
    :hide-on-scroll="false"
    :app="app"
    :color="color"
    :dark="dark"
    v-bind:class="{ clearHeader: clear === true }"
    v-scroll="onScroll"
  >
    <v-toolbar-title >
      <router-link :to="{ name: 'homePage'}">
      <v-img style="width: 160px" width="160px" src="@/assets/archiroidcom_wide.png" v-bind:class="{ whiteIcon: dark === true }"
    /></router-link></v-toolbar-title>

    <v-spacer />

    <v-btn-toggle v-model="sBtnNum" group active-class="sBtn">
      <v-btn
        class=""
        elevation="0"
        min-width="32"
        width="32"
        height="52"
        :color="color"
        :to="{ name: 'listPage'}"
      >
        <v-row justify="center">
          <!-- <v-icon size="30">search</v-icon> -->
          <v-icon size="30">{{ svgPath_Magnify }}</v-icon>
        </v-row>
      </v-btn>
      <v-btn
        elevation="0"
        min-width="32"
        width="32"
        height="52"
        :color="color"
        :to="{ name: 'makePage'}"
        @click="clickMakeIcon"
      >
        <v-icon size="24">{{ svgPath_Wrench }}</v-icon>
      </v-btn>
      <v-btn
        class=""
        elevation="0"
        min-width="32"
        width="32"
        height="52"
        :color="color"
        :to="{ name: 'account'}"
      >
        <v-icon size="30">{{ svgPath_Account }}</v-icon>
      </v-btn>
      </v-btn-toggle>
  </v-app-bar>
</template>

<script>
import { mdiMagnify, mdiWrench, mdiAccount } from '@mdi/js'
export default {
  name: 'site-header',
  props: {
  },
  data () {
    return {
      svgPath_Magnify: mdiMagnify, // Material Design Icon
      svgPath_Wrench: mdiWrench, // Material Design Icon
      svgPath_Account: mdiAccount, // Material Design Icon
      lang: 'jp',
      sBtnNum: -1,
      clear: false,
      hideOnScroll: true,
      app: true,
      color: 'white',
      dark: false,
      home: this.clear
    }
  },
  created () {
    // this.setSBtnNum()
  },
  mounted () {
    this.setHome()
  },
  methods: {
    setHome: function () {
      if (this.$route.name === 'homePage') {
        this.clear = true
        this.hideOnScroll = false
        this.app = false
        this.color = 'transparent'
        this.dark = true
        this.sBtnNum = -1
      } else {
        this.clear = false
        this.hideOnScroll = true
        this.app = true
        this.color = 'white'
        this.dark = false
      }
      this.home = this.clear
    },
    clickMakeIcon: function (event) {
      if (!this.checkLoggedin()) {
        this.showSwal('メイクモードの利用にはログインが必要です', 3000)
      }
      // this.showSwal('【メイクモード】 現在、鋭意製作中です...！', 1200)
    },
    onScroll (e) {
      if (this.home === true) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset || e.target.scrollTop || 0
        if (top < window.innerHeight) {
          this.dark = true
          this.color = 'transparent'
          this.clear = true
          this.hideOnScroll = false
        } else {
          this.dark = false
          this.color = 'white'
          this.clear = false
          this.hideOnScroll = true
        }
      }
    }
  },
  watch: {
    $route: function (to, from) {
      if (to.path !== from.path) {
        // this.setSBtnNum()
        this.setHome()
        // 以下ルート変更時に、一度ヘッダーを出す。
        // this.hideOnScroll_flg = false
        // this.hideOnScroll_flg = this.hideOnScroll
      }
    }
  },
  computed: {
  }
}
</script>

<style scoped>
#Header >>> .v-btn.sBtn::after {
  content: "";
  border-bottom: 4px solid #000000 !important;
  display: block;
  position: absolute;
  bottom: -4px;
  width: 100%;
}
#Header >>> .v-btn {
  margin-right: 0px !important;
  margin-left: 0px !important;
  padding: 0px !important;
  border: none !important;
  border-radius: 4px;
  opacity: 1;
}
.ver {
  color: #000000;
}
.clearHeader .ver {
  color: #095ff0;
}
.whiteIcon{
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.Header >>> .v-toolbar__content {
  padding: 0px 20px 0px 20px !important;
  border-bottom: 0.5px solid #dadce0 !important;
}
@media (max-width: 500px) {
  .Header >>> .v-toolbar__content {
    border-bottom: 1px solid #dadce0 !important;
  }
}
.clearHeader >>> .v-toolbar__content {
  border-bottom: none !important;
}
.Header >>> .v-toolbar__title {
  cursor: pointer;
}
.Header >>> .v-toolbar__title,
.Header >>> .v-btn {
  font-size: 14px;
  font-weight: 700;
}
.Header >>> .v-btn__content {
  width: 34px;
}

@media (min-width: 1030px) {
  .Header >>> .v-toolbar__content {
    padding: 0px 50px 0px 50px !important;
  }
  #Header >>> .v-btn {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
}
</style>
