<template>
  <div>
    <div id="Unity">
      <iframe
        id="UnityFrame"
        :src="unitySrc"
        :width="width"
        :height="height"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        ref="makeInstance"
      ></iframe>
      <div
        id="UnityData"
        :loginUser="loginUser"
        modeName="vr"
        :projectid="this.$route.params.id"
        :jwt="this.$store.state.auth.login.headers.Authorization"
      ></div>
    </div>
    <!-- <div v-if="howtoImgFlg" class="unity_howto_img">
      <v-carousel
        cycle
        interval="3000"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-btn
          class="closed_btn"
          fab
          dark
          x-small
          outlined
          @click="closeHowToUse"
        >
          <v-icon dark>{{ svgPath_Close }}</v-icon>
        </v-btn>
        <v-carousel-item v-for="(slide, i) in howtoImgs" :key="i">
          <v-img class="howtoimg" :src="slide" aspect-ratio="1" contain></v-img>
        </v-carousel-item>
      </v-carousel>
    </div> -->
    <div v-if="loaded == false">
      <div class="unity-loader-custom">
        <div class="bar_container">
          <div class="bar">
            <div
              class="fill"
              v-bind:style="{ width: progress * 100 + '%' }"
            ></div>
          </div>
          <div
            style="
              color: #FFFFFF;
              height: 30px;
              text-align: center;
              margin-top: 15px;
            "
          >
            Loading...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gv from '../mixins/globalValiables'
import { mdiLaunch, mdiBookmark, mdiClose, mdiChevronRight, mdiChevronLeft } from '@mdi/js'
export default {
  components: {
  },
  data () {
    return {
      svgPath_Launch: mdiLaunch,
      svgPath_Bookmark: mdiBookmark,
      svgPath_Close: mdiClose,
      svgPath_ChevronRight: mdiChevronRight,
      svgPath_ChevronLeft: mdiChevronLeft,
      house: null,
      price: 0,
      floor_area: 0,
      inset: true,
      collapse: true,
      fav_count: 0,
      progress: 0,
      loaded: true,
      howtoImgs: ['/img/howtouse_go.png', '/img/howtouse_lookaround.png', '/img/howtouse_360look.png'],
      howtoImgFlg: true,
      height: document.documentElement.clientHeight - 60,
      width: document.documentElement.clientWidth,
      unitySrc: '/Unity/index.html',
      projectID: 1
    }
  },
  watch: {
    progress: function (val, oldVal) {
      if (val === 1) {
        this.menuSwitch()
      }
    }
  },
  beforeCreate: function () {
    document.body.className = 'vr'
  },
  mounted () {
    // this.$axios.get(this.$httpGetHouse, {
    //   params: {
    //     id: this.$route.params.id
    //   }
    // })
    //   .then(response => {
    //     // console.log(response)
    //     return response.data
    //   })
    //   .then(data => {
    //     this.house = data
    //     this.price = data.price
    //     this.floor_area = data.floor_area
    //   })
  },
  created () {
    window.addEventListener('beforeunload', this.traHandler)
    this.interval = setInterval(() => this.updateProgress(), 500)
  },
  beforeDestroy: function () {
    window.removeEventListener('beforeunload', this.windowSize)
  },
  destroyed () {
    window.removeEventListener('beforeunload', this.traHandler)
  },
  beforeRouteEnter (to, from, next) {
    const devType = gv.methods.getDeviceType()
    if (devType !== 'PC') {
      window.alert(
        '現在VRページはPCのみ対応しています。是非PCでもアクセスしてみてください！')
    } else {
      next()
    }
  },
  // beforeRouteLeave (to, from, next) {
  //   var answer = window.confirm(
  //     'VRページはロードに時間がかかります。本当に移動していいですか？')
  //   if (answer) {
  //     next()
  //   } else {
  //     next(false)
  //   }
  // },
  computed: {
    loginUser: function () {
      if (this.$store.state.auth.login.token) {
        return this.$store.state.auth.login.user_id
      } else {
        return null
      }
    }
  },
  methods: {
    windowSize: function () {
      this.height = document.documentElement.clientHeight - 60
      this.width = document.documentElement.clientWidth
    },
    menuSwitch () {
      this.collapse = !this.collapse
    },
    updateProgress () {
      if (this.$refs.unityInstance !== undefined) {
        this.progress = this.$refs.unityInstance.progress
        this.loaded = this.$refs.unityInstance.loaded
        // this.$refs.unityInstance.loaded = false
        // this.UnityProgress()
        // var unityContainer = document.getElementById
      }
    },
    traHandler (event) {
      event.returnValue = "Data you've inputted won't be synced"
    },
    closeHowToUse () {
      this.howtoImgFlg = false
    }
  },
  filters: {
    addComma: function (value) {
      return value.toLocaleString(undefined, { maximumFractionDigits: 1 })
    }
  }
}
</script>
<style scoped>
.theme--dark.v-toolbar.v-sheet {
  background-color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-divider {
  border-right: 1px solid #DADCE0;
  border-left: 0px;
}
.v-application--is-ltr .v-toolbar.v-toolbar--collapsed {
  border-bottom-right-radius: 0px;
  width: 50px;
  max-width: 50px;
}
.theme--light.v-footer {
  background-color: transparent;
}
.v-card__subtitle,
.v-card__text {
  font-size: 13px !important;
}
.v-divider--vertical.v-divider--inset {
  margin-top: 0px;
  min-height: 0;
  max-height: 60px;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0 0 0 47px;
}
.text--primary div {
  padding-top: 4px;
}
.text--primary div .row {
  padding-top: 0px;
}
.text--primary div .row i {
  margin-top: -3px;
}
.text--primary div span {
  margin-right: 7px;
}
.margin-right {
  margin-right: 16px;
}
.text--primary .iconWarn span {
  font-size: 8px;
  font-weight: 700;
  padding: 2px 5px;
  background-color: #FFFFFF;
  color: #666666;
  text-align: center;
  vertical-align: middle;
}
.textWarn {
  text-align: center;
  vertical-align: middle;
}
.webgl-content {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
}
</style>
<style>
.VRbar .v-toolbar__content,
.VRbar .v-toolbar__extension {
  padding: 30px 13px;
}
.VRbar .v-toolbar__extension {
  padding: 30px 13px 30px 60px !important;
}
.unity-loader-custom {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
}
.bar_container {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
}
.bar {
  width: 50%;
  height: 15px;
  margin: auto;
  border: 2px solid oldlace;
}
.fill {
  width: 100%;
  height: 100%;
  background-color: #095FF0;
}
.unity_howto_img {
  position: absolute;
  width: 45%;
  height: 500px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
}
.closed_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.howtoimg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}
</style>
<style>
.vr .unity-loader {
  display: none;
}
</style>>
<style>
#UnityFrame {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.vr #unity-container {
  height: 100%;
}
</style>
