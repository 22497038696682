import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    thresholds: {
      // main.scssの値も変えること！
      xs: 0,
      sm: 670,
      md: 1030,
      lg: 1264,
      xl: 1560
    }
  },
  icons: {
    iconfont: 'mdiSvg'
  }
})
