<template>
  <div>
    <v-fab-transition>
      <v-btn
        style="z-index: 5;"
        v-scroll="onScroll"
        v-show="fab"
        fixed
        fab
        large
        bottom
        right
        dark
        color="#000000"
        @click="toTop"
        elevation=0
      >
        <!-- <v-icon>keyboard_arrow_up</v-icon> -->
        <v-icon size=30>{{svgPath_ChevronUp}}</v-icon>
        <!--<v-icon>arrow_upward</v-icon>-->
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import { mdiChevronUp } from '@mdi/js'
export default {
  data () {
    return {
      fab: false,
      svgPath_ChevronUp: mdiChevronUp
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped>
  .v-btn--fixed.v-btn--bottom{
    bottom: 50px;
  }
</style>
