var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"SearchQualityBar",attrs:{"no-gutters":""}},_vm._l((4),function(k){return _c('v-col',{key:k,attrs:{"cols":k===1?2 :undefined,"sm":k===1?3 :k===2?3 :k===3?3 :k===4?3 :undefined}},[(k===1)?_c('div',[_c('v-card',{staticClass:"buttonedge-left",attrs:{"outlined":"","tile":""}},[_c('v-card-title',{staticStyle:{"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"}},[_c('v-avatar',{attrs:{"size":"20"}},[_c('img',{attrs:{"src":require("@/assets/icon_Architecture_G.svg")}})]),_vm._v(" "+_vm._s(_vm.filter_quality_label)+" ")],1)],1)],1):(k===2)?_c('div',[_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-select',{attrs:{"label":_vm.filter_quality_structure_label,"multiple":"","height":"30","color":"black","item-color":"gray","disabled":"","single-line":"","menu-props":{ maxHeight: 1800 }}})],1)]}}],null,true)},[_c('div',{staticClass:"tips text-center"},[_c('span',[_vm._v("【耐震等級】")]),_c('br'),_c('br')]),_c('div',{staticClass:"tips"},[_c('span',[_vm._v("全ての建物で")]),_c('br'),_c('span',[_vm._v("許容応力度設計を実施しています。")]),_c('br'),_c('span',[_vm._v("（完成度60％）")]),_c('br'),_c('span',[_vm._v("計算結果はVRの「構造」ボタンでご確認下さい。")]),_c('br'),_c('span',[_vm._v("結果は梁成に反映されています。")])])])],1)],1):(k===3)?_c('div',[_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-select',{attrs:{"label":_vm.filter_quality_ec_label,"multiple":"","height":"30","color":"black","item-color":"gray","single-line":"","menu-props":{ maxHeight: 1800 },"disabled":""}})],1)]}}],null,true)},[_c('div',{staticClass:"tips text-center"},[_c('span',[_vm._v("【温熱等級】")]),_c('br'),_c('br')]),_c('div',{staticClass:"tips text-center"},[_c('span',[_vm._v("今後、全ての建物で")]),_c('br'),_c('span',[_vm._v("温熱等級評価を実装する予定です。")])])])],1)],1):_c('div',[_c('v-card',{staticClass:"buttonedge-right",attrs:{"outlined":"","tile":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-select',{attrs:{"label":_vm.filter_quality_low_label,"multiple":"","height":"30","color":"black","item-color":"gray","single-line":"","menu-props":{ maxHeight: 1800 },"disabled":""}})],1)]}}],null,true)},[_c('div',{staticClass:"tips text-center"},[_c('span',[_vm._v("【法適合性】")]),_c('br'),_c('br')]),_c('div',{staticClass:"tips"},[_c('span',[_vm._v("【集団規定】")]),_c('br'),_c('span',[_vm._v("道路・隣地・北側斜線、絶対高さ")]),_c('br'),_c('span',[_vm._v("【単体規定】")]),_c('br'),_c('span',[_vm._v("自然換気・採光")]),_c('br'),_c('span',[_vm._v("↑VRでご確認いただけます。")]),_c('br'),_c('span'),_c('br'),_c('span',[_vm._v("高さ制限.comで一部機能をお使い頂けます。")]),_c('br'),_c('span',[_vm._v("https://heightlimit.archiroid.com/")])])])],1)],1)])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }