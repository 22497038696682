<template>
  <div>
    <v-btn
      v-if="func != null"
      class="btnVR rounded-lg"
      solo
      depressed
      :height="size"
      :color="color"
      v-on:click="func"
      block
      style="padding: 0"
      v-bind:style="{ color: colorText }"
      :disabled="disabled"
    >
      <template v-if="backFlg == false">
        <v-icon
          v-if="mdi != null"
          left
          style="margin: 0 0 0 10px"
          :size="iconSize()"
          color="#f2f2f2"
          >{{ mdi }}</v-icon
        >
        <span v-else-if="icon == 'VR'" style="margin: 0 0 0 10px">
          <!-- <v-img :src="require(`@/assets/${icon};`)" width="30" height="30"/> -->
          <v-img src="@/assets/icon_VR_W.svg" width="30" height="30" />
        </span>
        <span class="btn_text" style="padding-left: 10px; margin-right: auto">{{
          title
        }}</span>
        <!-- <span style="margin:0 10px 0 10px; ">
        <v-img src="@/assets/icon_VR_W.svg" width="30" height="30"/>
      </span> -->
        <span style="margin: 0 10px 0 0">
          <v-icon
            v-show="subIconFlg"
            right
            :size="subIconSize == 0 ? size - 20 : subIconSize"
            color="#f2f2f2"
            >{{ subIcon }}</v-icon
          >
        </span>
      </template>
      <template v-else>
        <span style="margin: 0 10px 0 0">
          <v-icon
            v-show="subIconFlg"
            right
            :size="subIconSize == 0 ? size - 20 : subIconSize"
            color="#f2f2f2"
            >{{
              subIcon == mdiArrowRightCircle ? mdiArrowLeftCircle : subIcon
            }}</v-icon
          >
        </span>
        <span class="btn_text" style="padding-right: 10px; margin-left: auto">{{
          title
        }}</span>
      </template>
    </v-btn>
    <v-card-text v-if="caution != null">{{ caution }}</v-card-text>
  </div>
</template>

<script>
// https://www.nxworld.net/10-css-hover-fill-animation.html
import { mdiMagnify, mdiArrowRightCircle, mdiArrowLeftCircle } from '@mdi/js'
export default {
  data () {
    return {
      svgPath_Magnify: mdiMagnify, // Material Design Icon
      mdiArrowRightCircle: mdiArrowRightCircle,
      mdiArrowLeftCircle: mdiArrowLeftCircle
      // imagesrc: require(`@/assets/${this.icon};`)
    }
  },
  props: {
    title: {
      type: String,
      required: false
    },
    func: {
      type: Function,
      required: false,
      default: null
    },
    size: {
      type: Number,
      required: false
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    subIcon: {
      type: String,
      required: false,
      default: mdiArrowRightCircle
    },
    subIconSize: {
      type: Number,
      required: false,
      default: 0
    },
    subIconFlg: {
      type: Boolean,
      required: false,
      default: true
    },
    mdi: {
      type: String,
      required: false,
      default: null
    },
    color: {
      type: String,
      required: false,
      default: '#191919'
    },
    colorText: {
      type: String,
      required: false,
      default: '#f2f2f2'
    },
    caution: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    backFlg: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    loadImg () {
      // const images = require.context('../assets/', false, /\.svg$|\.png$|\.jpg$/)
      // return images('./' + this.icon)
      return require(`@/assets/${this.icon};`)
    },
    iconSize () {
      if (this.size >= 55) {
        return 40
      } else {
        return 30
      }
    }
  }
}
</script>

<style scoped>
.btnVR {
  /* color: #f2f2f2; */
  font-size: 18px;
  font-weight: 900;
}
.theme--dark.v-btn {
  background-color: #191919;
}
.v-card__text {
  padding: 6px 16px 0px;
  /* color: #DFFF00; */
  /* color: #00EEFF; */
  font-weight: 900;
  font-size: 15px;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #d7d7d7 !important;
}
.v-btn--disabled >>> .btn_text,
.v-btn--disabled >>> .btn_icon {
  color: #ffffff;
}
.theme--light.v-btn.v-btn--disabled .v-icon,
.theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: #ffffff !important;
}
</style>
