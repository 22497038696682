<template>
  <v-row class="SearchQualityBar" no-gutters>
    <v-col :cols="k===1?2 :undefined" :sm="k===1?3 :k===2?3 :k===3?3 :k===4?3 :undefined" v-for="k in 4" :key="k">
      <div v-if="k===1">
        <v-card class="buttonedge-left" outlined tile>
          <v-card-title
            style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
          >
            <v-avatar size=20>
              <img src="@/assets/icon_Architecture_G.svg">
            </v-avatar>
            {{ filter_quality_label }}
          </v-card-title>
        </v-card>
      </div>
      <div v-else-if="k===2">
        <v-card outlined tile>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
              >
              <v-select
                :label="filter_quality_structure_label"
                multiple
                height=30
                color="black"
                item-color="gray"
                disabled
                single-line
                :menu-props="{ maxHeight: 1800 }"
              >
              </v-select>
              </div>
            </template>
            <div class="tips text-center">
              <span>【耐震等級】</span><br><br>
            </div>
            <div class="tips">
              <span>全ての建物で</span><br>
              <span>許容応力度設計を実施しています。</span><br>
              <span>（完成度60％）</span><br>
              <span>計算結果はVRの「構造」ボタンでご確認下さい。</span><br>
              <span>結果は梁成に反映されています。</span>
            </div>
          </v-tooltip>
        </v-card>
      </div>
      <div v-else-if="k===3">
        <v-card outlined tile>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
              >
                <v-select
                  :label="filter_quality_ec_label"
                  multiple
                  height=30
                  color="black"
                  item-color="gray"
                  single-line
                  :menu-props="{ maxHeight: 1800 }"
                  disabled
                >
                </v-select>
                </div>
            </template>
            <div class="tips text-center">
              <span>【温熱等級】</span><br><br>
            </div>
            <div class="tips text-center">
              <span>今後、全ての建物で</span><br>
              <span>温熱等級評価を実装する予定です。</span>
            </div>
          </v-tooltip>
        </v-card>
      </div>
      <div v-else>
        <v-card class="buttonedge-right" outlined tile>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
              >
                <v-select
                  :label="filter_quality_low_label"
                  multiple
                  height=30
                  color="black"
                  item-color="gray"
                  single-line
                  :menu-props="{ maxHeight: 1800 }"
                  disabled
                >
                </v-select>
                </div>
            </template>
            <div class="tips text-center">
              <span>【法適合性】</span><br><br>
            </div>
            <div class="tips">
              <span>【集団規定】</span><br>
              <span>道路・隣地・北側斜線、絶対高さ</span><br>
              <span>【単体規定】</span><br>
              <span>自然換気・採光</span><br>
              <span>↑VRでご確認いただけます。</span><br>
              <span></span><br>
              <span>高さ制限.comで一部機能をお使い頂けます。</span><br>
              <span>https://heightlimit.archiroid.com/</span>
            </div>
          </v-tooltip>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// import Vue from 'vue'
export default {
  props: {
    filter_quality_label: {
      type: String,
      require: false,
      default: 'Performance'
    },
    filter_quality_structure_label: {
      type: String,
      require: false,
      default: 'Structure'
    },
    filter_quality_ec_label: {
      type: String,
      require: false,
      default: 'EnergyConservation'
    },
    filter_quality_low_label: {
      type: String,
      require: false,
      default: 'LegalCompliance'
    }
  }
}
</script>

<style scoped>
.theme--light.v-card.v-card--outlined {
  /*カードの枠線*/
  border: 0.5px solid #dadce0;
}
.v-card {
  /*カード設定*/
  height: 30px;
  margin: 0px;
  border-right: none !important;
}
.v-card__title {
  margin: 0px;
  padding: 0 0 0 4px;
  line-height: 28px;
  font-size: 14px;
  color: #bfbfbf;
}
.v-text-field {
  margin: 0px;
  padding: 0px 2px 0px 10px;
  height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.v-input {
  font-size: 14px;

}
.v-label {
  font-size: 14px;
}
.v-input input {
  max-height: 700px;
}
.v-select__slot {
  padding-left: 10px;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.v-list-item__title {
  font-weight:700;
}
.tips {
  font-size: 16px;
  font-weight:600;
}
</style>

<style>
.SearchQualityBar .v-label {
  font-size: 14px;
  /*color: black;*/
  font-weight:700
}
.SearchQualityBar .buttonedge-left {
  /* カードの左のマル */
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}
.SearchQualityBar .buttonedge-right {
  /* カードの左のマル */
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  border-right: 0.5px solid #dadce0 !important;
}

.SearchQualityBar .v-text-field > .v-input__control > .v-input__slot:before,
.SearchQualityBar .v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.SearchQualityBar .v-text-field .v-input__prepend-inner,
.SearchQualityBar .v-text-field .v-input__append-inner {
  margin: 0px;
  padding: 2.5px 0px 0px 0px !important;
  height: 30px;
}
.SearchQualityBar .v-select__selections {
  flex-wrap: inherit;
}

@media (max-width: 500px) {
  .SearchQualityBar .v-card{
    /*カードの枠線*/
    border-top: 1px solid #dadce0 !important;
    border-bottom: 1px solid #dadce0 !important;
    border-left: 1px solid #dadce0 !important;
    border-right: 0px solid #dadce0 !important;
  }
  .SearchQualityBar .buttonedge-right {
    /* カードの左のマル */
    border-right: 1px solid #dadce0 !important;
  }
}
</style>
