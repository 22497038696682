import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSession from 'vue-session'
import InfiniteLoading from 'vue-infinite-loading'
import Axios from 'axios'
import Swal from 'sweetalert2'
// import Vuetify from 'vuetify/lib'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import gv from './mixins/globalValiables'
// import VueGtag from 'vue-gtag'
import VueAnalytics from 'vue-analytics'
import VueGtm from '@gtm-support/vue2-gtm'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import VueSocialSharing from 'vue-social-sharing'
// ------------------------------
// ライブラリ関連
// ------------------------------
Vue.use(VueSession)
Vue.mixin(gv)// mixin（グローバルメソッド）の追加
Vue.use(InfiniteLoading)

Vue.use(VueAnalytics, { id: 'UA-90359711-4', router })
// Vue.use(VueGtag, {
//   config: { id: 'G-CRQVBW1GRN' }
// }, router)
Vue.use(VueGtm, {
  id: 'GTM-P8BSZVF',
  debug: true,
  vueRouter: router
})

Vue.config.productionTip = false
Vue.prototype.$axios = Axios
Vue.prototype.$swal = Swal
Vue.use(VuePlyr, { plyr: {} })
Vue.use(VueSocialSharing)

library.add(fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// ------------------------------
// URL
// ------------------------------
// 最初の呼び出しURLの設定
// const backendURL = 'http://127.0.0.1:8000'
const backendURL = 'https://backend.archiroid.com'
// const backendURL = 'https://backendbeta.archiroid.com'

Vue.prototype.$http = (url, opts) => fetch(url, opts)
// House 関連
Vue.prototype.$httpGetHouse = backendURL + '/api/house/'
Vue.prototype.$httpGetHouseList = backendURL + '/api/houselist/'
Vue.prototype.$httpGetHouseNum = backendURL + '/api/housenum/'
Vue.prototype.$httpGetAllHouseNum = backendURL + '/api/allhousenum/'
// Favorite 関連
Vue.prototype.$httpFavorite = backendURL + '/api/favorite/post/'
Vue.prototype.$httpFavoriteCheck = backendURL + '/api/favorite/check/'
Vue.prototype.$httpUserFavHouses = backendURL + '/api/favorite/getUserFavs/'
Vue.prototype.$httpHouseFavCnt = backendURL + '/api/favorite/houseFavCnt/'
// User 関連
Vue.prototype.$httpUserReist = backendURL + '/rest-auth/registration/'
// Vue.prototype.$httpUserReist = backendURL + '/accounts/signup/'
// Vue.prototype.$httpUserLogin = backendURL + '/auth/login/'
Vue.prototype.$httpUserLogin = backendURL + '/rest-auth/login/'
Vue.prototype.$httpUserPassChange = backendURL + '/rest-auth/password/change/'
Vue.prototype.$httpGetUserInfo = backendURL + '/rest-auth/user/'
Vue.prototype.$httpPassReset = backendURL + '/rest-auth/password/reset/'
Vue.prototype.$httpPassResetConfirm = backendURL + '/rest-auth/password/reset/confirm/'
// Make 関連
Vue.prototype.$httpUserMakeHouses = backendURL + '/api/make/getUserMakes/'
// History 関連
Vue.prototype.$httpMakeGetHistory = backendURL + '/api/make/getHistory/'
Vue.prototype.$httpMakePostHistory = backendURL + '/api/make/postHistory/'
// Paypal 関連
Vue.prototype.$httpPaymentCreateOrder = backendURL + '/payment/createorder/'
Vue.prototype.$httpPaymentCaptureOrder = backendURL + '/payment/captureorder/'
// 未使用？
// Vue.prototype.$httpPosts (backendurl + 'api/houselist/',{index: index})
// Vue.prototype.$httpCategories = backendurl + 'design-note/api/categories/'

new Vue({
  router,
  store,
  vuetify,
  data: () => ({
    collapseOnScroll: true
  }),
  render: h => h(App)
}).$mount('#app')
