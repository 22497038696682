import { render, staticRenderFns } from "./SortButton.vue?vue&type=template&id=1062b45b&scoped=true&"
import script from "./SortButton.vue?vue&type=script&lang=js&"
export * from "./SortButton.vue?vue&type=script&lang=js&"
import style0 from "./SortButton.vue?vue&type=style&index=0&id=1062b45b&scoped=true&lang=css&"
import style1 from "./SortButton.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1062b45b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOverflowBtn } from 'vuetify/lib/components/VOverflowBtn';
installComponents(component, {VOverflowBtn})
