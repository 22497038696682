<template>
  <v-row id="SearchToolBar" no-gutters>
    <v-col cols = "12" sm = "3" >
      <div>
        <v-card class="buttomedge-left" outlined tile>
          <v-text-field
            class="search_archiroid"
            :placeholder="filter_archiroid_label"
            prepend-inner-icon="mdi-magnify"
            v-model="archiroid_value"
            @input="filter"
            clearable
            color="#000000"
          ></v-text-field>
          <!-- <v-select
            v-model="archiroid_value"
            :items="filter_archiroid_items"
            :label="filter_archiroid_label"
            :prepend-inner-icon="svgPath_Magnify"
            multiple
            height=46
            color="black"
            item-color="gray"
            single-line
            :menu-props="{ maxHeight: 1800 }"
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @click="togglePlan"
              >
                <v-list-item-action>
                  <v-icon :color="archiroid_value.length > 0 ? 'brack' : ''">{{ icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>全て選択する</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item disabled>
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon style="margin: auto;" size=24>{{svgPath_HomeVariant}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content v-if="selectAllPlan">
                  <v-list-item-title>全てのアーキロイドが選択されました!</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-else-if="selectSomePlan">
                  <v-list-item-title>Selected Room Count</v-list-item-title>
                  <v-list-item-subtitle>{{ archiroid_value.length }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title>
                    好みのアーキロイドを選択してください。設計した家を検索します。
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    検索条件：OR
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, index }">
                <span
                  v-if="index === 0"
                  class="black--text caption"
                  style="font-size: 14px !important; font-weight: 700; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                > +{{ archiroid_value.length }} {{ filter_archiroid_label }}</span>
            </template>
            <template v-slot:label>
                <span
                  class="black--text caption"
                  style="position: relative; height:46px; vertical-align:middle; font-size: 14px !important; font-weight: 700; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                > {{ filter_archiroid_label }}</span>
            </template>
          </v-select> -->
        </v-card>
      </div>
    </v-col>
    <v-col cols = "12" sm = "">
      <div>
        <v-card class="areaTool" outlined tile>
          <v-range-slider
            v-model="area_range"
            :max="filter_area_max"
            :min="filter_area_min"
            hide-details
            class="align-center"
            @input="filter"
            color="#000000"
            label="延床面積"
            track-color="#dadce0"
            track-fill-color="#333333"
            thumb-label="always"
            thumb-color="#333333"
            thumb-size=2
            step="10"
            ticks
            tick-size="1.5"
          >
            <template v-slot:thumb-label="{ value }">{{ value }}㎡</template>
            <template v-slot:prepend><img src="@/assets/icon_area@2x.png"></template>
          </v-range-slider>
        </v-card>
      </div>
    </v-col>
    <v-col cols = "12" sm = "">
      <div>
        <v-card class="moneyTool buttomedge-right" outlined tile>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                  v-bind="attrs"
                  v-on="on"
                >
              <v-range-slider
                v-model="price_range"
                :max="filter_price_max"
                :min="filter_price_min"
                hide-details
                class="align-center"
                @input="filter"
                color="#000000"
                label="参考価格"
                track-color="#dadce0"
                track-fill-color="#333333"
                thumb-label="always"
                thumb-color="#333333"
                thumb-size=2
                step="1000000"
                ticks
                tick-size="1.5"
                tick-
              >
                <template v-slot:thumb-label="{ value }">￥{{ value | addComma }}</template>
                <template v-slot:prepend><img src="@/assets/icon_yen@2x.png"></template>
              </v-range-slider>
              </div>
            </template>
            <div class="tips text-center">
              <span>【市場価格】</span><br><br>
            </div>
            <div class="tips">
              <span>価格は、設計した「部品一つ一つのデータ」を元に積算しています。</span><br>
              <span>そのため、坪単価計算より精度の高い市場価格を表記しております。</span><br>
              <span></span><br>
              <span>実際の価格は建設事業者の調達額によって決まります。</span>
            </div>
          </v-tooltip>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mdiMagnify, mdiHomeVariant, mdiCloseBox, mdiMinusBox, mdiCheckboxBlankOutline } from '@mdi/js'
// import Vue from 'vue'
export default {
  // name: "SearchToolBar",
  props: {
    filter_archiroid_label: {
      type: String,
      require: false,
      default: 'Archiroid'
    },
    filter_archiroid_items: {
      type: Array,
      require: true
    },
    filter_archiroid_value: {
      type: String,
      require: true
    },
    filter_area_min: {
      type: Number,
      require: true
    },
    filter_area_max: {
      type: Number,
      require: true
    },
    filter_area_range: {
      type: Array,
      require: true
    },
    filter_price_min: {
      type: Number,
      require: true
    },
    filter_price_max: {
      type: Number,
      require: true
    },
    filter_price_range: {
      type: Array,
      require: true
    }
  },
  data () {
    return {
      svgPath_Magnify: mdiMagnify, // Material Design Icon
      svgPath_HomeVariant: mdiHomeVariant,
      svgPath_CloseBox: mdiCloseBox,
      svgPath_MinusBox: mdiMinusBox,
      svgPath_CheckboxBlankOutline: mdiCheckboxBlankOutline,
      archiroid_value: this.filter_archiroid_value,
      area_range: this.filter_area_range,
      price_range: this.filter_price_range
    }
  },
  methods: {
    filter () {
      this.$parent.filter_archiroid_value = this.archiroid_value
      this.$parent.filter_area_range = this.area_range
      this.$parent.filter_price_range = this.price_range
      this.$parent.filter()
    },
    togglePlan () {
      this.$nextTick(() => {
        if (this.selectAllPlan) {
          this.archiroid_value = []
        } else {
          this.archiroid_value = this.filter_archiroid_items.slice()
        }
        // this.filter()
      })
    }
  },
  filters: {
    addComma: function (value) {
      return value.toLocaleString()
    }
  },
  computed: {
    selectAllPlan () {
      return this.archiroid_value.length === this.filter_archiroid_items.length
    },
    selectSomePlan () {
      return this.archiroid_value.length > 0 && !this.selectAllPlan
    },
    icon () {
      // if (this.selectAllPlan) return 'mdi-close-box'
      // if (this.selectSomePlan) return 'mdi-minus-box'
      // return 'mdi-checkbox-blank-outline'
      if (this.selectAllPlan) return this.svgPath_CloseBox
      if (this.selectSomePlan) return this.svgPath_MinusBox
      return this.svgPath_CheckboxBlankOutline
    }
  }
}
</script>

<style scoped>
.v-card {
  /*カードのサイズ*/
  height: 46px;
  /*マージン*/
  margin: 30px 0px 20px 0px;
}
.v-input {
  /*入力テキストサイズ*/
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-weight: 700;
}
/*以下セレクト内のつもり */
.v-label {
  font-size: 14px;
}
.v-input input {
  max-height: 700px;
}
.v-select__slot {
  padding-left: 10px;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.v-list-item__title {
  font-weight:700;
}
/*以上セレクト内のつもり */
.tips {
  font-size: 16px;
  font-weight:600;
}
</style>

<style>
@media (max-width: 670px) {
  #SearchToolBar {
    margin: 20px 0 10px
  }
  #SearchToolBar .v-card {
    margin: 2.5px 0 2.5px;
    border-top-left-radius: 23px !important;
    border-bottom-left-radius: 23px !important;
    border-top-right-radius: 23px !important;
    border-bottom-right-radius: 23px !important;
    border-right: 0.5px solid #dadce0 !important;
    border-left: 0.5px solid #dadce0 !important;
  }
}
#SearchToolBar .buttomedge-left {
  border-top-left-radius: 23px !important;
  border-bottom-left-radius: 23px!important;
}
#SearchToolBar .buttomedge-right {
  border-top-right-radius: 23px !important;
  border-bottom-right-radius: 23px !important;
}
#SearchToolBar .v-input{
  /*カードの枠線*/
  color: #000000 !important;
}

#SearchToolBar .v-card{
  /*カードの枠線*/
  border: 0.5px solid #dadce0;
}
#SearchToolBar .buttomedge-left {
  /*カードの枠線重なり削除*/
  border-right: none;
}
#SearchToolBar .buttomedge-right {
  /*カードの枠線重なり削除*/
  border-left: none;
}

@media (max-width: 500px) {
  #SearchToolBar .v-card{
    /*カードの枠線*/
    border: 1px solid #dadce0 !important;
  }
}

#input-20 {
  /*インプットpadding削除(無くてもいい)*/
  height: 46px;
  padding: 0;
}

#input-1950 {
  /*インプットpadding削除(無くてもいい)*/
  height: 46px;
  padding: 0;
}

#input-21 {
  color: #000000 !important;
}

#SearchToolBar .v-input__slot {
  /*インプット文字上下高さ調整*/
  margin-bottom: 0px ;
  height: 46px;
}

#SearchToolBar .v-text-field > .v-input__control > .v-input__slot:before,
#SearchToolBar .v-text-field > .v-input__control > .v-input__slot:after {
  /*下線の削除*/
  border-style: none !important;
}

#SearchToolBar .v-text-field .v-input__prepend-inner {
  /*テキストフィールドアイコン調整*/
  margin: 11px;
  margin-right: 0px;
  padding-right: 10px !important;
}
#SearchToolBar .v-text-field .v-input__append-inner {
  padding: 10px !important;
  margin: 0px;
  /* padding: 2.5px 0px 0px 0px !important; */
  /*テキストフィールドアイコン調整*/
  /* margin-top: 20x;
  margin-right: 5px;
  margin-bottom: 0px; */
  /*アーキロイド文字上下位置調整謎原理*/
  /* height: 32px; */
}

/* スライダー */
#SearchToolBar .v-label {
  font-size: 14px !important;
  font-weight: 700;
  color: #000000;
}
#SearchToolBar .v-slider__thumb {
  width: 10px;
  height: 10px;
  left: -5px;
}

#SearchToolBar .v-slider__thumb:before{
  width: 20px !important;
  height: 20px !important;
  left: -5px !important;
  top: -5px !important;
}

#SearchToolBar .v-slider__thumb-label {
  color: #000000;
  background-color: transparent !important;
  font-size: 11px !important;
  font-weight: 700;
  letter-spacing: normal;
  white-space: nowrap;
}

#SearchToolBar .areaTool .v-slider--horizontal div[role="slider"]:nth-child(2n) .v-slider__thumb-label,
#SearchToolBar .moneyTool .v-slider--horizontal div[role="slider"]:nth-child(2n) .v-slider__thumb-label {
  top:25px  !important;
}

#input-max-29 {
  top:-30px  !important;
}
#SearchToolBar .v-slider--horizontal {
  margin-left: 15px;
  margin-right: 45px;
}

#SearchToolBar .v-input__prepend-outer {
  margin-left: 10px;
  margin-right: 10px;
}

</style>
