<template>
  <div>
    <ScrollToTopButton />
    <v-row>
      <v-col cols="12">
        <SearchToolBar
          :filter_archiroid_label="filter_archiroid_label"
          :filter_archiroid_items="filter_archiroid_items"
          :filter_archiroid_value="filter_archiroid_value"
          :filter_area_min="filter_area_min"
          :filter_area_max="filter_area_max"
          :filter_area_range="filter_area_range"
          :filter_price_min="filter_price_min"
          :filter_price_max="filter_price_max"
          :filter_price_range="filter_price_range"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        xl="3"
        lg="4"
        md="6"
        sm="6"
        cols="12"
        v-for="n in 3"
        :key="'1_' + n"
        style="padding:2.5px 10px;"
      >
        <div v-if="n === 1">
          <SearchArchitectureBar
            :filter_architecture_label="filter_architecture_label"
            :filter_architecture_plan_label="filter_architecture_plan_label"
            :filter_architecture_plan_items="filter_architecture_plan_items"
            :filter_architecture_plan_value="filter_architecture_plan_value"
            :filter_architecture_options_label="filter_architecture_options_label"
            :filter_architecture_options_items="filter_architecture_options_items"
            :filter_architecture_options_value="filter_architecture_options_value"
            :filter_architecture_floor_label="filter_architecture_floor_label"
            :filter_architecture_floor_items="filter_architecture_floor_items"
            :filter_architecture_floor_value="filter_architecture_floor_value"
          />
        </div>
        <div v-if="n === 2">
          <SearchQualityBar
            :filter_quality_label="filter_quality_label"
            :filter_quality_structure_label="filter_quality_structure_label"
            :filter_quality_ec_label="filter_quality_ec_label"
            :filter_quality_low_label="filter_quality_low_label"
          />
        </div>
        <div v-if="n === 3">
          <SearchEnvironmentBar
            :filter_environment_label="filter_environment_label"
            :filter_environment_area_label="filter_environment_area_label"
            :filter_environment_shape_label="filter_environment_shape_label"
            :filter_environment_road_label="filter_environment_road_label"
          />
        </div>
      </v-col>
      <v-col
        xl="auto"
        lg="2"
        md="3"
        sm="3"
        cols="6"
        v-for="n in 2"
        :key="'2_' + n"
        style="padding:2.5px 10px;"
      >
        <div v-if="n === 1">
          <v-btn
            class="filterBookmark"
            outlined
            height="30"
            fab
            width="30"
            style="padding:0px;"
            v-show="showFavorite===false"
            @click="showFavoriteTrue"
          >
            <!-- <v-icon>bookmark_border</v-icon> -->
            <img height="16" src="@/assets/bookmark-black.png" />
          </v-btn>
          <v-btn
            class="filterBookmark"
            outlined
            height="30"
            fab
            width="30"
            style="padding:0px; background-color:black;"
            v-show="showFavorite===true"
            @click="showFavoriteFalse"
          >
            <!-- <v-icon>bookmark</v-icon> -->
            <img height="16" src="@/assets/bookmark-white.png" />
          </v-btn>
          <span class="bookmarkText">：お気に入り</span>
        </div>
        <div v-if="n === 2">
          <v-btn
            class="filterBookmark"
            outlined
            height="30"
            fab
            width="30"
            style="padding:0px;"
            v-show="showMake===false"
            @click="showMakeTrue"
          >
            <!-- <v-icon>bookmark_border</v-icon> -->
            <v-icon size="16">{{svgPath_Wrench}}</v-icon>
          </v-btn>
          <v-btn
            class="filterBookmark"
            outlined
            height="30"
            fab
            width="30"
            dark
            style="padding:0px; background-color:black;"
            v-show="showMake===true"
            @click="showMakeFalse"
          >
            <!-- <v-icon>bookmark</v-icon> -->
            <v-icon size="16">{{svgPath_Wrench}}</v-icon>
          </v-btn>
          <span class="bookmarkText">：つくった家</span>
        </div>
      </v-col>
    </v-row>
    <v-row class="sortBar">
      <v-col cols="auto" class="mr-auto">
        <span class="result">{{ result_len |addComma }}</span>
        of {{ all_len |addComma }}
      </v-col>
      <!-- <v-spacer /> -->
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="auto" style="padding-top:0px; padding-bottom:0px;">
        <v-switch
          v-if="$vuetify.breakpoint.mdAndUp"
          class="switch"
          v-model="dataSwitch"
          color="black"
          label="情報"
          style="font-size:14px;"
        ></v-switch>
      </v-col>
      <v-col cols="auto" style="padding-top:0px; padding-bottom:0px;">
        <v-switch
          class="switch"
          v-model="planSwitch"
          color="black"
          label="平面"
        ></v-switch>
      </v-col>
      <v-col cols="auto"  style="padding-top:0px; padding-bottom:0px;">
        <SortButton
          style="width:140px;"
          :label="sort_label"
          :items="sort_items"
          :value="sort_value"
        />
      </v-col>
    </v-row>
    <v-row class="houseList">
      <v-col
        :xl="xlRayout"
        :lg="lgRayout"
        :md="mdRayout"
        sm="6"
        cols="12"
        style="padding:15px 10px 45px 10px"
        v-for="house in houseList"
        :key="house.project_id"
      >
        <HouseCard :houseinfo=" house " :dataFlg="dataSwitch" :planFlg="planSwitch" />
      </v-col>
    </v-row>
    <!-- InfiniteLoadingコンポーネントを定義 -->
    <infinite-loading
      ref="infiniteLoading"
      :identifier="identifier"
      @infinite="infiniteHandler"
      spinner="spiral"
    >
      <div slot="no-more">-----検索結果は以上です-----</div>
      <div slot="no-results">-----検索結果はありません-----</div>
    </infinite-loading>
  </div>
</template>

<script>
import HouseCard from '../components/HouseCard'
import SearchToolBar from '../components/SearchToolBar'
import SearchArchitectureBar from '../components/SearchArchitectureBar'
import SearchQualityBar from '../components/SearchQualityBar'
import SearchEnvironmentBar from '../components/SearchEnvironmentBar'
import SortButton from '../components/SortButton'
import ScrollToTopButton from '../components/Button/ScrollToTopButton'
import InfiniteLoading from 'vue-infinite-loading'
import { mdiWrench } from '@mdi/js'
// import qs from 'qs';
export default {
  components: {
    HouseCard,
    SearchToolBar,
    SearchArchitectureBar,
    SearchQualityBar,
    SearchEnvironmentBar,
    SortButton,
    ScrollToTopButton,
    InfiniteLoading
  },
  data () {
    return {
      dataSwitch: true,
      planSwitch: false,
      svgPath_Wrench: mdiWrench, // Material Design Icon
      result_len: 0,
      all_len: 0,
      identifier: 0,
      houseList: [],
      favoriteHouseIds: [],
      showFavorite: false,
      makeHouseIds: [],
      showMake: false,
      flg_get: false,
      index: 0,
      display_num: 12,
      filter_archiroid: '',
      filter_area_min: 0,
      filter_area_max: 350,
      filter_area_range: [0, 350], // デフォルト値
      filter_price_min: 0,
      filter_price_max: 50000000,
      filter_price_range: [0, 50000000], // デフォルト値 12000000, 37000000
      filter_archiroid_label: '検索（ID/作品名/空間作家名）',
      filter_archiroid_items: ['庵庭ゼン', '真庭ミカン(開発中)'],
      filter_archiroid_value: '',
      filter_architecture_label: '空間',
      filter_architecture_plan_label: 'nLDK',
      // filter_architecture_plan_items: ['PrivateRoom', 'LivingRoom', 'DiningRoom', 'EntranceRoom', 'Corridor', 'Staircase', 'WashRoom', 'Stork', 'Toilet', 'BathRoom', 'Kitchen'],
      filter_architecture_plan_items: ['玄関', '廊下', '寝室', 'リビング', 'ダイニング', 'キッチン', 'トイレ', '浴室', '洗面所', '収納室'],
      filter_architecture_plan_value: [],
      filter_architecture_options_label: '+α',
      filter_architecture_options_items: ['作業室', '書斎', '家事室', '事務室'],
      filter_architecture_options_value: [],
      filter_architecture_floor_label: '階数',
      filter_architecture_floor_items: ['1F', '2F', '3F', 'RF'],
      filter_architecture_floor_value: ['1F'],
      filter_quality_label: '建築',
      filter_quality_structure_label: '耐震等級',
      filter_quality_ec_label: '温熱等級',
      filter_quality_low_label: '法適合性',
      filter_environment_label: '土地',
      filter_environment_area_label: '面積',
      filter_environment_shape_label: '形状',
      filter_environment_road_label: '接道',
      sort_label: 'Sort:',
      sort_items: ['ID順', '価格が高い順', '価格が安い順', '面積が大きい順', '面積が小さい順'],
      sort_value: 'ID順'
    }
  },
  // beforeMount () {
  //   this.getUserFavIds()
  // },
  // beforeUpdate () {
  //   this.getUserFavIds()
  // },
  async created () {
    // console.log('created')
  },
  async beforeMount () {
    // this.favoriteHouseIds = await this.getUserFavIds()
    // this.$nextTick(() => {
    //     console.log('updated'); // log is 'updated.'
    // })
    // console.log('beforeMount')
  },
  mounted () {
    this.getAllHouseNum()
    this.getHouseNum()
    // console.log('mounted')
    // this.getHouses(this.$refs.infiniteLoading.stateChanger)
    // $state.loaded()
    // window.addEventListener('unload', this.getHouses(this.$refs.infiniteLoading.stateChanger))
  },
  beforeDestroy: function () {
    // window.removeEventListener('unload', this.getHouses(this.$refs.infiniteLoading.stateChanger))
  },
  computed: {
    xlRayout: function () {
      if (this.planSwitch) {
        return 4
      } else {
        return 3
      }
    },
    lgRayout: function () {
      if (this.planSwitch) {
        return 6
      } else {
        return 4
      }
    },
    mdRayout: function () {
      if (this.planSwitch) {
        return 6
      } else {
        return 4
      }
    }
  },
  methods: {
    infiniteHandler ($state) {
      if ((this.index) > this.result_len) {
        // 表示するデータがなくなった場合
        $state.complete()
      } else {
        // 表示するデータがある場合
        // console.log('getHouses')
        this.getHouses($state)
      }
    },
    // arrayToParam (array, name) {
    //   var params = new URLSearchParams();
    //   array.forEach ( function (item, index, array) {
    //     params.append (name, item)
    //     console.log(item, index, name)
    //   })
    //   console.log(params)
    //   return params
    // },
    getHouses ($state) {
      // this.$axios.get(this.$httpGetHouseList, {
      //   params: {
      //     index: this.index,
      //     display_num: this.display_num,
      //     filter_archiroid: this.filter_archiroid,
      //     filter_area_min: this.filter_area_range[0],
      //     filter_area_max: this.filter_area_range[1],
      //     filter_price_min: this.filter_price_range[0],
      //     filter_price_max: this.filter_price_range[1],
      //     filter_architecture_plan_value: this.filter_architecture_plan_value,
      //     filter_architecture_options_value: this.filter_architecture_options_value,
      //     filter_architecture_floor_value: this.filter_architecture_floor_value,
      //     sort_value: this.sort_value
      //   }
      // console.log('getHouses' + this.favoriteHouseIds)
      this.$axios.post(this.$httpGetHouseList, {
        timing: 'getHouses',
        favoriteHouseIds: this.favoriteHouseIds,
        showFavorite: String(this.showFavorite),
        makeHouseIds: this.makeHouseIds,
        showMake: String(this.showMake),
        index: this.index,
        display_num: this.display_num,
        filter_archiroid: this.filter_archiroid_value,
        filter_area_min: this.filter_area_range[0],
        filter_area_max: this.filter_area_range[1],
        filter_price_min: this.filter_price_range[0],
        filter_price_max: this.filter_price_range[1],
        filter_architecture_plan_value: this.filter_architecture_plan_value,
        filter_architecture_options_value: this.filter_architecture_options_value,
        filter_architecture_floor_value: this.filter_architecture_floor_value,
        sort_value: this.sort_value
      })
        .then(response => {
          return response.data
        })
        .then(data => {
          data.forEach(element => this.houseList.push(element))
          // this.result_len = data[0]
          // this.all_len = data[2]
          this.index = this.index + this.display_num
          $state.loaded()
        })
    },
    getHouseNum () {
      // this.$axios.get(this.$httpGetHouseNum, {
      //   params: {
      //     index: this.index,
      //     display_num: this.display_num,
      //     filter_archiroid: this.filter_archiroid,
      //     filter_area_min: this.filter_area_range[0],
      //     filter_area_max: this.filter_area_range[1],
      //     filter_price_min: this.filter_price_range[0],
      //     filter_price_max: this.filter_price_range[1],
      //     filter_architecture_plan_value: this.filter_architecture_plan_value,
      //     filter_architecture_options_value: this.filter_architecture_options_value,
      //     filter_architecture_floor_value: this.filter_architecture_floor_value,
      //     sort_value: this.sort_value
      //   }
      // })
      // console.log('getHouseNum' + this.favoriteHouseIds)
      this.$axios.post(this.$httpGetHouseNum, {
        timing: 'getHouseNum',
        favoriteHouseIds: this.favoriteHouseIds,
        showFavorite: String(this.showFavorite),
        makeHouseIds: this.makeHouseIds,
        showMake: String(this.showMake),
        index: this.index,
        display_num: this.display_num,
        filter_archiroid: this.filter_archiroid_value,
        filter_area_min: this.filter_area_range[0],
        filter_area_max: this.filter_area_range[1],
        filter_price_min: this.filter_price_range[0],
        filter_price_max: this.filter_price_range[1],
        filter_architecture_plan_value: this.filter_architecture_plan_value,
        filter_architecture_options_value: this.filter_architecture_options_value,
        filter_architecture_floor_value: this.filter_architecture_floor_value,
        sort_value: this.sort_value
      })
        .then(response => {
          return response.data
        })
        .then(data => {
          this.result_len = data
        })
    },
    getAllHouseNum () {
      this.$axios.get(this.$httpGetAllHouseNum)
        .then(response => {
          return response.data
        }).catch(error => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error'
          } else {
            this.errorStatus = error.response.data.message
          }
        })
        .then(data => {
          this.all_len = data
        })
    },
    async getUserFavIds () {
      if (!this.checkLoggedin()) {
        // 未ログイン
        this.favoriteHouseIds = []
      } else {
        const response = await this.$axios.get(this.$httpUserFavHouses, { headers: this.$store.state.auth.login.headers })
        // console.log(response.data)
        // this.favoriteHouseIds = response.data
        // .then(response => {
        //   console.log(response.data)
        //   this.favoriteHouseIds = response.data
        //   // return response.data
        // })
        return response.data
      }
    },
    async getUserMakeIds () {
      if (!this.checkLoggedin()) {
        // 未ログイン
        this.makeHouseIds = []
      } else {
        const response = await this.$axios.get(this.$httpUserMakeHouses, { headers: this.$store.state.auth.login.headers })
        // console.log(response.data)
        // this.favoriteHouseIds = response.data
        // .then(response => {
        //   console.log(response.data)
        //   this.favoriteHouseIds = response.data
        //   // return response.data
        // })
        return response.data
      }
    },
    async filter () {
      if (this.showFavorite) {
        this.favoriteHouseIds = await this.getUserFavIds()
      }
      if (this.showMake) {
        this.makeHouseIds = await this.getUserMakeIds()
      }
      this.$nextTick(() => {
        this.index = 0
        this.houseList = []
        this.getHouseNum()
        this.identifier += 1
      })
    },
    showFavoriteTrue () {
      if (this.checkLoggedin()) {
        this.showFavorite = true
        this.$nextTick(() => {
          this.filter()
        })
      } else {
        this.showSwal('ログインしてください', 1200)
        // this.$router.push({ name: 'login' })
      }
    },
    showFavoriteFalse () {
      if (this.checkLoggedin()) {
        this.showFavorite = false
        this.$nextTick(() => {
          this.filter()
        })
      }
    },
    showMakeTrue () {
      if (this.checkLoggedin()) {
        this.showMake = true
        this.$nextTick(() => {
          this.filter()
        })
      } else {
        this.showSwal('ログインしてください', 1200)
        // this.$router.push({ name: 'login' })
      }
    },
    showMakeFalse () {
      if (this.checkLoggedin()) {
        this.showMake = false
        this.$nextTick(() => {
          this.filter()
        })
      }
    }
  },
  filters: {
    addComma: function (value) {
      return value.toLocaleString()
    }
  }
}
</script>

<style scoped>
.sortBar {
  margin-top: 30px;
  font-size: 14px;
  height: 20px;
}
.sortBar .result {
  font-weight: 700;
}
.bookmarkText {
  font-size: 14px;
  font-weight: 400;
}
.filterBookmark {
  border: 0.5px solid #dadce0;
}
@media (max-width: 500px) {
  .filterBookmark {
    /* カードの左のマル */
    border: 1px solid #dadce0 !important;
  }
}
.v-input--switch--inset {
  padding: 0;
  margin: 0;
}
.SearchBarBody >>> .v-menu__content {
  margin-top: 0 !important;
}
.switch {
  padding: 0;
  margin: 0;
}
.switch >>> .v-label {
  font-size:14px;
}
</style>
