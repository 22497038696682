<template>
  <v-footer
    v-resize="windowSize"
    class="Footer"
    padless
  >
    <v-card
      v-if="bigWindow == true"
      class="flex"
      flat
      tile
      height="50"
    >
      <v-card-title justify="center">
        <strong class="text-center">©{{ new Date().getFullYear() }}- archiroid Inc.</strong>
        <v-spacer></v-spacer>
        <v-breadcrumbs class="text-center" color="black" :items="items"></v-breadcrumbs>
      </v-card-title>
    </v-card>
    <v-card
      v-if="bigWindow == false"
      class="flex"
      flat
      tile
    >
      <v-card-title justify="center">
        <v-spacer></v-spacer>
        <v-breadcrumbs color="black" :items="items"></v-breadcrumbs>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center">
        <strong>©{{ new Date().getFullYear() }}- archiroid Inc.</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'site-footer',
  data () {
    return {
      bigWindow: true,
      items: [
        {
          text: '利用規約',
          disabled: false,
          to: '/sitepolicy'
        },
        {
          text: 'プライバシーポリシー',
          disabled: false,
          to: '/privacypolicy'
        },
        {
          text: 'お問い合わせ',
          disabled: false,
          to: '/inquiry'
        }
      ]
    }
  },
  mounted () {
    this.windowSize()
  },
  methods: {
    windowSize () {
      if (window.innerWidth >= 1030) {
        this.bigWindow = true
      } else {
        this.bigWindow = false
      }
    }
  }
  // methods: {
  //   clickSitePolicy: function (event) {
  //     this.$router.push({ name: 'sitepolicy' }, () => {}, () => {})
  //   },
  //   clickPrivacyPolicy: function (event) {
  //     this.$router.push({ name: 'privacypolicy' }, () => {}, () => {})
  //   },
  //   clickInquiry: function (event) {
  //     this.$router.push({ name: 'inquiry' }, () => {}, () => {})
  //   }
  // }
}
</script>

<style scoped>
  .v-card__title {
    height:32px;
    padding: 0px 20px 0px 20px !important;
    border-top: 0.5px solid #dadce0 !important;
  }
  @media (max-width: 500px) {
  .v-card__title {
    border-top: 1px solid #dadce0 !important;
  }
}

  .v-card__text {
    height:28px;
    padding: 0px 20px 0px 20px !important;
  }

  .v-breadcrumbs {
    padding: 0;
  }

  @media (min-width: 1030px) {
  .v-card__title,
  .v-card__text {
    padding: 0px 50px 0px 50px !important;
  }

  .text-center {
    margin-top: 9px;
  }

  /* .v-btn.v-size--default {
    font-size: 10px !important;
  } */

}
</style>
<style>
  .Footer .v-breadcrumbs li {
    font-size: 11px !important;
    font-weight: 500;
  }

  .Footer .v-breadcrumbs__divider {
    padding: 0 5px !important;
  }

  .Footer strong {
    font-size: 11px !important;
    font-weight: 700;
  }

  .Footer  .v-breadcrumbs__item{
    vertical-align: middle;
    height: 25px;
    font-weight: 600;
    color: black !important;
  }

  .Footer  .v-breadcrumbs__item--disabled {
    font-weight: 600;
    color: #bfbfbf !important;
  }

  .Footer .v-breadcrumbs theme--light {
    justify-self: center;
  }

</style>
<style scoped>
  /* footer {
    background-color: #000;
    color: #fff;
    padding: 0 20px;
    margin-top: 160px;
  }
  ul > li {
    display: inline-block;
    margin-right: 2em;
  }
  ul .v-vtn {
    color: #fff;
    text-decoration: none;
  }
  @media (min-width: 768px) {
    footer {
      padding: 0;
      height: 50px;
      display: grid;
      grid-template-columns: 1fr 700px 1fr;
      grid-template-rows: 1fr;
    }
    footer > * {
      grid-column: 2;
      grid-row: 1;
    }
    p {
      justify-self: start;
      align-self: center;
    }
    ul {
      justify-self: end;
      align-self: center;
    }
  }
  @media (min-width: 1024px) {
    footer {
      grid-template-columns: 1fr 980px 1fr;
    }
  } */
</style>
