<template>
  <div class="inquiry_div">
    <ScrollToTopButton />
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSfHdx_blZkP2SAQ22t4SSzoUO-3zc-9dz10R6VJbgHmB6iQUA/viewform?embedded=true"
      ref="iframeContent"
      width="100%"
      height="100%"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      >読み込んでいます…</iframe
    >
  </div>
</template>

<script>
import ScrollToTopButton from '../components/Button/ScrollToTopButton'
export default {
  components: {
    ScrollToTopButton
  },
  data () {
    return {
    }
  },
  mounted () {
    this.iframeLoad()
  },
  methods: {
  }
}
</script>

<style scoped>
h4 {
  font-size: 21px;
  margin-top: 60px;
  margin-bottom: 60px;
  font-weight: 900;
  text-align: center;
}

h5 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 3px;
}

h6 {
  font-size: 14px;
  font-weight: 700 !important;
  margin-bottom: 3px;
}

p {
  font-size: 14px;
  margin-bottom: 30px !important;
}

.noMargin {
  margin-bottom: 0px !important;
}

.inquiry_div {
  height: 1200px;
}

@media (min-width: 670px) {
  .inquiry_div {
    height: 1100px;
  }
}

@media (min-width: 1030px) {
  h4 {
    margin-top: 120px;
    margin-bottom: 90px;
  }
}
</style>
