<template>
  <div>
    <v-overflow-btn
      class="sortButton"
      :label="label"
      :items="items"
      :prefix="label"
      v-model="component_value"
      @input="filter"
      dense
    >
    </v-overflow-btn>
  </div>
</template>

<script>
// import Vue from 'vue'
export default {
  props: {
    label: {
      type: String,
      require: false,
      default: 'Sort by:'
    },
    items: {
      type: Array,
      require: true
    },
    value: {
      type: String,
      require: true
    }
  },
  data () {
    return {
      component_value: this.value
    }
  },
  methods: {
    filter () {
      this.$parent.sort_value = this.component_value
      this.$parent.filter()
    }
  }
}
</script>

<style scoped>
.v-input{
  font-size: 12px;
  font-weight: 700;
}

.v-overflow-btn{
  margin: 0;
}

</style>
<style>
.sortBar .v-select__slot {
  cursor: pointer
}
.sortBar .v-overflow-btn .v-input__slot {
  border: 0px;
  height:20px;
}

.sortBar .v-text-field__prefix {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer
}
.sortBar .v-select.v-input--dense .v-select__selection--comma {
  margin: 0;
  overflow:visible;
  white-space:nowrap;
}

.sortBar .v-application--is-ltr .v-overflow-btn .v-select__selection--comma:first-child,
.v-application--is-ltr .v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 4px !important;
}

.sortBar .v-overflow-btn .v-input__append-inner {
  width: 24px;
}

.sortBar .v-input__append-inner .v-input__icon > .v-icon{
  margin-top: 0px !important;
}
</style>
